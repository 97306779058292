import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { Tab } from './tab';
import type { IconType } from '@innovamat/glimmer-icons';
import { Tooltip } from '../tooltip';

type Tab = {
  selected?: boolean;
  tooltip?: string;
  text?: string;
  icon?: IconType;
  disabled?: boolean;
  onClick: () => void;
  customStyles?: string;
  dataTestId?: string;
  iconRight?: IconType;
  showCondition?: boolean;
};

type TabsProps = {
  tabs: Tab[];
};

const TabsContainer = styled.div`
  display: flex;
  position: relative;
  gap: 8px;
`;

const Underline = styled.div<{ width: number; left: number }>`
  height: 3px;
  width: ${({ width }) => width}px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-accent'].value};
  position: absolute;
  bottom: -1px;
  left: ${({ left }) => left}px;
  transition: all cubic-bezier(0.5, 0, 0, 1.25) 0.3s;
`;

export function Tabs({ tabs }: TabsProps): JSX.Element {
  const [activeTab, setActiveTab] = useState(0);
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [underlineLeft, setUnderlineLeft] = useState(0);
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const processedTabs = tabs.filter((tab) => tab.showCondition !== false);

  useEffect(() => {
    const selectedTabIndex = processedTabs.findIndex((tab) => tab.selected);
    if (selectedTabIndex !== -1) {
      setActiveTab(selectedTabIndex);
    }

    const activeTabRef = tabRefs.current[activeTab];
    if (activeTabRef) {
      setUnderlineWidth(activeTabRef.clientWidth);
      setUnderlineLeft(activeTabRef.offsetLeft);
    }
  }, [processedTabs, activeTab]);

  const handleTabClick = (index: number): void => {
    if (activeTab === index) return;
    setActiveTab(index);
    processedTabs[index].onClick();
  };

  return (
    <TabsContainer ref={containerRef}>
      {processedTabs.map((tab, index) => (
        <Tooltip content={tab.tooltip} key={index}>
          <Tab
            myRef={(el) => (tabRefs.current[index] = el)}
            onClick={() => handleTabClick(index)}
            customStyles={tab.customStyles}
            dataTestId={tab.dataTestId}
            iconRight={tab.iconRight}
            state={
              tab.disabled
                ? 'disabled'
                : index === activeTab || tab.selected
                ? 'selected'
                : undefined
            }
            icon={tab.icon}
          >
            {tab.text}
          </Tab>
        </Tooltip>
      ))}
      <Underline width={underlineWidth} left={underlineLeft} />
    </TabsContainer>
  );
}
