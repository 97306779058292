import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '../typography';
import type { IconType } from '@innovamat/glimmer-icons';
import { Icon } from '../icon';

type MenuItemState = 'active' | 'disabled' | 'selected';

export type TabProps = {
  state?: MenuItemState;
  icon?: IconType;
  fill?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customStyles?: string;
  dataTestId?: string;
  iconRight?: IconType;
  myRef?: React.Ref<HTMLButtonElement>;
};

const StyledTab = styled.button<{
  state: MenuItemState;
  customStyles?: string;
  fill?: boolean;
}>`
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  width: ${({ fill }) => (fill ? '100%' : '')};
  height: 40px;

  .overflow {
    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  background-color: white;
  color: ${({ theme, state }) => {
    if (state === 'disabled') {
      return theme.tokens.color.alias.cm.text['text-disabled'].value;
    } else if (state === 'active') {
      return theme.tokens.color.alias.cm.text['text-subtle'].value;
    } else {
      return theme.tokens.color.alias.cm.text['text'].value;
    }
  }};

  ${({ state, theme }) =>
    state === 'active' &&
    css`
      &:hover {
        color: ${theme.tokens.color.alias.cm.text.text.value};
      }

      &:active {
        box-shadow: 0 -2px 0 ${theme.tokens.color.alias.cm.border[
              'border-subtle'
            ].value} inset,
          0 1px 0 ${theme.tokens.color.alias.cm.border['border-subtle'].value};
      }
    `}

  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  .tabIcon {
    path {
      fill: ${({ theme, state }) =>
        theme.tokens.color.alias.cm.icon[
          state === 'disabled' ? 'icon-disabled' : 'icon-default'
        ].value};
    }
  }

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

const Text = styled(Typography.Subtitle2)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RightIconWrapper = styled.div`
  margin-left: auto;
`;

export function Tab({
  state = 'active',
  icon,
  fill,
  children,
  onClick,
  customStyles,
  dataTestId,
  iconRight,
  myRef,
}: TabProps): JSX.Element {
  return (
    <StyledTab
      state={state}
      onClick={onClick}
      customStyles={customStyles}
      data-testid={dataTestId}
      fill={fill}
      ref={myRef}
    >
      {icon && <Icon icon={icon} size="M" className="tabIcon" />}
      {!!children && <Text className="menuText">{children}</Text>}
      {iconRight && (
        <RightIconWrapper>
          <Icon icon={iconRight} size="M" className="tabIcon" />
        </RightIconWrapper>
      )}
    </StyledTab>
  );
}
