/* eslint-disable */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useFetch } from '../use-fetch';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Json: { input: any; output: any };
  Upload: { input: any; output: any };
  Void: { input: any; output: any };
};

export type AcademicYear = {
  isTeacherPeriod: Scalars['Boolean']['output'];
  isUpdatePeriod: Scalars['Boolean']['output'];
  studentEndDate: Scalars['String']['output'];
  studentStartDate: Scalars['String']['output'];
  teacherEndDate: Scalars['String']['output'];
  teacherStartDate: Scalars['String']['output'];
};

export type Activity = {
  codenamepack: Scalars['String']['output'];
  screenshot: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Address */
export type Address = {
  administrativeRegion: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  formattedAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  province?: Maybe<Scalars['String']['output']>;
  reference: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type AnswersMetrics = {
  largestAnswerWidth: Scalars['Int']['output'];
};

export type Applet = {
  codename?: Maybe<Scalars['String']['output']>;
  completed?: Maybe<Scalars['Boolean']['output']>;
  data?: Maybe<AppletData>;
  index?: Maybe<Scalars['Int']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  pack?: Maybe<Scalars['Int']['output']>;
  sceneName?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  stars?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unityActivity?: Maybe<Scalars['Json']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type AppletBlackList = {
  pack: Scalars['String']['output'];
  sceneName: Scalars['String']['output'];
};

export type AppletBlacklistBody = {
  connection?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  pack?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  sceneName: Scalars['String']['input'];
  stage: Scalars['String']['input'];
  userAgent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type AppletData = {
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type AppletsAttempted = {
  applets?: Maybe<Array<Maybe<ContentApplet>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type AssessmentPacing = {
  lessons?: Maybe<Array<Lesson>>;
  title: Scalars['String']['output'];
};

export type AssignTeacherToClassroomsBody = {
  classroomIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  email: Scalars['String']['input'];
};

export type AssignTeachersToClassroomBody = {
  classId: Scalars['ID']['input'];
  className: Scalars['String']['input'];
  inviterId: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  schoolId: Scalars['ID']['input'];
  teacherEmails: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Banners = {
  canBeClosed: Scalars['Boolean']['output'];
  colour: Scalars['String']['output'];
  cta: Scalars['Boolean']['output'];
  ctaExternal: Scalars['Boolean']['output'];
  ctaLink: Scalars['String']['output'];
  ctaText: Scalars['String']['output'];
  icon?: Maybe<Icon>;
  id: Scalars['String']['output'];
  importance: Scalars['Int']['output'];
  sectionCv: Scalars['Boolean']['output'];
  sectionDp: Scalars['Boolean']['output'];
  sectionTr: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type BannersFilter = {
  course?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Block = {
  blockId?: Maybe<Scalars['ID']['output']>;
  blockTitle?: Maybe<TextTranslation>;
  blockTooltip?: Maybe<TextTranslation>;
  contents?: Maybe<Array<Maybe<BlockContent>>>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type BlockContent = {
  contentId?: Maybe<Scalars['ID']['output']>;
  contentTitle?: Maybe<TextTranslation>;
  contentTooltip?: Maybe<TextTranslation>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type Bubble = {
  bubbleTextCode: Scalars['String']['output'];
  hasDifficulty?: Maybe<Scalars['Boolean']['output']>;
  readableId?: Maybe<Scalars['String']['output']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Card = {
  operand1: Scalars['Int']['output'];
  status: CardType;
  studentsFailed: Array<StudentFailed>;
  uuid: Scalars['ID']['output'];
};

export enum CardType {
  FairlyFailed = 'fairlyFailed',
  InProgress = 'inProgress',
  Locked = 'locked',
  Memorized = 'memorized',
  MostlyFailed = 'mostlyFailed',
  NotSeen = 'notSeen',
}

export type ChangeOrganizationBody = {
  schoolId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ChangeStudentOrganizationBody = {
  classroomId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type ChangeTeacherOrganizationBody = {
  schoolId: Scalars['ID']['input'];
  teacherId: Scalars['ID']['input'];
};

export type ChangeUserPasswordBody = {
  password?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CheckGoogleClassroomConnectionBody = {
  code: Scalars['String']['input'];
  redirectTo: Scalars['String']['input'];
};

export type Classroom = {
  avatar?: Maybe<Scalars['String']['output']>;
  checked?: Maybe<Scalars['Boolean']['output']>;
  courseId?: Maybe<Scalars['ID']['output']>;
  courseName?: Maybe<Scalars['String']['output']>;
  courseOrder?: Maybe<Scalars['Int']['output']>;
  digitalPracticeAccess?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfPendingInvitations?: Maybe<Scalars['Int']['output']>;
  numberOfStudents?: Maybe<Scalars['Int']['output']>;
  numberOfStudentsWithHomeAccess?: Maybe<Scalars['Int']['output']>;
  numberOfTeachers?: Maybe<Scalars['Int']['output']>;
  pilotStatus?: Maybe<PilotStatus>;
  provider?: Maybe<Scalars['String']['output']>;
  providerChecked?: Maybe<Scalars['Boolean']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scheduleEnd?: Maybe<Scalars['String']['output']>;
  scheduleStart?: Maybe<Scalars['String']['output']>;
  teachersNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ClassroomAdminDashboard = {
  classroom: Scalars['String']['output'];
  classroomId: Scalars['String']['output'];
  course: CourseAdminDashboard;
  digitalPractice: DigitalPracticeAdminDashboard;
  sessions: SessionAdminDashboard;
  students: StudentAdminDashboard;
  teachers: TeachersAdminDashboard;
};

export type ClassroomBody = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  icon?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  level?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  scheduleEnd?: InputMaybe<Scalars['String']['input']>;
  scheduleStart?: InputMaybe<Scalars['String']['input']>;
};

export type ClassroomInfo = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ClassroomTestRoundParticipation = {
  completed?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ClassroomTestRoundReport = {
  pdfBase64?: Maybe<Scalars['String']['output']>;
};

export type ClassroomTestRoundReportDetail = {
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  numberOfStatements?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<ClassroomTestRoundParticipation>;
  startDate?: Maybe<Scalars['String']['output']>;
  statements: Array<StatementMetadata>;
  status?: Maybe<Scalars['String']['output']>;
  students: Array<ClassroomTestRoundStudents>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ClassroomTestRoundStatements = {
  answer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

export type ClassroomTestRoundStudents = {
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  statements?: Maybe<Array<Maybe<ClassroomTestRoundStatements>>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ClassroomTestStatements = {
  total?: Maybe<Scalars['Int']['output']>;
};

export type ClassroomToImport = {
  course_order?: InputMaybe<Scalars['Int']['input']>;
  google_id?: InputMaybe<Scalars['String']['input']>;
};

export type ClassroomsCsv = {
  base64?: Maybe<Scalars['String']['output']>;
};

export type CleverToken = {
  access_token: Scalars['String']['output'];
  token_type: Scalars['String']['output'];
};

export type CompleteAppletBody = {
  metric: Metric;
  offlineSession?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * opinion: {
   *   opinionTypeCodename: "string",
   *   rating: 0
   *   }
   */
  sessionType?: InputMaybe<Scalars['String']['input']>;
  user: Scalars['Int']['input'];
  userToken: Scalars['String']['input'];
};

export type CompleteInvitationBody = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invitationId?: InputMaybe<Scalars['String']['input']>;
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CompletedResource = {
  resourceId: Scalars['String']['output'];
  solutionsEnabled: Scalars['Boolean']['output'];
};

export type Content = {
  applets?: Maybe<Array<Maybe<ContentApplet>>>;
  contentId?: Maybe<Scalars['ID']['output']>;
  contentTextCode?: Maybe<Scalars['String']['output']>;
  contentTitle?: Maybe<TextTranslation>;
  contentTooltip?: Maybe<TextTranslation>;
  score?: Maybe<Scalars['Float']['output']>;
  skillTextCode?: Maybe<Scalars['String']['output']>;
};

export type ContentApplet = {
  contentTextCode?: Maybe<Scalars['String']['output']>;
  data?: Maybe<AppletData>;
  maxScoreCurrentWeek?: Maybe<Scalars['Float']['output']>;
  pack: Scalars['Int']['output'];
  passed?: Maybe<Scalars['Boolean']['output']>;
  readableId?: Maybe<Scalars['String']['output']>;
  sceneName: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ContentList = {
  translated_resource_list: TranslatedResourceList;
};

export type CoreActivitiesByBubble = {
  coreActivities?: Maybe<Array<Maybe<ContentApplet>>>;
  readableName?: Maybe<Scalars['String']['output']>;
};

export type Course = CourseInterface & {
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type CourseAdminDashboard = {
  courseName: Scalars['String']['output'];
  courseOrder: Scalars['Int']['output'];
};

export type CourseInterface = {
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type CoursePermission = {
  courseId: Scalars['ID']['output'];
  permission?: Maybe<Permission>;
};

export type CreateOrUpdateStudentBody = {
  alias?: InputMaybe<Scalars['String']['input']>;
  classroomId: Scalars['ID']['input'];
  educationalPlanId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  homeAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  pinCode?: InputMaybe<Scalars['String']['input']>;
  studentAvatar?: InputMaybe<Scalars['String']['input']>;
  studentIcon: Scalars['Int']['input'];
  trainingAccess?: InputMaybe<Scalars['Boolean']['input']>;
  trainingTimeLimit?: InputMaybe<Scalars['Int']['input']>;
  weeklyTimeLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateOrderBody = {
  academicYearId: Scalars['String']['input'];
  billingCustomerId: Scalars['String']['input'];
  billingCustomerName: Scalars['String']['input'];
  estimateId?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<InputMaybe<OrderItem>>>;
  orderId: Scalars['ID']['input'];
  orderType: OrderType;
  orderTypeSale: OrderTypeSale;
  organizationId: Scalars['String']['input'];
  shippingAddress?: InputMaybe<ShippingAddress>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CrmSchool = {
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  regionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CrmSchoolName = {
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Customer = {
  custentityns_cif_ctrl_duplic?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export enum DigitalPracticeAccess {
  HomeAccess = 'HOME_ACCESS',
  HomeTraining = 'HOME_TRAINING',
  NoAccess = 'NO_ACCESS',
}

export type DigitalPracticeAdminDashboard = {
  acquiredContent: Scalars['Int']['output'];
  avgInnovamatScore: Scalars['Float']['output'];
  avgScore?: Maybe<Scalars['Float']['output']>;
  studentsPerStrip: Array<Scalars['Int']['output']>;
};

export type EditSettingsBody = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  positions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EducationalPlan = {
  code?: Maybe<Scalars['String']['output']>;
  courseId: Scalars['ID']['output'];
  courseName?: Maybe<Scalars['String']['output']>;
  courseOrder?: Maybe<Scalars['Int']['output']>;
  educationalPlanName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  region?: Maybe<Scalars['String']['output']>;
};

export type ErrorManagerBody = {
  deviceID?: InputMaybe<Scalars['String']['input']>;
  errorDescription?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  origin: Scalars['String']['input'];
  schoolID?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type FamilyPaymentItem = {
  order: FamilyPaymentOrder;
  product: FamilyPaymentProduct;
  student: FamilyPaymentStudent;
};

export type FamilyPaymentOrder = {
  financialStatus: Scalars['String']['output'];
  payoutDate: Scalars['String']['output'];
  readableExternalId: Scalars['String']['output'];
};

export type FamilyPaymentProduct = {
  courseName: Scalars['String']['output'];
  courseOrder: Scalars['Int']['output'];
  language?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type FamilyPaymentStudent = {
  name?: Maybe<Scalars['String']['output']>;
};

export type FamilyPayments = {
  countAll: Scalars['Int']['output'];
  countByPage: Scalars['Int']['output'];
  items: Array<FamilyPaymentItem>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type File = {
  fragments?: Maybe<Array<Maybe<Fragment>>>;
  href?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Length>;
  original_file_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

/** Final Customer - CRM */
export type FinalCustomerCrm = {
  a80Email?: Maybe<Scalars['String']['output']>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressFormattedAddress?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressProvince?: Maybe<Scalars['String']['output']>;
  /** Unique ID of the customer */
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  languageEmail?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  regionId?: Maybe<Scalars['String']['output']>;
  schoolPhone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Final Customer - ERP */
export type FinalCustomerErp = {
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Array<Address>>;
};

/** Final Customer Of Billing */
export type FinalCustomerOfBilling = {
  billingAddress: Address;
  cif?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type FluencyMultiplicationReport = {
  multiplicationTables: Array<MultiplicationTable>;
  operator?: Maybe<Scalars['String']['output']>;
  students: Array<StudentReports>;
  titleTranslationKey?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type Fragment = {
  caption?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['Float']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['Float']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

export type GetChallengesBody = {
  academicYearId: Scalars['ID']['input'];
  classroomId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type GetInvitationParams = {
  classroomId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  type: UserType;
};

export type GetSpacesBody = {
  classroomId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type GoogleClassroom = {
  course_order?: Maybe<Scalars['Int']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
  imported?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GoogleClassrooms = {
  classrooms?: Maybe<Array<Maybe<GoogleClassroom>>>;
};

export type Icon = {
  height?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type ImportedClassroom = {
  course_order?: Maybe<Scalars['Int']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
};

export type IntervalDates = {
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type InterventionReport = {
  studentsCurrentlyInIntervention: Array<StudentIntervention>;
  studentsNoLongerInIntervention: Array<StudentIntervention>;
  weeks: InterventionReportWeeks;
};

export type InterventionReportWeeks = {
  currentWeekNumber: Scalars['Int']['output'];
  interventionWeeks: Array<IntervalDates>;
};

export type InvitationBody = {
  classrooms?: InputMaybe<Array<InputMaybe<ClassroomInfo>>>;
  forceRoleChange?: InputMaybe<Scalars['Boolean']['input']>;
  invitedEmails?: InputMaybe<Array<InputMaybe<InvitedEmailBody>>>;
  inviterId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  operativeRegion?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  students?: InputMaybe<Array<InputMaybe<InvitationStudentBody>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type InvitationStudentBody = {
  className?: InputMaybe<Scalars['String']['input']>;
  courseName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
};

export type InvitedEmailBody = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  invitedId: Scalars['ID']['input'];
};

export type Item = {
  items?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  totalResults?: Maybe<Scalars['Int']['output']>;
};

export type Items = {
  id?: Maybe<Scalars['ID']['output']>;
  links?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
};

export type LastYearReport = {
  fromDate: Scalars['String']['output'];
  reports: Array<LastYearReportData>;
  toDate: Scalars['String']['output'];
};

export type LastYearReportData = {
  average: ProgressReportData;
  courseOrder?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  operativeRegion?: Maybe<Scalars['String']['output']>;
  students: Array<ProgressReportData>;
};

export type LastYearReports = {
  academicYear?: Maybe<Scalars['String']['output']>;
  isLastYear?: Maybe<Scalars['Boolean']['output']>;
};

export type Length = {
  length?: Maybe<Scalars['Int']['output']>;
};

export type Lesson = {
  duration: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type LinkedLists = {
  related_resource_lists?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  suggested_resource_lists?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MarkChallengeBody = {
  academicYearId: Scalars['String']['input'];
  challengeId: Scalars['ID']['input'];
  classroomId: Scalars['ID']['input'];
  done: Scalars['Boolean']['input'];
};

export type MarkSessionBody = {
  academicYearId: Scalars['String']['input'];
  classroomId: Scalars['ID']['input'];
  done: Scalars['Boolean']['input'];
  resourceId: Scalars['ID']['input'];
  solutionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MarkSpaceBody = {
  academicYearId: Scalars['String']['input'];
  challengeId: Scalars['String']['input'];
  classroomId: Scalars['ID']['input'];
  pinned: Scalars['Boolean']['input'];
  sessionId: Scalars['String']['input'];
  spaceId: Scalars['String']['input'];
};

/** Material */
export type Material = {
  courseId: Scalars['String']['output'];
  courseName: Scalars['String']['output'];
  courseOrder: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  languageId?: Maybe<Scalars['String']['output']>;
  maxReturnableItems: Scalars['Int']['output'];
  productId: Scalars['ID']['output'];
  productName: Scalars['String']['output'];
  productShortname: Scalars['String']['output'];
  productType: Scalars['String']['output'];
  productTypeOfMaterial: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  stageId: Scalars['String']['output'];
  stageName: Scalars['String']['output'];
  stageOrder: Scalars['Int']['output'];
};

/** Material Order. The name of this type is because the Order type already exists */
export type MaterialOrder = {
  academicYearId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  estimateNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orderStatus: Scalars['String']['output'];
  orderType: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  readableId?: Maybe<Scalars['String']['output']>;
  returnDeadlineDate?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
};

export type Member = {
  id?: Maybe<Scalars['ID']['output']>;
  reportsEnabled?: Maybe<Scalars['Boolean']['output']>;
  userInfo?: Maybe<UserInfo>;
};

export type Metric = {
  activityAttemptData?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  codename: Scalars['String']['input'];
  coins?: InputMaybe<Scalars['Int']['input']>;
  currentMultiplier?: InputMaybe<Scalars['Int']['input']>;
  duration: Scalars['Int']['input'];
  duration_exceeded?: InputMaybe<Scalars['Boolean']['input']>;
  gems?: InputMaybe<Scalars['Int']['input']>;
  hasReachedMaxMultiplier?: InputMaybe<Scalars['Boolean']['input']>;
  is_invalid?: InputMaybe<Scalars['Boolean']['input']>;
  is_motor: Scalars['Boolean']['input'];
  max_duration: Scalars['Int']['input'];
  metric: Scalars['String']['input'];
  min_duration: Scalars['Int']['input'];
  min_statements: Scalars['Int']['input'];
  order: Scalars['Int']['input'];
  pack: Scalars['Int']['input'];
  scene_name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  variation: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type Module = {
  standards: Array<Standard>;
  stretch: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ModulePacing = {
  lessons?: Maybe<Array<Lesson>>;
  title: Scalars['String']['output'];
};

export type MultiplicationTable = {
  cards: Array<Card>;
  operand2: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

export type Mutation = {
  addAppletToWarningList?: Maybe<Scalars['Void']['output']>;
  assignTeacherToClassrooms?: Maybe<Scalars['Void']['output']>;
  assignTeachersToClassroom?: Maybe<Scalars['Void']['output']>;
  changeOrganization?: Maybe<Scalars['Void']['output']>;
  changePassword?: Maybe<Scalars['Void']['output']>;
  changeStudentOrganization?: Maybe<Scalars['Void']['output']>;
  changeTeacherOrganization?: Maybe<Scalars['Void']['output']>;
  changeUserPassword?: Maybe<Scalars['Void']['output']>;
  checkGoogleClassroomConnection?: Maybe<Scalars['Void']['output']>;
  completeApplet?: Maybe<Scalars['Void']['output']>;
  completeParentInvitation?: Maybe<Scalars['Void']['output']>;
  completeUserInvitation?: Maybe<Scalars['Void']['output']>;
  createOrUpdateStudent?: Maybe<Scalars['Void']['output']>;
  createOrder?: Maybe<Scalars['Void']['output']>;
  deleteAppletFromBlacklist?: Maybe<Scalars['Void']['output']>;
  deleteClassroom?: Maybe<Scalars['Void']['output']>;
  deleteInvitation?: Maybe<Scalars['Void']['output']>;
  deleteStudent?: Maybe<Scalars['Void']['output']>;
  deleteTeacher?: Maybe<Scalars['Void']['output']>;
  deleteUser?: Maybe<Scalars['Void']['output']>;
  disconnectGoogleClassroom?: Maybe<Scalars['Void']['output']>;
  editPreferences?: Maybe<Scalars['Void']['output']>;
  editSettings?: Maybe<Scalars['Void']['output']>;
  importGoogleClassrooms?: Maybe<Array<Maybe<ImportedClassroom>>>;
  inviteUsers?: Maybe<Array<Maybe<Member>>>;
  markChallenge?: Maybe<Scalars['Void']['output']>;
  markClassroomAsChecked?: Maybe<Scalars['Void']['output']>;
  markSession?: Maybe<Scalars['Void']['output']>;
  markSpace?: Maybe<Scalars['Void']['output']>;
  putClassroom?: Maybe<Classroom>;
  recoverStudent?: Maybe<Scalars['Void']['output']>;
  refreshToken?: Maybe<TokenData>;
  removeClassroomConnection?: Maybe<Scalars['Void']['output']>;
  resendInvitation?: Maybe<Scalars['Void']['output']>;
  resendInvitations?: Maybe<Scalars['Void']['output']>;
  resendParentsInvitation?: Maybe<Scalars['Void']['output']>;
  resendReport?: Maybe<Scalars['Void']['output']>;
  resendSchoolInvitation?: Maybe<Scalars['Void']['output']>;
  resetTests?: Maybe<Scalars['Void']['output']>;
  saveSessionReview?: Maybe<Scalars['Void']['output']>;
  saveSubscriptionsByEmail?: Maybe<Scalars['Void']['output']>;
  sendAndManageError?: Maybe<Scalars['Void']['output']>;
  setAppletToBlacklist?: Maybe<Scalars['Void']['output']>;
  skipSessionActivity?: Maybe<Scalars['Void']['output']>;
  startWeeklyPractice?: Maybe<WeeklyPractice>;
  syncroGoogleClassroom?: Maybe<Scalars['Void']['output']>;
  trackVideoProgress?: Maybe<Scalars['Void']['output']>;
  unlinkParent?: Maybe<Scalars['Void']['output']>;
  unlinkTeacher?: Maybe<Scalars['Void']['output']>;
  updateDigitalPracticeAccess?: Maybe<Scalars['Void']['output']>;
  updateReportsPreference?: Maybe<Scalars['Void']['output']>;
  updateRti?: Maybe<Rti>;
  updateStudentAvatar?: Maybe<Scalars['Void']['output']>;
  updateStudentClassroom?: Maybe<Scalars['Void']['output']>;
  updateTimezone?: Maybe<Scalars['Void']['output']>;
  updateUser?: Maybe<Scalars['Void']['output']>;
  updateUserRole?: Maybe<Scalars['Void']['output']>;
};

export type MutationAddAppletToWarningListArgs = {
  data?: InputMaybe<AppletBlacklistBody>;
};

export type MutationAssignTeacherToClassroomsArgs = {
  body?: InputMaybe<AssignTeacherToClassroomsBody>;
};

export type MutationAssignTeachersToClassroomArgs = {
  body?: InputMaybe<AssignTeachersToClassroomBody>;
};

export type MutationChangeOrganizationArgs = {
  body: ChangeOrganizationBody;
};

export type MutationChangePasswordArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationChangeStudentOrganizationArgs = {
  body: ChangeStudentOrganizationBody;
};

export type MutationChangeTeacherOrganizationArgs = {
  body: ChangeTeacherOrganizationBody;
};

export type MutationChangeUserPasswordArgs = {
  body: ChangeUserPasswordBody;
};

export type MutationCheckGoogleClassroomConnectionArgs = {
  body?: InputMaybe<CheckGoogleClassroomConnectionBody>;
};

export type MutationCompleteAppletArgs = {
  body?: InputMaybe<CompleteAppletBody>;
};

export type MutationCompleteParentInvitationArgs = {
  body: CompleteInvitationBody;
};

export type MutationCompleteUserInvitationArgs = {
  body: CompleteInvitationBody;
};

export type MutationCreateOrUpdateStudentArgs = {
  body?: InputMaybe<CreateOrUpdateStudentBody>;
  fromGoogleClassroom?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateOrderArgs = {
  order?: InputMaybe<CreateOrderBody>;
};

export type MutationDeleteAppletFromBlacklistArgs = {
  data?: InputMaybe<AppletBlacklistBody>;
};

export type MutationDeleteClassroomArgs = {
  classroomId: Scalars['ID']['input'];
};

export type MutationDeleteInvitationArgs = {
  invitationId: Scalars['ID']['input'];
  isSchoolInvitation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationDeleteStudentArgs = {
  studentId: Scalars['ID']['input'];
};

export type MutationDeleteTeacherArgs = {
  teacherId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationEditPreferencesArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditSettingsArgs = {
  body?: InputMaybe<EditSettingsBody>;
};

export type MutationImportGoogleClassroomsArgs = {
  classroomsToImport?: InputMaybe<Array<InputMaybe<ClassroomToImport>>>;
};

export type MutationInviteUsersArgs = {
  invitations: InvitationBody;
};

export type MutationMarkChallengeArgs = {
  body: MarkChallengeBody;
};

export type MutationMarkClassroomAsCheckedArgs = {
  classroomId: Scalars['ID']['input'];
};

export type MutationMarkSessionArgs = {
  body: MarkSessionBody;
};

export type MutationMarkSpaceArgs = {
  body: MarkSpaceBody;
};

export type MutationPutClassroomArgs = {
  classroom: ClassroomBody;
};

export type MutationRecoverStudentArgs = {
  body?: InputMaybe<RecoverStudentBody>;
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationRemoveClassroomConnectionArgs = {
  classroomId: Scalars['String']['input'];
};

export type MutationResendInvitationArgs = {
  body?: InputMaybe<ResendInvitationBody>;
};

export type MutationResendInvitationsArgs = {
  body?: InputMaybe<Array<InputMaybe<ResendInvitationBody>>>;
};

export type MutationResendParentsInvitationArgs = {
  body?: InputMaybe<Array<InputMaybe<ResendInvitationBody>>>;
};

export type MutationResendReportArgs = {
  body: ResendReportBody;
};

export type MutationResendSchoolInvitationArgs = {
  body?: InputMaybe<ResendInvitationBody>;
};

export type MutationResetTestsArgs = {
  body: ResetTestsBody;
};

export type MutationSaveSessionReviewArgs = {
  body: SessionReviewBody;
};

export type MutationSaveSubscriptionsByEmailArgs = {
  email: Scalars['String']['input'];
  subscribedList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  unsubscribedList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type MutationSendAndManageErrorArgs = {
  data?: InputMaybe<ErrorManagerBody>;
};

export type MutationSetAppletToBlacklistArgs = {
  data?: InputMaybe<AppletBlacklistBody>;
};

export type MutationSkipSessionActivityArgs = {
  body?: InputMaybe<SkipSessionActivityBody>;
};

export type MutationStartWeeklyPracticeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSyncroGoogleClassroomArgs = {
  classroomToImport: ClassroomToImport;
};

export type MutationTrackVideoProgressArgs = {
  body: TrackVideoProgressBody;
};

export type MutationUnlinkParentArgs = {
  body: UnlinkParentBody;
};

export type MutationUnlinkTeacherArgs = {
  body?: InputMaybe<UnlinkTeacherBody>;
};

export type MutationUpdateDigitalPracticeAccessArgs = {
  body: UpdateDigitalPracticeAccessBody;
};

export type MutationUpdateReportsPreferenceArgs = {
  body?: InputMaybe<UpdateReportsBody>;
};

export type MutationUpdateRtiArgs = {
  body: UpdateRtiBody;
};

export type MutationUpdateStudentAvatarArgs = {
  body?: InputMaybe<UpdateStudentAvatarBody>;
};

export type MutationUpdateStudentClassroomArgs = {
  body: UpdateStudentClassroomBody;
};

export type MutationUpdateTimezoneArgs = {
  body: UpdateTimeZoneBody;
};

export type MutationUpdateUserArgs = {
  body?: InputMaybe<UpdateUserBody>;
};

export type MutationUpdateUserRoleArgs = {
  body?: InputMaybe<UpdateUserRoleBody>;
};

export type OngoingOrders = {
  hasOngoingOrders: Scalars['Boolean']['output'];
};

export type Order = {
  cif?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Customer>;
  items?: Maybe<Array<Maybe<Items>>>;
  orgId?: Maybe<Scalars['ID']['output']>;
  salesOrders?: Maybe<Array<Maybe<SalesOrder>>>;
  totalResults?: Maybe<Scalars['Int']['output']>;
};

/** The details of order including material list, shipping address, and billing address */
export type OrderDetails = {
  billingAddress: Array<Scalars['String']['output']>;
  materials: Array<OrderDetailsMaterial>;
  shippingAddress: Array<Maybe<Scalars['String']['output']>>;
};

export type OrderDetailsMaterial = {
  course: Scalars['String']['output'];
  courseOrder: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  stageOrder: Scalars['Int']['output'];
  totalOperationQuantity: Scalars['Int']['output'];
  units?: Maybe<Scalars['String']['output']>;
};

export type OrderItem = {
  id: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export enum OrderType {
  Expand = 'expand',
  Return = 'return',
}

export enum OrderTypeSale {
  Direct = 'direct',
  DirectFive = 'direct_five',
  DirectFour = 'direct_four',
  DirectSix = 'direct_six',
  Prescription = 'prescription',
  Web = 'web',
}

export type OrderedResource = {
  order?: Maybe<Scalars['Int']['output']>;
  resource_view?: Maybe<Resource>;
};

export type OrderedTranslatedResourceList = {
  order?: Maybe<Scalars['Int']['output']>;
  translated_resource_list?: Maybe<TranslatedResourceList>;
};

export type Organization = {
  academicYear: AcademicYear;
  academicYearId: Scalars['String']['output'];
  anonymized?: Maybe<Scalars['Boolean']['output']>;
  availableLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  importedStudentsWithSisId?: Maybe<Scalars['Int']['output']>;
  isLATAM?: Maybe<Scalars['Boolean']['output']>;
  language: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextAcademicYear?: Maybe<AcademicYear>;
  numberOfClassrooms?: Maybe<Scalars['Int']['output']>;
  numberOfDeletedStudents?: Maybe<Scalars['Int']['output']>;
  numberOfImports?: Maybe<Scalars['Int']['output']>;
  numberOfStudents?: Maybe<Scalars['Int']['output']>;
  numberOfTeachers?: Maybe<Scalars['Int']['output']>;
  operativeRegion?: Maybe<Scalars['String']['output']>;
  platformEnabled?: Maybe<Scalars['Boolean']['output']>;
  region: Scalars['String']['output'];
  standard?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type OrganizationsCourse = CourseInterface & {
  adminDashboard: Scalars['Boolean']['output'];
  assessment: Scalars['Boolean']['output'];
  earlyIntervention: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  platformEnabled: Scalars['Boolean']['output'];
  region: Scalars['String']['output'];
  tutoringChat: Scalars['Boolean']['output'];
};

export type OtherContents = {
  contents?: Maybe<Array<Maybe<Content>>>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type Page = {
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ordered_translated_resource_lists?: Maybe<
    Array<Maybe<OrderedTranslatedResourceList>>
  >;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type PaginatedListBody = {
  academicYearId: Scalars['String']['input'];
  classroomId: Scalars['ID']['input'];
  listId: Scalars['ID']['input'];
  paginated: Scalars['Boolean']['input'];
};

export type PaginationBody = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type Permission = {
  adminDashboard?: Maybe<Scalars['Boolean']['output']>;
  assessment?: Maybe<Scalars['Boolean']['output']>;
  earlyIntervention?: Maybe<Scalars['Boolean']['output']>;
  tutoringChat?: Maybe<Scalars['Boolean']['output']>;
};

export type PilotStatus = {
  RTI?: Maybe<Scalars['Boolean']['output']>;
  pilotControl?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  vertexTest?: Maybe<Scalars['Boolean']['output']>;
};

export type PreloadPageBody = {
  academicYearId: Scalars['String']['input'];
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  pageId: Scalars['ID']['input'];
};

export type Printable = {
  display_name?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  id?: Maybe<Scalars['ID']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

export type ProgressReport = {
  average: ProgressReportData;
  courseOrder?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  operativeRegion?: Maybe<Scalars['String']['output']>;
  students: Array<ProgressReportData>;
};

export type ProgressReportData = {
  appletsCompleted?: Maybe<Scalars['Float']['output']>;
  averageScore?: Maybe<Scalars['Float']['output']>;
  blocks?: Maybe<Array<Maybe<Block>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  otherContents?: Maybe<OtherContents>;
  timeSpentAll?: Maybe<Scalars['Float']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
  weekScores?: Maybe<Array<Maybe<WeekScore>>>;
};

export type ProgressReports = {
  dateFrom: Scalars['String']['output'];
  dateTo: Scalars['String']['output'];
  reports: Array<ProgressReport>;
};

export type Query = {
  /** Get an order by it's ID */
  Orders?: Maybe<Order>;
  academicYear?: Maybe<AcademicYear>;
  adminDashboard?: Maybe<Array<Maybe<ClassroomAdminDashboard>>>;
  allLanguages?: Maybe<Scalars['Json']['output']>;
  allOrganizations: Array<Maybe<Organization>>;
  appletsBySessionCode?: Maybe<Array<Maybe<Applet>>>;
  availableTests?: Maybe<Array<Maybe<Test>>>;
  banners: Array<Banners>;
  catchUpZone?: Maybe<WeeklyPractice>;
  challenges?: Maybe<Scalars['Json']['output']>;
  /** Check if there is an ongoing order for the current organization */
  checkHasOngoingOrders: OngoingOrders;
  checkStudent?: Maybe<Array<Maybe<StudentValidation>>>;
  classroom?: Maybe<Classroom>;
  classroomTestRoundAvailableLanguages?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  classroomTestRoundDetail?: Maybe<ClassroomTestRoundReportDetail>;
  classroomTestRoundReport?: Maybe<ClassroomTestRoundReport>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  classroomsCSV?: Maybe<ClassroomsCsv>;
  contentLists?: Maybe<Array<Maybe<ContentList>>>;
  contentsLanguages?: Maybe<Scalars['Json']['output']>;
  coreActivities?: Maybe<Array<Maybe<CoreActivitiesByBubble>>>;
  courses: Array<Course>;
  downloadLocales?: Maybe<Scalars['String']['output']>;
  educationalPlans?: Maybe<Array<Maybe<EducationalPlan>>>;
  /** Get the Family Payments - paginated family payments */
  familyPayments: FamilyPayments;
  fluencyDecks?: Maybe<Array<Scalars['String']['output']>>;
  fluencyMultiplicationReport?: Maybe<FluencyMultiplicationReport>;
  getAllAppletsFromBlacklist?: Maybe<Array<Maybe<AppletBlackList>>>;
  getAllCourses?: Maybe<Array<Maybe<Course>>>;
  getCompletedResourcesByClassroomId?: Maybe<Array<Maybe<CompletedResource>>>;
  getCurrentMaterials?: Maybe<Array<Maybe<StagedMaterials>>>;
  /** Get the CRM - final customer by it's ID */
  getFinalCustomerCrm?: Maybe<FinalCustomerCrm>;
  /** Get the ERP - final customer by it's ID */
  getFinalCustomerErp?: Maybe<FinalCustomerErp>;
  getFinalCustomerErpByOrganizationId?: Maybe<FinalCustomerErp>;
  getFinalCustomersOfBilling?: Maybe<Array<FinalCustomerOfBilling>>;
  getGoogleClassrooms?: Maybe<GoogleClassrooms>;
  getPermissions?: Maybe<Scalars['Json']['output']>;
  getReports?: Maybe<Array<Maybe<Report>>>;
  getStudentInvitations?: Maybe<Array<Maybe<Student>>>;
  getTeacherInvitations?: Maybe<Array<Maybe<Teacher>>>;
  getThirdPartyConnections?: Maybe<ThirdPartyConnections>;
  interventionReport?: Maybe<InterventionReport>;
  lastYearReport?: Maybe<LastYearReport>;
  lastYearReports?: Maybe<Array<Maybe<LastYearReports>>>;
  learningApiUrl?: Maybe<Scalars['String']['output']>;
  linkedLists?: Maybe<Array<Maybe<ResourceList>>>;
  materialOrders?: Maybe<Array<Maybe<MaterialOrder>>>;
  modulesOfCourse: Array<Module>;
  /** Get the details of a specific order */
  orderDetails?: Maybe<OrderDetails>;
  organization?: Maybe<Organization>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  organizationsCourses: Array<OrganizationsCourse>;
  page?: Maybe<Page>;
  paginatedList?: Maybe<Scalars['Json']['output']>;
  permissionCourses?: Maybe<Array<Maybe<CoursePermission>>>;
  platformApiUrl?: Maybe<Scalars['String']['output']>;
  progressReports?: Maybe<ProgressReports>;
  realtimeApplet?: Maybe<RealtimeApplet>;
  regionParameters?: Maybe<Scalars['Json']['output']>;
  regions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  registerLanguages?: Maybe<Scalars['Json']['output']>;
  resource?: Maybe<Scalars['Json']['output']>;
  resourceList?: Maybe<ResourceList>;
  revenueAcademicYears?: Maybe<Array<Maybe<RevenueAcademicYear>>>;
  revenueGetSchoolFromCrm?: Maybe<CrmSchool>;
  revenueOrganization?: Maybe<RevenueOrganization>;
  revenueOrganizationByCrmId?: Maybe<RevenueOrganization>;
  revenueSearchCrmSchoolByName?: Maybe<Array<Maybe<CrmSchoolName>>>;
  rosterings?: Maybe<Array<Maybe<Rostering>>>;
  salesForceSchoolsByCountry: Array<SalesForceSchool>;
  /** Get a sales order by it's ID */
  salesOrder?: Maybe<SalesOrder>;
  schoolPositions?: Maybe<Scalars['Json']['output']>;
  schoolStudents?: Maybe<Array<Maybe<Student>>>;
  sessionReview?: Maybe<SessionReview>;
  settings?: Maybe<Settings>;
  signClever: CleverToken;
  signJWT?: Maybe<Scalars['String']['output']>;
  sitemapLevels: Array<SitemapsLevel>;
  sitemapTree?: Maybe<Array<Maybe<SitemapTreeItem>>>;
  sitemaps: Array<Sitemap>;
  solverApiUrl?: Maybe<Scalars['String']['output']>;
  spaces?: Maybe<Scalars['Json']['output']>;
  starsFeedbackTrainingZone?: Maybe<Scalars['String']['output']>;
  stretchesOfCourse: Array<Stretch>;
  student?: Maybe<Student>;
  students?: Maybe<Array<Maybe<Student>>>;
  studentsByParent: Array<Maybe<StudentByParent>>;
  studentsCSV?: Maybe<StudentsCsv>;
  subscriptionsByEmail?: Maybe<Subscriptions>;
  suggestTeachers?: Maybe<Array<Maybe<Teacher>>>;
  teachers?: Maybe<Array<Maybe<Teacher>>>;
  test?: Maybe<TestData>;
  testsAndResults: Array<TestsAndResults>;
  trainingZone?: Maybe<WeeklyPractice>;
  weeklyPractice?: Maybe<WeeklyPractice>;
  weeklyReports?: Maybe<Array<Maybe<WeeklyReport>>>;
  weeklyReportsDates?: Maybe<Array<Maybe<WeeklyReportDate>>>;
};

export type QueryOrdersArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAcademicYearArgs = {
  academicYearId: Scalars['ID']['input'];
};

export type QueryAdminDashboardArgs = {
  organizationId: Scalars['ID']['input'];
  stage: Stages;
};

export type QueryAppletsBySessionCodeArgs = {
  classroomId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  courseOrder: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
  sessionCode: Scalars['String']['input'];
};

export type QueryAvailableTestsArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryBannersArgs = {
  filters: BannersFilter;
};

export type QueryCatchUpZoneArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryChallengesArgs = {
  body: GetChallengesBody;
};

export type QueryCheckHasOngoingOrdersArgs = {
  organizationId: Scalars['ID']['input'];
};

export type QueryCheckStudentArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  sisId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryClassroomArgs = {
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type QueryClassroomTestRoundAvailableLanguagesArgs = {
  testRoundId: Scalars['ID']['input'];
};

export type QueryClassroomTestRoundDetailArgs = {
  classroomId: Scalars['ID']['input'];
  testRoundId: Scalars['ID']['input'];
};

export type QueryClassroomTestRoundReportArgs = {
  language: Scalars['String']['input'];
  testRoundId: Scalars['ID']['input'];
};

export type QueryClassroomsArgs = {
  mine: Scalars['Boolean']['input'];
  orgId: Scalars['ID']['input'];
};

export type QueryContentListsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryCoreActivitiesArgs = {
  bubblePlanId: Scalars['ID']['input'];
  bubblesIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryCoursesArgs = {
  activeLevel?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  regionCode: Scalars['String']['input'];
};

export type QueryEducationalPlansArgs = {
  courseOrder?: InputMaybe<Scalars['Int']['input']>;
  regionCode: Scalars['String']['input'];
};

export type QueryFamilyPaymentsArgs = {
  academicYearId: Scalars['ID']['input'];
  courseOrder?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  regionId: Scalars['String']['input'];
  studentName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFluencyDecksArgs = {
  classroomId: Scalars['ID']['input'];
};

export type QueryFluencyMultiplicationReportArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCompletedResourcesByClassroomIdArgs = {
  academicYearId: Scalars['String']['input'];
  classroomId: Scalars['ID']['input'];
};

export type QueryGetCurrentMaterialsArgs = {
  academicYearId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type QueryGetFinalCustomerCrmArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetFinalCustomerErpArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetFinalCustomerErpByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
  visibleWeb?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetFinalCustomersOfBillingArgs = {
  organizationId: Scalars['ID']['input'];
};

export type QueryGetPermissionsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetReportsArgs = {
  classroomId: Scalars['ID']['input'];
  teacherId: Scalars['ID']['input'];
  teachers?: InputMaybe<Array<InputMaybe<TeacherInput>>>;
};

export type QueryGetStudentInvitationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryGetTeacherInvitationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryInterventionReportArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLastYearReportArgs = {
  id: Scalars['ID']['input'];
  standardStructure?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLastYearReportsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLinkedListsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryMaterialOrdersArgs = {
  academicYearId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryModulesOfCourseArgs = {
  courseUuid: Scalars['ID']['input'];
};

export type QueryOrderDetailsArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganizationsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrganizationsCoursesArgs = {
  academicYearId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type QueryPageArgs = {
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  paginated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPaginatedListArgs = {
  body: PaginatedListBody;
};

export type QueryPermissionCoursesArgs = {
  academicYearId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type QueryProgressReportsArgs = {
  from: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  stage: Scalars['String']['input'];
  standardStructure?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['String']['input'];
};

export type QueryRealtimeAppletArgs = {
  locale: Scalars['String']['input'];
  pack: Scalars['Int']['input'];
  sceneName: Scalars['String']['input'];
};

export type QueryRegionParametersArgs = {
  regionCode: Scalars['String']['input'];
  stage?: InputMaybe<Scalars['String']['input']>;
};

export type QueryResourceArgs = {
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  courseOrder?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  standard?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryResourceListArgs = {
  uuid: Scalars['ID']['input'];
};

export type QueryRevenueAcademicYearsArgs = {
  availableForSale?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  regionId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRevenueGetSchoolFromCrmArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRevenueOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRevenueOrganizationByCrmIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRevenueSearchCrmSchoolByNameArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRosteringsArgs = {
  organizationId: Scalars['ID']['input'];
};

export type QuerySalesForceSchoolsByCountryArgs = {
  country: Scalars['String']['input'];
};

export type QuerySalesOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySchoolStudentsArgs = {
  body?: InputMaybe<PaginationBody>;
};

export type QuerySessionReviewArgs = {
  academicYearId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type QuerySignCleverArgs = {
  code: Scalars['String']['input'];
  redirectTo: Scalars['String']['input'];
};

export type QuerySignJwtArgs = {
  payload?: InputMaybe<Scalars['Json']['input']>;
  secret?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySitemapLevelsArgs = {
  region: Scalars['String']['input'];
};

export type QuerySitemapTreeArgs = {
  courseOrder: Scalars['Int']['input'];
  level?: InputMaybe<Scalars['String']['input']>;
  region: Scalars['String']['input'];
  viewAs?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySitemapsArgs = {
  region: Scalars['String']['input'];
};

export type QuerySpacesArgs = {
  body: GetSpacesBody;
};

export type QueryStarsFeedbackTrainingZoneArgs = {
  activityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type QueryStretchesOfCourseArgs = {
  courseUuid: Scalars['ID']['input'];
};

export type QueryStudentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStudentsArgs = {
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySubscriptionsByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QuerySuggestTeachersArgs = {
  name: Scalars['String']['input'];
};

export type QueryTeachersArgs = {
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  isPlatformEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTestArgs = {
  testUuid: Scalars['ID']['input'];
  userTestUuid: Scalars['ID']['input'];
};

export type QueryTestsAndResultsArgs = {
  academicYearId: Scalars['ID']['input'];
  classroomId: Scalars['ID']['input'];
};

export type QueryTrainingZoneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWeeklyPracticeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWeeklyReportsArgs = {
  from: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  practicePeriod: Scalars['String']['input'];
  stage: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type QueryWeeklyReportsDatesArgs = {
  id: Scalars['ID']['input'];
  stage: Scalars['String']['input'];
};

export type RealtimeApplet = {
  data?: Maybe<AppletData>;
  pack?: Maybe<Scalars['Int']['output']>;
  sceneName?: Maybe<Scalars['String']['output']>;
};

export type RecoverStudentBody = {
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  studentId: Scalars['ID']['input'];
};

export type RegionParameters = {
  clock?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  decimalSeparator?: Maybe<Scalars['String']['output']>;
  decimalSeparatorAudio?: Maybe<Scalars['String']['output']>;
  metricSystem?: Maybe<Scalars['String']['output']>;
  shortDivisionSign?: Maybe<Scalars['String']['output']>;
  shortMultiplicationSignCollection?: Maybe<ShortMultiplicationSignType>;
  temperature?: Maybe<Scalars['String']['output']>;
  thousandSeparator?: Maybe<Scalars['String']['output']>;
  verticalDivisionAlgorithm?: Maybe<Scalars['String']['output']>;
  verticalMultiplicationPosition?: Maybe<Scalars['String']['output']>;
  verticalOperationsParameters?: Maybe<VerticalOperationsParametersType>;
  verticalSubtractionPosition?: Maybe<Scalars['String']['output']>;
  verticalSumPosition?: Maybe<Scalars['String']['output']>;
};

export type Report = {
  excelFileName?: Maybe<Scalars['String']['output']>;
  excelUrl?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  html?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  readableName?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ResendInvitationBody = {
  id: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type ResendReportBody = {
  classroomId: Scalars['ID']['input'];
  teacherId: Scalars['ID']['input'];
};

export type ResetTestsBody = {
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  studentId: Scalars['ID']['input'];
};

export type Resource = {
  available_locales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  done_status?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  enumeration?: Maybe<Scalars['String']['output']>;
  has_activities?: Maybe<Scalars['Boolean']['output']>;
  has_digital_guide?: Maybe<Scalars['Boolean']['output']>;
  has_solutions?: Maybe<Scalars['Boolean']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isAlreadySeen?: Maybe<Scalars['Boolean']['output']>;
  is_completable?: Maybe<Scalars['Boolean']['output']>;
  linked_lists?: Maybe<LinkedLists>;
  name?: Maybe<Scalars['String']['output']>;
  pack?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['String']['output']>;
  resourceData?: Maybe<ResourceData>;
  scene?: Maybe<Scalars['String']['output']>;
  script?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbnail?: Maybe<Thumbnail>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

export type ResourceBlocks = {
  name?: Maybe<Scalars['String']['output']>;
  ordered_resources?: Maybe<Array<Maybe<OrderedResource>>>;
};

export type ResourceData = ResourceBlocks | ResourceFile;

export type ResourceFile = {
  href?: Maybe<Scalars['String']['output']>;
  original_file_name?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ResourceList = {
  description?: Maybe<Scalars['String']['output']>;
  list_type?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  page_id?: Maybe<Scalars['String']['output']>;
  translated_ordered_resources?: Maybe<Array<Maybe<Resource>>>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type ResourceView = {
  _tags?: Maybe<Array<Maybe<Tag>>>;
  available_locales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  done_status?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  enumeration?: Maybe<Scalars['String']['output']>;
  has_activities?: Maybe<Scalars['Boolean']['output']>;
  has_digital_guide?: Maybe<Scalars['Boolean']['output']>;
  has_player?: Maybe<Scalars['Boolean']['output']>;
  has_solutions?: Maybe<Scalars['Boolean']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  is_completable?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  resource_id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbnail?: Maybe<Thumbnail>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

/** Revenue Academic Year for revenue app */
export type RevenueAcademicYear = {
  availableForSale?: Maybe<Scalars['Boolean']['output']>;
  current?: Maybe<Scalars['Boolean']['output']>;
  endAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  regionId?: Maybe<Scalars['String']['output']>;
  startAt?: Maybe<Scalars['String']['output']>;
};

export type RevenueOrganization = {
  crm_id?: Maybe<Scalars['String']['output']>;
  erp_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Rostering = {
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  items?: Maybe<Array<Maybe<RosteringItem>>>;
  organization?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type RosteringItem = {
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Rti = {
  enabled?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type SalesForceSchool = {
  country: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  region: Scalars['String']['output'];
  town: Scalars['String']['output'];
};

export type SalesOrder = {
  billingAddress?: Maybe<Scalars['Json']['output']>;
  billingAddress_text?: Maybe<Scalars['String']['output']>;
  billingCustomerCif?: Maybe<Scalars['String']['output']>;
  billingCustomerId?: Maybe<Scalars['String']['output']>;
  custbody1?: Maybe<Scalars['String']['output']>;
  custbody2?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['Json']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  item?: Maybe<Item>;
  items?: Maybe<Scalars['Json']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  pedidoNum?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Scalars['Json']['output']>;
  shippingAddress_text?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Json']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalResults?: Maybe<Scalars['Int']['output']>;
  tranDate?: Maybe<Scalars['String']['output']>;
  tranId?: Maybe<Scalars['String']['output']>;
};

export type SessionAdminDashboard = {
  completed: Scalars['Int']['output'];
  completed_adventure?: Maybe<Scalars['Int']['output']>;
  completed_laboratory?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type SessionReview = {
  rating: Scalars['Int']['output'];
};

export type SessionReviewBody = {
  academicYearId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
};

export type Settings = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Scalars['Json']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  schoolPositions?: Maybe<Scalars['Json']['output']>;
};

export type ShippingAddress = {
  administrativeRegion: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  formattedAddress: Scalars['String']['input'];
  province?: InputMaybe<Scalars['String']['input']>;
  reference: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ShortMultiplicationSignType = {
  primary?: Maybe<Scalars['String']['output']>;
  secondary?: Maybe<Scalars['String']['output']>;
};

export type SingleInvitationBody = {
  classrooms?: InputMaybe<Array<InputMaybe<ClassroomInfo>>>;
  forceRoleChange?: InputMaybe<Scalars['Boolean']['input']>;
  invitedEmail: InvitedEmailBody;
  inviterId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  operativeRegion?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  students?: InputMaybe<Array<InputMaybe<InvitationStudentBody>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Sitemap = {
  active?: Maybe<Scalars['Boolean']['output']>;
  courseOrder?: Maybe<Scalars['Int']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  numberOfNullPageId?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type SitemapLinkItem = {
  demoPageId?: Maybe<Scalars['ID']['output']>;
  fullPageId?: Maybe<Scalars['ID']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  isOnlyForClassroom?: Maybe<Scalars['Boolean']['output']>;
  linkItemType?: Maybe<Scalars['String']['output']>;
  program?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SitemapTreeItem = {
  icon?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<SitemapLinkItem>>>;
  route?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SitemapsLevel = {
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type SkipSessionActivityBody = {
  codename: Scalars['String']['input'];
  isSkippedByFenix?: InputMaybe<Scalars['Boolean']['input']>;
  sessionType: Scalars['String']['input'];
  userUuid: Scalars['ID']['input'];
};

export type Stage = {
  description?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type StagedMaterials = {
  id: Scalars['ID']['output'];
  materials: Array<Material>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum Stages {
  Ey = 'EY',
  Pe = 'PE',
  Se = 'SE',
}

export type Standard = {
  activities: Array<Activity>;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type StatementMetadata = {
  answersMetrics: AnswersMetrics;
  position: Scalars['Int']['output'];
};

export type Stretch = {
  assessmentPacing?: Maybe<AssessmentPacing>;
  modulePacings: Array<ModulePacing>;
  number: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Student = {
  alias?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  classroomAvatar?: Maybe<Scalars['String']['output']>;
  classroomDeletedAt?: Maybe<Scalars['String']['output']>;
  classroomIcon?: Maybe<Scalars['String']['output']>;
  classroomId?: Maybe<Scalars['String']['output']>;
  classroomLevel?: Maybe<Scalars['String']['output']>;
  classroomName?: Maybe<Scalars['String']['output']>;
  courseId?: Maybe<Scalars['String']['output']>;
  courseName?: Maybe<Scalars['String']['output']>;
  courseOrder?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  educationalPlanId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  homeAccess?: Maybe<Scalars['Boolean']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  individualAccess?: Maybe<Scalars['Boolean']['output']>;
  isInvitation?: Maybe<Scalars['Boolean']['output']>;
  isSecondaryStudent?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  parents?: Maybe<Array<Maybe<Member>>>;
  pinCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  rti?: Maybe<Rti>;
  studentInfo?: Maybe<StudentInfo>;
  token?: Maybe<Scalars['String']['output']>;
  trainingAccess?: Maybe<Scalars['Boolean']['output']>;
  trainingTimeLimit?: Maybe<Scalars['Int']['output']>;
  userInfo?: Maybe<UserInfo>;
  weeklyTimeLimit?: Maybe<Scalars['Int']['output']>;
};

export type StudentAdminDashboard = {
  connectedPercentage: Scalars['Float']['output'];
  timeSpent: Scalars['Float']['output'];
};

export type StudentByParent = {
  classroom_id: Scalars['ID']['output'];
  classroom_name: Scalars['String']['output'];
  course_id: Scalars['ID']['output'];
  digital_practice_access: DigitalPracticeAccess;
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  student_id: Scalars['ID']['output'];
  teachers?: Maybe<Array<Scalars['String']['output']>>;
};

export type StudentCard = {
  status: CardType;
  uuid: Scalars['ID']['output'];
};

export type StudentFailed = {
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type StudentInfo = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['Int']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type StudentIntervention = {
  currentArithmeticFluency: Scalars['Float']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  initialArithmeticFluency: Scalars['Float']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lastWeekInIntervention?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['ID']['output'];
  weeks: Array<StudentInterventionWeeks>;
};

export type StudentInterventionWeeks = {
  availableInterventionSessions: Scalars['Int']['output'];
  completedInterventionSessions: Scalars['Int']['output'];
};

export type StudentReports = {
  cards: Array<StudentCard>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type StudentValidation = {
  data: StudentValidationData;
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type StudentValidationData = {
  classroom: Scalars['String']['output'];
  course_order: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  sis_id?: Maybe<Scalars['String']['output']>;
};

export type StudentsCsv = {
  base64?: Maybe<Scalars['String']['output']>;
};

export type Subscriptions = {
  subscribedList?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  unsubscribedList?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type Tag = {
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Teacher = {
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  email: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isInvitation?: Maybe<Scalars['Boolean']['output']>;
  isSchoolInvitation?: Maybe<Scalars['Boolean']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  reportsEnabled?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TeacherInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TeachersAdminDashboard = {
  firstName?: Maybe<Scalars['String']['output']>;
  lastConnection?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Test = {
  mandatory: Scalars['Boolean']['output'];
  password: Scalars['String']['output'];
  passwordRequired: Scalars['Boolean']['output'];
  testUuid: Scalars['ID']['output'];
  userTestUuid: Scalars['ID']['output'];
};

export type TestActivity = {
  completed?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pack?: Maybe<Scalars['Int']['output']>;
  scene?: Maybe<Scalars['String']['output']>;
  statements?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TestData = {
  activities: Array<Maybe<TestActivity>>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  navigation: Scalars['Boolean']['output'];
  shuffle: Scalars['Boolean']['output'];
  time?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  userTestUuid: Scalars['String']['output'];
};

export type TestsAndResults = {
  classroomTestRoundId: Scalars['ID']['output'];
  endDate: Scalars['String']['output'];
  participation?: Maybe<TestsAndResultsParticipation>;
  password: Scalars['String']['output'];
  report?: Maybe<TestsAndResultsReport>;
  roundName: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  status: Scalars['String']['output'];
  test?: Maybe<TestsAndResultsTest>;
  testId: Scalars['ID']['output'];
};

export type TestsAndResultsParticipation = {
  completed?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TestsAndResultsReport = {
  deliveryDate?: Maybe<Scalars['String']['output']>;
};

export type TestsAndResultsTest = {
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type TextTranslation = {
  requiresTranslation?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ThirdPartyConnection = {
  _links?: Maybe<ThirdPartyConnectionLinks>;
  connected_at?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ThirdPartyConnectionLinks = {
  disconnect?: Maybe<Scalars['String']['output']>;
};

export type ThirdPartyConnections = {
  google?: Maybe<ThirdPartyConnection>;
  microsoft?: Maybe<ThirdPartyConnection>;
};

export type Thumbnail = {
  href: Scalars['String']['output'];
  original_file_name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type TokenData = {
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  id_token: Scalars['String']['output'];
  refresh_token: Scalars['String']['output'];
  token_type: Scalars['String']['output'];
};

export type TrackVideoProgressBody = {
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  progress: Scalars['Float']['input'];
};

export type TrainingZone = {
  appletsAttempted?: Maybe<AppletsAttempted>;
  appletsImproved?: Maybe<Scalars['Int']['output']>;
  averageScore?: Maybe<Scalars['Float']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
};

export type TranslatedOrderedResource = {
  order?: Maybe<Scalars['Int']['output']>;
  resource_view?: Maybe<ResourceView>;
};

export type TranslatedResourceList = {
  description?: Maybe<Scalars['String']['output']>;
  list_type?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  page_id?: Maybe<Scalars['ID']['output']>;
  total_resources?: Maybe<Scalars['Int']['output']>;
  translated_ordered_resources?: Maybe<Array<Maybe<TranslatedOrderedResource>>>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type UnlinkParentBody = {
  isInvitation: Scalars['Boolean']['input'];
  parentId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type UnlinkTeacherBody = {
  classroomId: Scalars['ID']['input'];
  teacherId: Scalars['ID']['input'];
};

export type UpdateDigitalPracticeAccessBody = {
  classroomId: Scalars['String']['input'];
  digitalPracticeAccess: Scalars['String']['input'];
  studentsPropagation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateReportsBody = {
  classroomId?: InputMaybe<Scalars['String']['input']>;
  report?: InputMaybe<Scalars['Boolean']['input']>;
  teacherId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRtiBody = {
  enabled: Scalars['Boolean']['input'];
  studentId: Scalars['ID']['input'];
};

export type UpdateStudentAvatarBody = {
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStudentClassroomBody = {
  classroomId: Scalars['ID']['input'];
  pinCode?: InputMaybe<Scalars['String']['input']>;
  studentId: Scalars['ID']['input'];
};

export type UpdateTimeZoneBody = {
  organizationId: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

export type UpdateUserBody = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  forceRoleChange?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  roles: Array<InputMaybe<Scalars['String']['input']>>;
};

export type UpdateUserRole = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type UpdateUserRoleBody = {
  id: Scalars['ID']['input'];
  roles: Array<InputMaybe<Scalars['String']['input']>>;
};

export type UserInfo = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  isInvitation?: Maybe<Scalars['Boolean']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum UserType {
  Invitation = 'invitation',
  Parents = 'parents',
  Student = 'student',
  Teacher = 'teacher',
}

export type VerticalOperationsParametersType = {
  verticalDivisionAlgorithm?: Maybe<Scalars['String']['output']>;
  verticalMultiplicationPosition?: Maybe<Scalars['String']['output']>;
  verticalSubtractionPosition?: Maybe<Scalars['String']['output']>;
  verticalSumPosition?: Maybe<Scalars['String']['output']>;
};

export type WeekScore = {
  score?: Maybe<Scalars['Float']['output']>;
  weekEndDate: Scalars['String']['output'];
  weekStartDate: Scalars['String']['output'];
};

export type WeeklyPractice = {
  active?: Maybe<Scalars['Boolean']['output']>;
  activities?: Maybe<Array<Maybe<Applet>>>;
  isVacation?: Maybe<Scalars['Boolean']['output']>;
  noMoreData?: Maybe<Scalars['Boolean']['output']>;
  unityPayload?: Maybe<Scalars['Json']['output']>;
};

export type WeeklyReport = {
  average: WeeklyReportData;
  courseOrder?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lessonsDone: Array<Scalars['ID']['output']>;
  operativeRegion?: Maybe<Scalars['String']['output']>;
  students: Array<WeeklyReportData>;
};

export type WeeklyReportData = {
  appletSelectorActivities?: Maybe<Array<Maybe<ContentApplet>>>;
  appletsCompleted?: Maybe<Scalars['Float']['output']>;
  appletsServed?: Maybe<Scalars['Float']['output']>;
  averageScore?: Maybe<Scalars['Float']['output']>;
  bubbles?: Maybe<Array<Maybe<Bubble>>>;
  contents?: Maybe<Array<Maybe<Content>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  otherContents?: Maybe<OtherContents>;
  statementsCompleted?: Maybe<Scalars['Float']['output']>;
  statementsServed?: Maybe<Scalars['Float']['output']>;
  timeSpentAll?: Maybe<Scalars['Float']['output']>;
  timeSpentHome?: Maybe<Scalars['Float']['output']>;
  timeSpentSchool?: Maybe<Scalars['Float']['output']>;
  trainingZone?: Maybe<TrainingZone>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type WeeklyReportDate = {
  date_from: Scalars['String']['output'];
  date_to: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type _Tag = {
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AssignTeacherToClassroomsMutationVariables = Exact<{
  body?: InputMaybe<AssignTeacherToClassroomsBody>;
}>;

export type AssignTeacherToClassroomsMutation = {
  assignTeacherToClassrooms?: any | null;
};

export type AssignTeachersToClassroomMutationVariables = Exact<{
  body?: InputMaybe<AssignTeachersToClassroomBody>;
}>;

export type AssignTeachersToClassroomMutation = {
  assignTeachersToClassroom?: any | null;
};

export type ChangeOrganizationMutationVariables = Exact<{
  body: ChangeOrganizationBody;
}>;

export type ChangeOrganizationMutation = { changeOrganization?: any | null };

export type ChangePasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = { changePassword?: any | null };

export type ChangeStudentOrganizationMutationVariables = Exact<{
  body: ChangeStudentOrganizationBody;
}>;

export type ChangeStudentOrganizationMutation = {
  changeStudentOrganization?: any | null;
};

export type ChangeUserPasswordMutationVariables = Exact<{
  body: ChangeUserPasswordBody;
}>;

export type ChangeUserPasswordMutation = { changeUserPassword?: any | null };

export type CheckGoogleClassroomConnectionMutationVariables = Exact<{
  body: CheckGoogleClassroomConnectionBody;
}>;

export type CheckGoogleClassroomConnectionMutation = {
  checkGoogleClassroomConnection?: any | null;
};

export type CompleteAppletMutationVariables = Exact<{
  body?: InputMaybe<CompleteAppletBody>;
}>;

export type CompleteAppletMutation = { completeApplet?: any | null };

export type CompleteParentInvitationMutationVariables = Exact<{
  body: CompleteInvitationBody;
}>;

export type CompleteParentInvitationMutation = {
  completeParentInvitation?: any | null;
};

export type CompleteUserInvitationMutationVariables = Exact<{
  body: CompleteInvitationBody;
}>;

export type CompleteUserInvitationMutation = {
  completeUserInvitation?: any | null;
};

export type CreateOrUpdateStudentMutationVariables = Exact<{
  body: CreateOrUpdateStudentBody;
  fromGoogleClassroom?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateOrUpdateStudentMutation = {
  createOrUpdateStudent?: any | null;
};

export type CreateOrderMutationVariables = Exact<{
  order?: InputMaybe<CreateOrderBody>;
}>;

export type CreateOrderMutation = { createOrder?: any | null };

export type DeleteClassroomMutationVariables = Exact<{
  classroomId: Scalars['ID']['input'];
}>;

export type DeleteClassroomMutation = { deleteClassroom?: any | null };

export type DeleteInvitationMutationVariables = Exact<{
  invitationId: Scalars['ID']['input'];
  isSchoolInvitation?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteInvitationMutation = { deleteInvitation?: any | null };

export type DeleteStudentMutationVariables = Exact<{
  studentId: Scalars['ID']['input'];
}>;

export type DeleteStudentMutation = { deleteStudent?: any | null };

export type DeleteTeacherMutationVariables = Exact<{
  teacherId: Scalars['ID']['input'];
}>;

export type DeleteTeacherMutation = { deleteTeacher?: any | null };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type DeleteUserMutation = { deleteUser?: any | null };

export type DisconnectGoogleClassroomMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DisconnectGoogleClassroomMutation = {
  disconnectGoogleClassroom?: any | null;
};

export type EditPreferencesMutationVariables = Exact<{
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type EditPreferencesMutation = { editPreferences?: any | null };

export type EditSettingsMutationVariables = Exact<{
  body: EditSettingsBody;
}>;

export type EditSettingsMutation = { editSettings?: any | null };

export type ImportGoogleClassroomsMutationVariables = Exact<{
  classroomsToImport:
    | Array<InputMaybe<ClassroomToImport>>
    | InputMaybe<ClassroomToImport>;
}>;

export type ImportGoogleClassroomsMutation = {
  importGoogleClassrooms?: Array<{
    google_id?: string | null;
    course_order?: number | null;
  } | null> | null;
};

export type InviteUsersToOrganizationMutationVariables = Exact<{
  invitations: InvitationBody;
}>;

export type InviteUsersToOrganizationMutation = {
  inviteUsers?: Array<{
    id?: string | null;
    userInfo?: {
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      lastLogin?: string | null;
      token?: string | null;
    } | null;
  } | null> | null;
};

export type InviteUsersMutationVariables = Exact<{
  invitations: InvitationBody;
}>;

export type InviteUsersMutation = {
  inviteUsers?: Array<{
    id?: string | null;
    userInfo?: {
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      lastLogin?: string | null;
      token?: string | null;
    } | null;
  } | null> | null;
};

export type MarkChallengeMutationVariables = Exact<{
  body: MarkChallengeBody;
}>;

export type MarkChallengeMutation = { markChallenge?: any | null };

export type MarkClassroomAsCheckedMutationVariables = Exact<{
  classroomId: Scalars['ID']['input'];
}>;

export type MarkClassroomAsCheckedMutation = {
  markClassroomAsChecked?: any | null;
};

export type MarkSessionMutationVariables = Exact<{
  body: MarkSessionBody;
}>;

export type MarkSessionMutation = { markSession?: any | null };

export type MarkSpaceMutationVariables = Exact<{
  body: MarkSpaceBody;
}>;

export type MarkSpaceMutation = { markSpace?: any | null };

export type PutClassroomMutationVariables = Exact<{
  classroom: ClassroomBody;
}>;

export type PutClassroomMutation = {
  putClassroom?: {
    id: string;
    name?: string | null;
    courseId?: string | null;
    courseName?: string | null;
    courseOrder?: number | null;
    icon?: number | null;
    avatar?: string | null;
    level?: string | null;
    scheduleStart?: string | null;
    scheduleEnd?: string | null;
  } | null;
};

export type RecoverStudentMutationVariables = Exact<{
  body?: InputMaybe<RecoverStudentBody>;
}>;

export type RecoverStudentMutation = { recoverStudent?: any | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;

export type RefreshTokenMutation = {
  refreshToken?: {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    token_type: string;
  } | null;
};

export type RemoveClassroomConnectionMutationVariables = Exact<{
  classroomId: Scalars['String']['input'];
}>;

export type RemoveClassroomConnectionMutation = {
  removeClassroomConnection?: any | null;
};

export type ResendInvitationMutationVariables = Exact<{
  body?: InputMaybe<ResendInvitationBody>;
}>;

export type ResendInvitationMutation = { resendInvitation?: any | null };

export type ResendParentsInvitationMutationVariables = Exact<{
  body?: InputMaybe<
    Array<InputMaybe<ResendInvitationBody>> | InputMaybe<ResendInvitationBody>
  >;
}>;

export type ResendParentsInvitationMutation = {
  resendParentsInvitation?: any | null;
};

export type ResendSchoolInvitationMutationVariables = Exact<{
  body?: InputMaybe<ResendInvitationBody>;
}>;

export type ResendSchoolInvitationMutation = {
  resendSchoolInvitation?: any | null;
};

export type ResetTestsMutationVariables = Exact<{
  body: ResetTestsBody;
}>;

export type ResetTestsMutation = { resetTests?: any | null };

export type SaveSessionReviewMutationVariables = Exact<{
  body: SessionReviewBody;
}>;

export type SaveSessionReviewMutation = { saveSessionReview?: any | null };

export type SaveSubscriptionsByEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  subscribedList: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  unsubscribedList: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type SaveSubscriptionsByEmailMutation = {
  saveSubscriptionsByEmail?: any | null;
};

export type SkipSessionActivityMutationVariables = Exact<{
  body: SkipSessionActivityBody;
}>;

export type SkipSessionActivityMutation = { skipSessionActivity?: any | null };

export type SyncroGoogleClassroomMutationVariables = Exact<{
  classroomToImport: ClassroomToImport;
}>;

export type SyncroGoogleClassroomMutation = {
  syncroGoogleClassroom?: any | null;
};

export type UnlinkParentMutationVariables = Exact<{
  body: UnlinkParentBody;
}>;

export type UnlinkParentMutation = { unlinkParent?: any | null };

export type UnlinkTeacherMutationVariables = Exact<{
  body?: InputMaybe<UnlinkTeacherBody>;
}>;

export type UnlinkTeacherMutation = { unlinkTeacher?: any | null };

export type UpdateDigitalPracticeAccessMutationVariables = Exact<{
  body: UpdateDigitalPracticeAccessBody;
}>;

export type UpdateDigitalPracticeAccessMutation = {
  updateDigitalPracticeAccess?: any | null;
};

export type UpdateReportsPreferenceMutationVariables = Exact<{
  body?: InputMaybe<UpdateReportsBody>;
}>;

export type UpdateReportsPreferenceMutation = {
  updateReportsPreference?: any | null;
};

export type UpdateRtiMutationVariables = Exact<{
  body: UpdateRtiBody;
}>;

export type UpdateRtiMutation = {
  updateRti?: {
    enabled?: boolean | null;
    startDate?: string | null;
    endDate?: string | null;
  } | null;
};

export type UpdateStudentAvatarMutationVariables = Exact<{
  body: UpdateStudentAvatarBody;
}>;

export type UpdateStudentAvatarMutation = { updateStudentAvatar?: any | null };

export type UpdateStudentClassroomMutationVariables = Exact<{
  body: UpdateStudentClassroomBody;
}>;

export type UpdateStudentClassroomMutation = {
  updateStudentClassroom?: any | null;
};

export type UpdateTimezoneMutationVariables = Exact<{
  body: UpdateTimeZoneBody;
}>;

export type UpdateTimezoneMutation = { updateTimezone?: any | null };

export type UpdateUserMutationVariables = Exact<{
  body: UpdateUserBody;
}>;

export type UpdateUserMutation = { updateUser?: any | null };

export type UpdateUserRoleMutationVariables = Exact<{
  body?: InputMaybe<UpdateUserRoleBody>;
}>;

export type UpdateUserRoleMutation = { updateUserRole?: any | null };

export type AdminDashboardQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  stage: Stages;
}>;

export type AdminDashboardQuery = {
  adminDashboard?: Array<{
    classroom: string;
    classroomId: string;
    course: { courseName: string; courseOrder: number };
    sessions: {
      completed: number;
      total: number;
      completed_laboratory?: number | null;
      completed_adventure?: number | null;
    };
    digitalPractice: {
      avgScore?: number | null;
      studentsPerStrip: Array<number>;
      acquiredContent: number;
    };
    students: { connectedPercentage: number; timeSpent: number };
    teachers: {
      lastConnection?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null> | null;
};

export type AppletsBySessionCodeQueryVariables = Exact<{
  classroomId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  courseOrder: Scalars['Int']['input'];
  sessionCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;

export type AppletsBySessionCodeQuery = {
  appletsBySessionCode?: Array<{
    sceneName?: string | null;
    pack?: number | null;
    data?: {
      name?: string | null;
      thumbnail?: string | null;
      description?: string | null;
      id?: string | null;
    } | null;
  } | null> | null;
};

export type AvailableTestsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type AvailableTestsQuery = {
  availableTests?: Array<{
    userTestUuid: string;
    testUuid: string;
    mandatory: boolean;
    passwordRequired: boolean;
    password: string;
  } | null> | null;
};

export type GetFinalCustomersOfBillingQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type GetFinalCustomersOfBillingQuery = {
  getFinalCustomersOfBilling?: Array<{
    cif?: string | null;
    email?: string | null;
    externalId?: string | null;
    id: string;
    name?: string | null;
    phone?: string | null;
    billingAddress: {
      administrativeRegion: string;
      city: string;
      country: string;
      formattedAddress: string;
      id: string;
      province?: string | null;
      street: string;
      zip?: string | null;
    };
  }> | null;
};

export type CatchUpZoneQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CatchUpZoneQuery = {
  catchUpZone?: {
    active?: boolean | null;
    unityPayload?: any | null;
    noMoreData?: boolean | null;
    activities?: Array<{
      sceneName?: string | null;
      codename?: string | null;
      layout?: string | null;
      type?: string | null;
      pack?: number | null;
      locale?: string | null;
      completed?: boolean | null;
      index?: number | null;
      unityActivity?: any | null;
      stars?: number | null;
      uuid?: string | null;
      data?: {
        name?: string | null;
        thumbnail?: string | null;
        description?: string | null;
        id?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ChallengesQueryVariables = Exact<{
  body: GetChallengesBody;
}>;

export type ChallengesQuery = { challenges?: any | null };

export type CheckStudentQueryVariables = Exact<{
  sisId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type CheckStudentQuery = {
  checkStudent?: Array<{
    type: string;
    message: string;
    data: {
      id: string;
      sis_id?: string | null;
      first_name: string;
      last_name: string;
      classroom: string;
      course_order: number;
      email?: string | null;
    };
  } | null> | null;
};

export type ClassroomTestRoundAvailableLanguagesQueryVariables = Exact<{
  testRoundId: Scalars['ID']['input'];
}>;

export type ClassroomTestRoundAvailableLanguagesQuery = {
  classroomTestRoundAvailableLanguages?: Array<string | null> | null;
};

export type ClassroomTestRoundDetailQueryVariables = Exact<{
  testRoundId: Scalars['ID']['input'];
  classroomId: Scalars['ID']['input'];
}>;

export type ClassroomTestRoundDetailQuery = {
  classroomTestRoundDetail?: {
    id?: string | null;
    title?: string | null;
    description?: string | null;
    duration?: number | null;
    status?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    numberOfStatements?: number | null;
    participation?: { completed?: number | null; total?: number | null } | null;
    statements: Array<{
      position: number;
      answersMetrics: { largestAnswerWidth: number };
    }>;
    students: Array<{
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      score?: number | null;
      status?: string | null;
      statements?: Array<{
        id?: string | null;
        answer?: string | null;
        isCorrect?: boolean | null;
        position?: number | null;
      } | null> | null;
    }>;
  } | null;
};

export type ClassroomTestRoundReportQueryVariables = Exact<{
  testRoundId: Scalars['ID']['input'];
  language: Scalars['String']['input'];
}>;

export type ClassroomTestRoundReportQuery = {
  classroomTestRoundReport?: { pdfBase64?: string | null } | null;
};

export type ClassroomQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
}>;

export type ClassroomQuery = {
  classroom?: {
    id: string;
    name?: string | null;
    courseId?: string | null;
    courseName?: string | null;
    courseOrder?: number | null;
    checked?: boolean | null;
    icon?: number | null;
    avatar?: string | null;
    level?: string | null;
    providerId?: string | null;
    provider?: string | null;
    providerChecked?: boolean | null;
    numberOfStudents?: number | null;
    numberOfTeachers?: number | null;
    teachersNames?: Array<string | null> | null;
    scheduleStart?: string | null;
    scheduleEnd?: string | null;
    digitalPracticeAccess?: string | null;
    pilotStatus?: { RTI?: boolean | null } | null;
  } | null;
};

export type ClassroomsCsvQueryVariables = Exact<{ [key: string]: never }>;

export type ClassroomsCsvQuery = {
  classroomsCSV?: { base64?: string | null } | null;
};

export type ClassroomsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  mine: Scalars['Boolean']['input'];
}>;

export type ClassroomsQuery = {
  classrooms?: Array<{
    id: string;
    name?: string | null;
    courseId?: string | null;
    courseName?: string | null;
    courseOrder?: number | null;
    checked?: boolean | null;
    icon?: number | null;
    avatar?: string | null;
    level?: string | null;
    providerId?: string | null;
    provider?: string | null;
    numberOfStudents?: number | null;
    numberOfTeachers?: number | null;
    teachersNames?: Array<string | null> | null;
    scheduleStart?: string | null;
    scheduleEnd?: string | null;
  } | null> | null;
};

export type CompletedResourcesQueryVariables = Exact<{
  classroomId: Scalars['ID']['input'];
  academicYearId: Scalars['String']['input'];
}>;

export type CompletedResourcesQuery = {
  getCompletedResourcesByClassroomId?: Array<{
    resourceId: string;
    solutionsEnabled: boolean;
  } | null> | null;
};

export type ContentListsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type ContentListsQuery = {
  contentLists?: Array<{
    translated_resource_list: {
      uuid?: string | null;
      page_id?: string | null;
      list_type?: string | null;
      name?: string | null;
      description?: string | null;
      translated_ordered_resources?: Array<{
        order?: number | null;
        resource_view?: {
          uuid?: string | null;
          tags?: Array<string | null> | null;
          resource_id?: string | null;
          name?: string | null;
          description?: string | null;
          type?: string | null;
          available_locales?: Array<string | null> | null;
          visibility?: string | null;
          done_status?: string | null;
          status?: string | null;
          enumeration?: string | null;
          icon?: string | null;
          has_activities?: boolean | null;
          has_solutions?: boolean | null;
          is_completable?: boolean | null;
          has_digital_guide?: boolean | null;
          has_player?: boolean | null;
          code?: string | null;
          _tags?: Array<{
            id?: string | null;
            name?: string | null;
          } | null> | null;
          thumbnail?: {
            uuid: string;
            href: string;
            original_file_name: string;
            type: string;
          } | null;
        } | null;
      } | null> | null;
    };
  } | null> | null;
};

export type CoreActivitiesQueryVariables = Exact<{
  bubblePlanId: Scalars['ID']['input'];
  bubblesIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type CoreActivitiesQuery = {
  coreActivities?: Array<{
    readableName?: string | null;
    coreActivities?: Array<{
      uuid?: string | null;
      sceneName: string;
      pack: number;
      data?: { name?: string | null } | null;
    } | null> | null;
  } | null> | null;
};

export type CoursesQueryVariables = Exact<{
  regionCode: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  activeLevel?: InputMaybe<Scalars['String']['input']>;
}>;

export type CoursesQuery = {
  courses: Array<{
    id: string;
    region?: string | null;
    order?: number | null;
    name?: string | null;
  }>;
};

export type EducationalPlansQueryVariables = Exact<{
  regionCode: Scalars['String']['input'];
  courseOrder?: InputMaybe<Scalars['Int']['input']>;
}>;

export type EducationalPlansQuery = {
  educationalPlans?: Array<{
    id: string;
    courseId: string;
    region?: string | null;
    code?: string | null;
    courseOrder?: number | null;
    courseName?: string | null;
    educationalPlanName?: string | null;
  } | null> | null;
};

export type FamilyPaymentsQueryVariables = Exact<{
  academicYearId: Scalars['ID']['input'];
  courseOrder?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  regionId: Scalars['String']['input'];
  studentName?: InputMaybe<Scalars['String']['input']>;
}>;

export type FamilyPaymentsQuery = {
  familyPayments: {
    page: number;
    pageSize: number;
    countByPage: number;
    countAll: number;
    items: Array<{
      student: { name?: string | null };
      order: {
        financialStatus: string;
        payoutDate: string;
        readableExternalId: string;
      };
      product: {
        name: string;
        courseName: string;
        language?: string | null;
        quantity: number;
      };
    }>;
  };
};

export type GetFinalCustomerCrmQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFinalCustomerCrmQuery = {
  getFinalCustomerCrm?: {
    id: string;
    name?: string | null;
    type?: string | null;
    regionId?: string | null;
    addressFormattedAddress?: string | null;
    addressCity?: string | null;
    addressProvince?: string | null;
    addressCountry?: string | null;
    addressPostalCode?: string | null;
    schoolPhone?: string | null;
    a80Email?: string | null;
    languageEmail?: string | null;
    language?: string | null;
  } | null;
};

export type GetFinalCustomerErpQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFinalCustomerErpQuery = {
  getFinalCustomerErp?: {
    id: string;
    name?: string | null;
    currency?: string | null;
    shippingAddress?: Array<{
      administrativeRegion: string;
      city: string;
      country: string;
      formattedAddress: string;
      id: string;
      province?: string | null;
      reference: string;
      street: string;
      zip?: string | null;
    }> | null;
  } | null;
};

export type GetShippingAddressByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  visibleWeb?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetShippingAddressByOrganizationIdQuery = {
  getFinalCustomerErpByOrganizationId?: {
    id: string;
    shippingAddress?: Array<{
      administrativeRegion: string;
      city: string;
      country: string;
      formattedAddress: string;
      id: string;
      province?: string | null;
      reference: string;
      street: string;
      zip?: string | null;
    }> | null;
  } | null;
};

export type FluencyDecksQueryVariables = Exact<{
  classroomId: Scalars['ID']['input'];
}>;

export type FluencyDecksQuery = { fluencyDecks?: Array<string> | null };

export type FluencyMultiplicationReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type FluencyMultiplicationReportQuery = {
  fluencyMultiplicationReport?: {
    uuid: string;
    titleTranslationKey?: string | null;
    operator?: string | null;
    students: Array<{
      uuid: string;
      firstName: string;
      lastName: string;
      cards: Array<{ uuid: string; status: CardType }>;
    }>;
    multiplicationTables: Array<{
      uuid: string;
      operand2: number;
      cards: Array<{
        uuid: string;
        operand1: number;
        status: CardType;
        studentsFailed: Array<{
          uuid: string;
          firstName?: string | null;
          lastName?: string | null;
        }>;
      }>;
    }>;
  } | null;
};

export type GetCurrentMaterialsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  academicYearId: Scalars['ID']['input'];
}>;

export type GetCurrentMaterialsQuery = {
  getCurrentMaterials?: Array<{
    id: string;
    name?: string | null;
    materials: Array<{
      courseId: string;
      courseName: string;
      courseOrder: number;
      imageUrl: string;
      languageId?: string | null;
      maxReturnableItems: number;
      productId: string;
      productName: string;
      productShortname: string;
      productType: string;
      productTypeOfMaterial: string;
      quantity: number;
      stageId: string;
      stageName: string;
      stageOrder: number;
    }>;
  } | null> | null;
};

export type GetGoogleClassroomsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoogleClassroomsQuery = {
  getGoogleClassrooms?: {
    classrooms?: Array<{
      google_id?: string | null;
      name?: string | null;
      imported?: boolean | null;
      course_order?: number | null;
    } | null> | null;
  } | null;
};

export type GetModulesOfCourseQueryVariables = Exact<{
  courseUuid: Scalars['ID']['input'];
}>;

export type GetModulesOfCourseQuery = {
  modulesOfCourse: Array<{
    stretch: string;
    title: string;
    standards: Array<{
      code: string;
      description: string;
      activities: Array<{
        codenamepack: string;
        title: string;
        screenshot: string;
      }>;
    }>;
  }>;
};

export type GetThirdPartyConnectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetThirdPartyConnectionsQuery = {
  getThirdPartyConnections?: {
    google?: {
      connected_at?: string | null;
      username?: string | null;
      _links?: { disconnect?: string | null } | null;
    } | null;
    microsoft?: {
      connected_at?: string | null;
      username?: string | null;
      _links?: { disconnect?: string | null } | null;
    } | null;
  } | null;
};

export type InterventionReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InterventionReportQuery = {
  interventionReport?: {
    weeks: {
      currentWeekNumber: number;
      interventionWeeks: Array<{ startDate: string; endDate: string }>;
    };
    studentsCurrentlyInIntervention: Array<{
      uuid: string;
      firstName?: string | null;
      lastName?: string | null;
      initialArithmeticFluency: number;
      currentArithmeticFluency: number;
      lastWeekInIntervention?: number | null;
      weeks: Array<{
        completedInterventionSessions: number;
        availableInterventionSessions: number;
      }>;
    }>;
    studentsNoLongerInIntervention: Array<{
      uuid: string;
      firstName?: string | null;
      lastName?: string | null;
      initialArithmeticFluency: number;
      currentArithmeticFluency: number;
      lastWeekInIntervention?: number | null;
      weeks: Array<{
        completedInterventionSessions: number;
        availableInterventionSessions: number;
      }>;
    }>;
  } | null;
};

export type LastYearReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  standardStructure?: InputMaybe<Scalars['String']['input']>;
}>;

export type LastYearReportQuery = {
  lastYearReport?: {
    fromDate: string;
    toDate: string;
    reports: Array<{
      id: string;
      operativeRegion?: string | null;
      courseOrder?: number | null;
      students: Array<{
        uuid?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        averageScore?: number | null;
        timeSpentAll?: number | null;
        appletsCompleted?: number | null;
        blocks?: Array<{
          blockId?: string | null;
          score?: number | null;
          blockTitle?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          blockTooltip?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          contents?: Array<{
            contentId?: string | null;
            score?: number | null;
            contentTitle?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
            contentTooltip?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
          } | null> | null;
        } | null> | null;
        weekScores?: Array<{
          weekStartDate: string;
          weekEndDate: string;
          score?: number | null;
        } | null> | null;
      }>;
      average: {
        averageScore?: number | null;
        timeSpentAll?: number | null;
        appletsCompleted?: number | null;
        blocks?: Array<{
          blockId?: string | null;
          score?: number | null;
          blockTitle?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          blockTooltip?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          contents?: Array<{
            contentId?: string | null;
            score?: number | null;
            contentTitle?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
            contentTooltip?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
          } | null> | null;
        } | null> | null;
        otherContents?: {
          score?: number | null;
          contents?: Array<{
            contentId?: string | null;
            score?: number | null;
            contentTitle?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
            contentTooltip?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
          } | null> | null;
        } | null;
        weekScores?: Array<{
          weekStartDate: string;
          weekEndDate: string;
          score?: number | null;
        } | null> | null;
      };
    }>;
  } | null;
};

export type LastYearReportsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LastYearReportsQuery = {
  lastYearReports?: Array<{
    academicYear?: string | null;
    isLastYear?: boolean | null;
  } | null> | null;
};

export type RevenueOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RevenueOrganizationQuery = {
  revenueOrganization?: {
    erp_id?: string | null;
    crm_id?: string | null;
  } | null;
};

export type OrderDetailsQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type OrderDetailsQuery = {
  orderDetails?: {
    shippingAddress: Array<string | null>;
    billingAddress: Array<string>;
    materials: Array<{
      course: string;
      id: string;
      language?: string | null;
      name: string;
      totalOperationQuantity: number;
      stageOrder: number;
      courseOrder: number;
      units?: string | null;
    }>;
  } | null;
};

export type CheckHasOngoingOrdersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type CheckHasOngoingOrdersQuery = {
  checkHasOngoingOrders: { hasOngoingOrders: boolean };
};

export type MaterialOrdersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  academicYearId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MaterialOrdersQuery = {
  materialOrders?: Array<{
    createdAt: string;
    id: string;
    orderStatus: string;
    orderType: string;
    organizationId: string;
    readableId?: string | null;
    returnDeadlineDate?: string | null;
    transactionType?: string | null;
    estimateNumber?: string | null;
  } | null> | null;
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrganizationQuery = {
  organization?: {
    id?: string | null;
    name?: string | null;
    location?: string | null;
    numberOfTeachers?: number | null;
    numberOfClassrooms?: number | null;
    numberOfStudents?: number | null;
    numberOfDeletedStudents?: number | null;
    numberOfImports?: number | null;
    region: string;
    operativeRegion?: string | null;
    availableLanguages?: Array<string | null> | null;
    platformEnabled?: boolean | null;
    language: string;
    isLATAM?: boolean | null;
    academicYearId: string;
    anonymized?: boolean | null;
    currency?: string | null;
    standard?: string | null;
    importedStudentsWithSisId?: number | null;
    timezone?: string | null;
    academicYear: {
      isUpdatePeriod: boolean;
      isTeacherPeriod: boolean;
      studentEndDate: string;
      studentStartDate: string;
      teacherEndDate: string;
      teacherStartDate: string;
    };
    nextAcademicYear?: {
      isUpdatePeriod: boolean;
      isTeacherPeriod: boolean;
      studentEndDate: string;
      studentStartDate: string;
      teacherEndDate: string;
      teacherStartDate: string;
    } | null;
  } | null;
};

export type OrganizationsQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type OrganizationsQuery = {
  organizations?: Array<{
    id?: string | null;
    name?: string | null;
    location?: string | null;
    numberOfTeachers?: number | null;
    numberOfClassrooms?: number | null;
    numberOfStudents?: number | null;
    numberOfDeletedStudents?: number | null;
    region: string;
    operativeRegion?: string | null;
    availableLanguages?: Array<string | null> | null;
    platformEnabled?: boolean | null;
    language: string;
    anonymized?: boolean | null;
  } | null> | null;
};

export type AllOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type AllOrganizationsQuery = {
  allOrganizations: Array<{
    id?: string | null;
    name?: string | null;
    location?: string | null;
    numberOfTeachers?: number | null;
    numberOfClassrooms?: number | null;
    numberOfStudents?: number | null;
    numberOfDeletedStudents?: number | null;
    region: string;
    operativeRegion?: string | null;
    availableLanguages?: Array<string | null> | null;
    platformEnabled?: boolean | null;
    language: string;
    anonymized?: boolean | null;
  } | null>;
};

export type OrganizationsCoursesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  academicYearId: Scalars['ID']['input'];
}>;

export type OrganizationsCoursesQuery = {
  organizationsCourses: Array<{
    id: string;
    region: string;
    order: number;
    name: string;
    tutoringChat: boolean;
    earlyIntervention: boolean;
    adminDashboard: boolean;
    assessment: boolean;
    platformEnabled: boolean;
  }>;
};

export type PageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  paginated?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PageQuery = {
  page?: {
    description?: string | null;
    name?: string | null;
    uuid?: string | null;
    ordered_translated_resource_lists?: Array<{
      order?: number | null;
      translated_resource_list?: {
        uuid?: string | null;
        page_id?: string | null;
        list_type?: string | null;
        name?: string | null;
        description?: string | null;
        total_resources?: number | null;
        translated_ordered_resources?: Array<{
          order?: number | null;
          resource_view?: {
            uuid?: string | null;
            tags?: Array<string | null> | null;
            resource_id?: string | null;
            name?: string | null;
            description?: string | null;
            type?: string | null;
            available_locales?: Array<string | null> | null;
            visibility?: string | null;
            done_status?: string | null;
            status?: string | null;
            enumeration?: string | null;
            icon?: string | null;
            has_activities?: boolean | null;
            has_solutions?: boolean | null;
            is_completable?: boolean | null;
            has_digital_guide?: boolean | null;
            has_player?: boolean | null;
            code?: string | null;
            progress?: number | null;
            duration?: number | null;
            _tags?: Array<{
              id?: string | null;
              name?: string | null;
            } | null> | null;
            thumbnail?: {
              uuid: string;
              href: string;
              original_file_name: string;
              type: string;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PaginatedListQueryVariables = Exact<{
  body: PaginatedListBody;
}>;

export type PaginatedListQuery = { paginatedList?: any | null };

export type PermissionCoursesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  academicYearId: Scalars['String']['input'];
}>;

export type PermissionCoursesQuery = {
  permissionCourses?: Array<{
    courseId: string;
    permission?: {
      tutoringChat?: boolean | null;
      earlyIntervention?: boolean | null;
      adminDashboard?: boolean | null;
      assessment?: boolean | null;
    } | null;
  } | null> | null;
};

export type PlatformApiUrlQueryVariables = Exact<{ [key: string]: never }>;

export type PlatformApiUrlQuery = { platformApiUrl?: string | null };

export type ProgressReportsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  stage: Scalars['String']['input'];
  standardStructure?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProgressReportsQuery = {
  progressReports?: {
    dateTo: string;
    dateFrom: string;
    reports: Array<{
      id: string;
      operativeRegion?: string | null;
      courseOrder?: number | null;
      students: Array<{
        uuid?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        averageScore?: number | null;
        timeSpentAll?: number | null;
        appletsCompleted?: number | null;
        blocks?: Array<{
          blockId?: string | null;
          score?: number | null;
          blockTitle?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          blockTooltip?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          contents?: Array<{
            contentId?: string | null;
            score?: number | null;
            contentTitle?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
            contentTooltip?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
          } | null> | null;
        } | null> | null;
        weekScores?: Array<{
          weekStartDate: string;
          weekEndDate: string;
          score?: number | null;
        } | null> | null;
      }>;
      average: {
        averageScore?: number | null;
        timeSpentAll?: number | null;
        appletsCompleted?: number | null;
        blocks?: Array<{
          score?: number | null;
          blockId?: string | null;
          blockTitle?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          blockTooltip?: {
            text?: string | null;
            requiresTranslation?: boolean | null;
          } | null;
          contents?: Array<{
            contentId?: string | null;
            score?: number | null;
            contentTitle?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
            contentTooltip?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
          } | null> | null;
        } | null> | null;
        otherContents?: {
          score?: number | null;
          contents?: Array<{
            contentId?: string | null;
            score?: number | null;
            contentTitle?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
            contentTooltip?: {
              text?: string | null;
              requiresTranslation?: boolean | null;
            } | null;
          } | null> | null;
        } | null;
        weekScores?: Array<{
          weekStartDate: string;
          weekEndDate: string;
          score?: number | null;
        } | null> | null;
      };
    }>;
  } | null;
};

export type RegionParametersQueryVariables = Exact<{
  regionCode: Scalars['String']['input'];
  stage?: InputMaybe<Scalars['String']['input']>;
}>;

export type RegionParametersQuery = { regionParameters?: any | null };

export type ResourceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  standard?: InputMaybe<Scalars['String']['input']>;
  courseOrder?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ResourceQuery = { resource?: any | null };

export type RosteringsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type RosteringsQuery = {
  rosterings?: Array<{
    completedAt?: string | null;
    createdAt?: string | null;
    originalFilename?: string | null;
    id?: string | null;
    organization?: string | null;
    owner?: string | null;
    status?: string | null;
    items?: Array<{
      id?: string | null;
      message?: string | null;
      number?: number | null;
      status?: string | null;
    } | null> | null;
  } | null> | null;
};

export type SchoolPositionsQueryVariables = Exact<{ [key: string]: never }>;

export type SchoolPositionsQuery = { schoolPositions?: any | null };

export type SchoolStudentsQueryVariables = Exact<{
  body?: InputMaybe<PaginationBody>;
}>;

export type SchoolStudentsQuery = {
  schoolStudents?: Array<{
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    classroomName?: string | null;
    classroomId?: string | null;
    classroomDeletedAt?: string | null;
    courseName?: string | null;
    courseOrder?: number | null;
    avatar?: string | null;
    deletedAt?: string | null;
    alias?: string | null;
  } | null> | null;
};

export type SessionReviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  academicYearId: Scalars['String']['input'];
}>;

export type SessionReviewQuery = { sessionReview?: { rating: number } | null };

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = {
  settings?: {
    email?: string | null;
    firstName?: string | null;
    lastLogin?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    positions?: any | null;
    roles?: Array<string | null> | null;
    schoolPositions?: any | null;
    id?: string | null;
  } | null;
};

export type SitemapLevelsQueryVariables = Exact<{
  region: Scalars['String']['input'];
}>;

export type SitemapLevelsQuery = {
  sitemapLevels: Array<{ key: string; value: Array<string> }>;
};

export type SitemapTreeQueryVariables = Exact<{
  region: Scalars['String']['input'];
  courseOrder: Scalars['Int']['input'];
  level: Scalars['String']['input'];
  viewAs?: InputMaybe<Scalars['String']['input']>;
}>;

export type SitemapTreeQuery = {
  sitemapTree?: Array<{
    icon?: number | null;
    value?: string | null;
    route?: string | null;
    items?: Array<{
      fullPageId?: string | null;
      demoPageId?: string | null;
      icon?: number | null;
      isOnlyForClassroom?: boolean | null;
      linkItemType?: string | null;
      program?: string | null;
      route?: string | null;
      status?: string | null;
      type?: string | null;
      url?: string | null;
      urlName?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
};

export type SitemapsQueryVariables = Exact<{
  region: Scalars['String']['input'];
}>;

export type SitemapsQuery = {
  sitemaps: Array<{
    active?: boolean | null;
    courseOrder?: number | null;
    numberOfNullPageId?: number | null;
    region?: string | null;
    level?: string | null;
  }>;
};

export type SolverApiUrlQueryVariables = Exact<{ [key: string]: never }>;

export type SolverApiUrlQuery = { solverApiUrl?: string | null };

export type SpacesQueryVariables = Exact<{
  body: GetSpacesBody;
}>;

export type SpacesQuery = { spaces?: any | null };

export type StarsFeedbackTrainingZoneQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  activityId: Scalars['ID']['input'];
}>;

export type StarsFeedbackTrainingZoneQuery = {
  starsFeedbackTrainingZone?: string | null;
};

export type StartWeeklyPracticeMutationVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type StartWeeklyPracticeMutation = {
  startWeeklyPractice?: {
    active?: boolean | null;
    unityPayload?: any | null;
    activities?: Array<{
      sceneName?: string | null;
      codename?: string | null;
      layout?: string | null;
      type?: string | null;
      pack?: number | null;
      locale?: string | null;
      completed?: boolean | null;
      index?: number | null;
      stars?: number | null;
      uuid?: string | null;
      unityActivity?: any | null;
      data?: {
        name?: string | null;
        thumbnail?: string | null;
        description?: string | null;
        id?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type StretchesOfCourseQueryVariables = Exact<{
  courseUuid: Scalars['ID']['input'];
}>;

export type StretchesOfCourseQuery = {
  stretchesOfCourse: Array<{
    title: string;
    number: number;
    modulePacings: Array<{
      title: string;
      lessons?: Array<{
        title: string;
        type: string;
        duration: number;
        image: string;
      }> | null;
    }>;
    assessmentPacing?: {
      title: string;
      lessons?: Array<{
        title: string;
        type: string;
        duration: number;
        image: string;
      }> | null;
    } | null;
  }>;
};

export type StudentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type StudentQuery = {
  student?: {
    classroomName?: string | null;
    courseName?: string | null;
    region?: string | null;
    icon?: number | null;
    courseOrder?: number | null;
    classroomId?: string | null;
    classroomIcon?: string | null;
    classroomAvatar?: string | null;
    courseId?: string | null;
    classroomLevel?: string | null;
  } | null;
};

export type StudentsByParentQueryVariables = Exact<{ [key: string]: never }>;

export type StudentsByParentQuery = {
  studentsByParent: Array<{
    classroom_id: string;
    classroom_name: string;
    course_id: string;
    digital_practice_access: DigitalPracticeAccess;
    first_name: string;
    last_name: string;
    student_id: string;
    teachers?: Array<string> | null;
  } | null>;
};

export type StudentsCsvQueryVariables = Exact<{ [key: string]: never }>;

export type StudentsCsvQuery = {
  studentsCSV?: { base64?: string | null } | null;
};

export type StudentsQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  classroomId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type StudentsQuery = {
  students?: Array<{
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    educationalPlanId?: string | null;
    avatar?: string | null;
    icon?: number | null;
    language?: string | null;
    homeAccess?: boolean | null;
    individualAccess?: boolean | null;
    weeklyTimeLimit?: number | null;
    classroomId?: string | null;
    courseOrder?: number | null;
    isSecondaryStudent?: boolean | null;
    pinCode?: string | null;
    alias?: string | null;
    isInvitation?: boolean | null;
    lastLogin?: string | null;
    token?: string | null;
    trainingAccess?: boolean | null;
    trainingTimeLimit?: number | null;
    parents?: Array<{
      id?: string | null;
      userInfo?: {
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        lastLogin?: string | null;
        token?: string | null;
      } | null;
    } | null> | null;
    rti?: {
      enabled?: boolean | null;
      startDate?: string | null;
      endDate?: string | null;
    } | null;
  } | null> | null;
};

export type SubscriptionsByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SubscriptionsByEmailQuery = {
  subscriptionsByEmail?: {
    subscribedList?: Array<number | null> | null;
    unsubscribedList?: Array<number | null> | null;
  } | null;
};

export type SuggestTeachersQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type SuggestTeachersQuery = {
  suggestTeachers?: Array<{
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    type?: string | null;
    roles?: Array<string> | null;
    lastLogin?: string | null;
    reportsEnabled?: boolean | null;
    isInvitation?: boolean | null;
    isSchoolInvitation?: boolean | null;
    token?: string | null;
    expiresAt?: string | null;
  } | null> | null;
};

export type TeachersQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  classroomId?: InputMaybe<Scalars['ID']['input']>;
  isPlatformEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type TeachersQuery = {
  teachers?: Array<{
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    type?: string | null;
    roles?: Array<string> | null;
    lastLogin?: string | null;
    reportsEnabled?: boolean | null;
    isInvitation?: boolean | null;
    isSchoolInvitation?: boolean | null;
    token?: string | null;
    expiresAt?: string | null;
    classrooms?: Array<{
      courseId?: string | null;
      courseName?: string | null;
      courseOrder?: number | null;
      icon?: number | null;
      id: string;
      name?: string | null;
    } | null> | null;
  } | null> | null;
};

export type TestQueryVariables = Exact<{
  testUuid: Scalars['ID']['input'];
  userTestUuid: Scalars['ID']['input'];
}>;

export type TestQuery = {
  test?: {
    description: string;
    navigation: boolean;
    shuffle: boolean;
    time?: number | null;
    title: string;
    id: string;
    userTestUuid: string;
    activities: Array<{
      description?: string | null;
      pack?: number | null;
      scene?: string | null;
      id: string;
      statements?: Array<any | null> | null;
      completed?: boolean | null;
      type?: string | null;
    } | null>;
  } | null;
};

export type TestsAndResultsQueryVariables = Exact<{
  classroomId: Scalars['ID']['input'];
  academicYearId: Scalars['ID']['input'];
}>;

export type TestsAndResultsQuery = {
  testsAndResults: Array<{
    testId: string;
    classroomTestRoundId: string;
    roundName: string;
    status: string;
    password: string;
    startDate: string;
    endDate: string;
    test?: { type?: string | null } | null;
    report?: { deliveryDate?: string | null } | null;
  }>;
};

export type TrainingZoneQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type TrainingZoneQuery = {
  trainingZone?: {
    active?: boolean | null;
    unityPayload?: any | null;
    noMoreData?: boolean | null;
    activities?: Array<{
      sceneName?: string | null;
      codename?: string | null;
      layout?: string | null;
      type?: string | null;
      pack?: number | null;
      locale?: string | null;
      completed?: boolean | null;
      index?: number | null;
      unityActivity?: any | null;
      stars?: number | null;
      uuid?: string | null;
      data?: {
        name?: string | null;
        thumbnail?: string | null;
        description?: string | null;
        id?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type WeeklyPracticeQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type WeeklyPracticeQuery = {
  weeklyPractice?: {
    active?: boolean | null;
    unityPayload?: any | null;
    isVacation?: boolean | null;
    activities?: Array<{
      sceneName?: string | null;
      codename?: string | null;
      layout?: string | null;
      type?: string | null;
      pack?: number | null;
      locale?: string | null;
      completed?: boolean | null;
      index?: number | null;
      stars?: number | null;
      uuid?: string | null;
      unityActivity?: any | null;
      data?: {
        name?: string | null;
        thumbnail?: string | null;
        description?: string | null;
        id?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type WeeklyReportsDatesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  stage: Scalars['String']['input'];
}>;

export type WeeklyReportsDatesQuery = {
  weeklyReportsDates?: Array<{
    date_from: string;
    date_to: string;
    title?: string | null;
  } | null> | null;
};

export type WeeklyReportsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  practicePeriod: Scalars['String']['input'];
  stage: Scalars['String']['input'];
}>;

export type WeeklyReportsQuery = {
  weeklyReports?: Array<{
    lessonsDone: Array<string>;
    id: string;
    operativeRegion?: string | null;
    courseOrder?: number | null;
    students: Array<{
      uuid?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      statementsCompleted?: number | null;
      statementsServed?: number | null;
      timeSpentAll?: number | null;
      timeSpentSchool?: number | null;
      timeSpentHome?: number | null;
      appletsCompleted?: number | null;
      appletsServed?: number | null;
      averageScore?: number | null;
      contents?: Array<{
        contentId?: string | null;
        contentTextCode?: string | null;
        skillTextCode?: string | null;
        score?: number | null;
        applets?: Array<{
          uuid?: string | null;
          sceneName: string;
          pack: number;
          score?: number | null;
          data?: {
            name?: string | null;
            thumbnail?: string | null;
            description?: string | null;
            id?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
      appletSelectorActivities?: Array<{
        uuid?: string | null;
        sceneName: string;
        pack: number;
        maxScoreCurrentWeek?: number | null;
        passed?: boolean | null;
        data?: {
          name?: string | null;
          thumbnail?: string | null;
          description?: string | null;
          id?: string | null;
        } | null;
      } | null> | null;
      bubbles?: Array<{
        bubbleTextCode: string;
        hasDifficulty?: boolean | null;
        readableId?: string | null;
      } | null> | null;
      trainingZone?: {
        timeSpent?: number | null;
        averageScore?: number | null;
        appletsImproved?: number | null;
        appletsAttempted?: {
          count?: number | null;
          applets?: Array<{
            sceneName: string;
            pack: number;
            score?: number | null;
            data?: {
              name?: string | null;
              thumbnail?: string | null;
              description?: string | null;
              id?: string | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    }>;
    average: {
      statementsCompleted?: number | null;
      statementsServed?: number | null;
      timeSpentAll?: number | null;
      timeSpentSchool?: number | null;
      timeSpentHome?: number | null;
      appletsCompleted?: number | null;
      appletsServed?: number | null;
      averageScore?: number | null;
      contents?: Array<{
        contentId?: string | null;
        contentTextCode?: string | null;
        skillTextCode?: string | null;
        score?: number | null;
        applets?: Array<{
          uuid?: string | null;
          sceneName: string;
          pack: number;
          score?: number | null;
          data?: {
            name?: string | null;
            thumbnail?: string | null;
            description?: string | null;
            id?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
      trainingZone?: {
        timeSpent?: number | null;
        averageScore?: number | null;
      } | null;
      otherContents?: {
        score?: number | null;
        contents?: Array<{
          contentId?: string | null;
          contentTextCode?: string | null;
          skillTextCode?: string | null;
          score?: number | null;
          applets?: Array<{
            uuid?: string | null;
            sceneName: string;
            pack: number;
            score?: number | null;
            data?: {
              name?: string | null;
              thumbnail?: string | null;
              description?: string | null;
              id?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    };
  } | null> | null;
};

export const AssignTeacherToClassroomsDocument = `
    mutation AssignTeacherToClassrooms($body: AssignTeacherToClassroomsBody) {
  assignTeacherToClassrooms(body: $body)
}
    `;

export const useAssignTeacherToClassroomsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AssignTeacherToClassroomsMutation,
    TError,
    AssignTeacherToClassroomsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AssignTeacherToClassroomsMutation,
    TError,
    AssignTeacherToClassroomsMutationVariables,
    TContext
  >({
    mutationKey: ['AssignTeacherToClassrooms'],
    mutationFn: useFetch<
      AssignTeacherToClassroomsMutation,
      AssignTeacherToClassroomsMutationVariables
    >(AssignTeacherToClassroomsDocument),
    ...options,
  });
};

export const AssignTeachersToClassroomDocument = `
    mutation AssignTeachersToClassroom($body: AssignTeachersToClassroomBody) {
  assignTeachersToClassroom(body: $body)
}
    `;

export const useAssignTeachersToClassroomMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AssignTeachersToClassroomMutation,
    TError,
    AssignTeachersToClassroomMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AssignTeachersToClassroomMutation,
    TError,
    AssignTeachersToClassroomMutationVariables,
    TContext
  >({
    mutationKey: ['AssignTeachersToClassroom'],
    mutationFn: useFetch<
      AssignTeachersToClassroomMutation,
      AssignTeachersToClassroomMutationVariables
    >(AssignTeachersToClassroomDocument),
    ...options,
  });
};

export const ChangeOrganizationDocument = `
    mutation ChangeOrganization($body: ChangeOrganizationBody!) {
  changeOrganization(body: $body)
}
    `;

export const useChangeOrganizationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ChangeOrganizationMutation,
    TError,
    ChangeOrganizationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeOrganizationMutation,
    TError,
    ChangeOrganizationMutationVariables,
    TContext
  >({
    mutationKey: ['ChangeOrganization'],
    mutationFn: useFetch<
      ChangeOrganizationMutation,
      ChangeOrganizationMutationVariables
    >(ChangeOrganizationDocument),
    ...options,
  });
};

export const ChangePasswordDocument = `
    mutation ChangePassword($id: String!, $password: String!) {
  changePassword(id: $id, password: $password)
}
    `;

export const useChangePasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ChangePasswordMutation,
    TError,
    ChangePasswordMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangePasswordMutation,
    TError,
    ChangePasswordMutationVariables,
    TContext
  >({
    mutationKey: ['ChangePassword'],
    mutationFn: useFetch<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >(ChangePasswordDocument),
    ...options,
  });
};

export const ChangeStudentOrganizationDocument = `
    mutation ChangeStudentOrganization($body: ChangeStudentOrganizationBody!) {
  changeStudentOrganization(body: $body)
}
    `;

export const useChangeStudentOrganizationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ChangeStudentOrganizationMutation,
    TError,
    ChangeStudentOrganizationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeStudentOrganizationMutation,
    TError,
    ChangeStudentOrganizationMutationVariables,
    TContext
  >({
    mutationKey: ['ChangeStudentOrganization'],
    mutationFn: useFetch<
      ChangeStudentOrganizationMutation,
      ChangeStudentOrganizationMutationVariables
    >(ChangeStudentOrganizationDocument),
    ...options,
  });
};

export const ChangeUserPasswordDocument = `
    mutation ChangeUserPassword($body: ChangeUserPasswordBody!) {
  changeUserPassword(body: $body)
}
    `;

export const useChangeUserPasswordMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ChangeUserPasswordMutation,
    TError,
    ChangeUserPasswordMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeUserPasswordMutation,
    TError,
    ChangeUserPasswordMutationVariables,
    TContext
  >({
    mutationKey: ['ChangeUserPassword'],
    mutationFn: useFetch<
      ChangeUserPasswordMutation,
      ChangeUserPasswordMutationVariables
    >(ChangeUserPasswordDocument),
    ...options,
  });
};

export const CheckGoogleClassroomConnectionDocument = `
    mutation checkGoogleClassroomConnection($body: CheckGoogleClassroomConnectionBody!) {
  checkGoogleClassroomConnection(body: $body)
}
    `;

export const useCheckGoogleClassroomConnectionMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CheckGoogleClassroomConnectionMutation,
    TError,
    CheckGoogleClassroomConnectionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CheckGoogleClassroomConnectionMutation,
    TError,
    CheckGoogleClassroomConnectionMutationVariables,
    TContext
  >({
    mutationKey: ['checkGoogleClassroomConnection'],
    mutationFn: useFetch<
      CheckGoogleClassroomConnectionMutation,
      CheckGoogleClassroomConnectionMutationVariables
    >(CheckGoogleClassroomConnectionDocument),
    ...options,
  });
};

export const CompleteAppletDocument = `
    mutation CompleteApplet($body: CompleteAppletBody) {
  completeApplet(body: $body)
}
    `;

export const useCompleteAppletMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CompleteAppletMutation,
    TError,
    CompleteAppletMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CompleteAppletMutation,
    TError,
    CompleteAppletMutationVariables,
    TContext
  >({
    mutationKey: ['CompleteApplet'],
    mutationFn: useFetch<
      CompleteAppletMutation,
      CompleteAppletMutationVariables
    >(CompleteAppletDocument),
    ...options,
  });
};

export const CompleteParentInvitationDocument = `
    mutation CompleteParentInvitation($body: CompleteInvitationBody!) {
  completeParentInvitation(body: $body)
}
    `;

export const useCompleteParentInvitationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CompleteParentInvitationMutation,
    TError,
    CompleteParentInvitationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CompleteParentInvitationMutation,
    TError,
    CompleteParentInvitationMutationVariables,
    TContext
  >({
    mutationKey: ['CompleteParentInvitation'],
    mutationFn: useFetch<
      CompleteParentInvitationMutation,
      CompleteParentInvitationMutationVariables
    >(CompleteParentInvitationDocument),
    ...options,
  });
};

export const CompleteUserInvitationDocument = `
    mutation CompleteUserInvitation($body: CompleteInvitationBody!) {
  completeUserInvitation(body: $body)
}
    `;

export const useCompleteUserInvitationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CompleteUserInvitationMutation,
    TError,
    CompleteUserInvitationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CompleteUserInvitationMutation,
    TError,
    CompleteUserInvitationMutationVariables,
    TContext
  >({
    mutationKey: ['CompleteUserInvitation'],
    mutationFn: useFetch<
      CompleteUserInvitationMutation,
      CompleteUserInvitationMutationVariables
    >(CompleteUserInvitationDocument),
    ...options,
  });
};

export const CreateOrUpdateStudentDocument = `
    mutation CreateOrUpdateStudent($body: CreateOrUpdateStudentBody!, $fromGoogleClassroom: Boolean) {
  createOrUpdateStudent(body: $body, fromGoogleClassroom: $fromGoogleClassroom)
}
    `;

export const useCreateOrUpdateStudentMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateOrUpdateStudentMutation,
    TError,
    CreateOrUpdateStudentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateOrUpdateStudentMutation,
    TError,
    CreateOrUpdateStudentMutationVariables,
    TContext
  >({
    mutationKey: ['CreateOrUpdateStudent'],
    mutationFn: useFetch<
      CreateOrUpdateStudentMutation,
      CreateOrUpdateStudentMutationVariables
    >(CreateOrUpdateStudentDocument),
    ...options,
  });
};

export const CreateOrderDocument = `
    mutation CreateOrder($order: CreateOrderBody) {
  createOrder(order: $order)
}
    `;

export const useCreateOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateOrderMutation,
    TError,
    CreateOrderMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateOrderMutation,
    TError,
    CreateOrderMutationVariables,
    TContext
  >({
    mutationKey: ['CreateOrder'],
    mutationFn: useFetch<CreateOrderMutation, CreateOrderMutationVariables>(
      CreateOrderDocument
    ),
    ...options,
  });
};

export const DeleteClassroomDocument = `
    mutation DeleteClassroom($classroomId: ID!) {
  deleteClassroom(classroomId: $classroomId)
}
    `;

export const useDeleteClassroomMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteClassroomMutation,
    TError,
    DeleteClassroomMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteClassroomMutation,
    TError,
    DeleteClassroomMutationVariables,
    TContext
  >({
    mutationKey: ['DeleteClassroom'],
    mutationFn: useFetch<
      DeleteClassroomMutation,
      DeleteClassroomMutationVariables
    >(DeleteClassroomDocument),
    ...options,
  });
};

export const DeleteInvitationDocument = `
    mutation DeleteInvitation($invitationId: ID!, $isSchoolInvitation: Boolean) {
  deleteInvitation(
    invitationId: $invitationId
    isSchoolInvitation: $isSchoolInvitation
  )
}
    `;

export const useDeleteInvitationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteInvitationMutation,
    TError,
    DeleteInvitationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteInvitationMutation,
    TError,
    DeleteInvitationMutationVariables,
    TContext
  >({
    mutationKey: ['DeleteInvitation'],
    mutationFn: useFetch<
      DeleteInvitationMutation,
      DeleteInvitationMutationVariables
    >(DeleteInvitationDocument),
    ...options,
  });
};

export const DeleteStudentDocument = `
    mutation DeleteStudent($studentId: ID!) {
  deleteStudent(studentId: $studentId)
}
    `;

export const useDeleteStudentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteStudentMutation,
    TError,
    DeleteStudentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteStudentMutation,
    TError,
    DeleteStudentMutationVariables,
    TContext
  >({
    mutationKey: ['DeleteStudent'],
    mutationFn: useFetch<DeleteStudentMutation, DeleteStudentMutationVariables>(
      DeleteStudentDocument
    ),
    ...options,
  });
};

export const DeleteTeacherDocument = `
    mutation DeleteTeacher($teacherId: ID!) {
  deleteTeacher(teacherId: $teacherId)
}
    `;

export const useDeleteTeacherMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteTeacherMutation,
    TError,
    DeleteTeacherMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteTeacherMutation,
    TError,
    DeleteTeacherMutationVariables,
    TContext
  >({
    mutationKey: ['DeleteTeacher'],
    mutationFn: useFetch<DeleteTeacherMutation, DeleteTeacherMutationVariables>(
      DeleteTeacherDocument
    ),
    ...options,
  });
};

export const DeleteUserDocument = `
    mutation DeleteUser($userId: ID!) {
  deleteUser(userId: $userId)
}
    `;

export const useDeleteUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteUserMutation,
    TError,
    DeleteUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteUserMutation,
    TError,
    DeleteUserMutationVariables,
    TContext
  >({
    mutationKey: ['DeleteUser'],
    mutationFn: useFetch<DeleteUserMutation, DeleteUserMutationVariables>(
      DeleteUserDocument
    ),
    ...options,
  });
};

export const DisconnectGoogleClassroomDocument = `
    mutation DisconnectGoogleClassroom {
  disconnectGoogleClassroom
}
    `;

export const useDisconnectGoogleClassroomMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DisconnectGoogleClassroomMutation,
    TError,
    DisconnectGoogleClassroomMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DisconnectGoogleClassroomMutation,
    TError,
    DisconnectGoogleClassroomMutationVariables,
    TContext
  >({
    mutationKey: ['DisconnectGoogleClassroom'],
    mutationFn: useFetch<
      DisconnectGoogleClassroomMutation,
      DisconnectGoogleClassroomMutationVariables
    >(DisconnectGoogleClassroomDocument),
    ...options,
  });
};

export const EditPreferencesDocument = `
    mutation EditPreferences($key: String!, $value: String!) {
  editPreferences(key: $key, value: $value)
}
    `;

export const useEditPreferencesMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    EditPreferencesMutation,
    TError,
    EditPreferencesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    EditPreferencesMutation,
    TError,
    EditPreferencesMutationVariables,
    TContext
  >({
    mutationKey: ['EditPreferences'],
    mutationFn: useFetch<
      EditPreferencesMutation,
      EditPreferencesMutationVariables
    >(EditPreferencesDocument),
    ...options,
  });
};

export const EditSettingsDocument = `
    mutation EditSettings($body: EditSettingsBody!) {
  editSettings(body: $body)
}
    `;

export const useEditSettingsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    EditSettingsMutation,
    TError,
    EditSettingsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    EditSettingsMutation,
    TError,
    EditSettingsMutationVariables,
    TContext
  >({
    mutationKey: ['EditSettings'],
    mutationFn: useFetch<EditSettingsMutation, EditSettingsMutationVariables>(
      EditSettingsDocument
    ),
    ...options,
  });
};

export const ImportGoogleClassroomsDocument = `
    mutation importGoogleClassrooms($classroomsToImport: [ClassroomToImport]!) {
  importGoogleClassrooms(classroomsToImport: $classroomsToImport) {
    google_id
    course_order
  }
}
    `;

export const useImportGoogleClassroomsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ImportGoogleClassroomsMutation,
    TError,
    ImportGoogleClassroomsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ImportGoogleClassroomsMutation,
    TError,
    ImportGoogleClassroomsMutationVariables,
    TContext
  >({
    mutationKey: ['importGoogleClassrooms'],
    mutationFn: useFetch<
      ImportGoogleClassroomsMutation,
      ImportGoogleClassroomsMutationVariables
    >(ImportGoogleClassroomsDocument),
    ...options,
  });
};

export const InviteUsersToOrganizationDocument = `
    mutation InviteUsersToOrganization($invitations: InvitationBody!) {
  inviteUsers(invitations: $invitations) {
    id
    userInfo {
      firstName
      lastName
      email
      lastLogin
      token
    }
  }
}
    `;

export const useInviteUsersToOrganizationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    InviteUsersToOrganizationMutation,
    TError,
    InviteUsersToOrganizationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    InviteUsersToOrganizationMutation,
    TError,
    InviteUsersToOrganizationMutationVariables,
    TContext
  >({
    mutationKey: ['InviteUsersToOrganization'],
    mutationFn: useFetch<
      InviteUsersToOrganizationMutation,
      InviteUsersToOrganizationMutationVariables
    >(InviteUsersToOrganizationDocument),
    ...options,
  });
};

export const InviteUsersDocument = `
    mutation InviteUsers($invitations: InvitationBody!) {
  inviteUsers(invitations: $invitations) {
    id
    userInfo {
      firstName
      lastName
      email
      lastLogin
      token
    }
  }
}
    `;

export const useInviteUsersMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    InviteUsersMutation,
    TError,
    InviteUsersMutationVariables,
    TContext
  >
) => {
  return useMutation<
    InviteUsersMutation,
    TError,
    InviteUsersMutationVariables,
    TContext
  >({
    mutationKey: ['InviteUsers'],
    mutationFn: useFetch<InviteUsersMutation, InviteUsersMutationVariables>(
      InviteUsersDocument
    ),
    ...options,
  });
};

export const MarkChallengeDocument = `
    mutation MarkChallenge($body: MarkChallengeBody!) {
  markChallenge(body: $body)
}
    `;

export const useMarkChallengeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    MarkChallengeMutation,
    TError,
    MarkChallengeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    MarkChallengeMutation,
    TError,
    MarkChallengeMutationVariables,
    TContext
  >({
    mutationKey: ['MarkChallenge'],
    mutationFn: useFetch<MarkChallengeMutation, MarkChallengeMutationVariables>(
      MarkChallengeDocument
    ),
    ...options,
  });
};

export const MarkClassroomAsCheckedDocument = `
    mutation MarkClassroomAsChecked($classroomId: ID!) {
  markClassroomAsChecked(classroomId: $classroomId)
}
    `;

export const useMarkClassroomAsCheckedMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    MarkClassroomAsCheckedMutation,
    TError,
    MarkClassroomAsCheckedMutationVariables,
    TContext
  >
) => {
  return useMutation<
    MarkClassroomAsCheckedMutation,
    TError,
    MarkClassroomAsCheckedMutationVariables,
    TContext
  >({
    mutationKey: ['MarkClassroomAsChecked'],
    mutationFn: useFetch<
      MarkClassroomAsCheckedMutation,
      MarkClassroomAsCheckedMutationVariables
    >(MarkClassroomAsCheckedDocument),
    ...options,
  });
};

export const MarkSessionDocument = `
    mutation MarkSession($body: MarkSessionBody!) {
  markSession(body: $body)
}
    `;

export const useMarkSessionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    MarkSessionMutation,
    TError,
    MarkSessionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    MarkSessionMutation,
    TError,
    MarkSessionMutationVariables,
    TContext
  >({
    mutationKey: ['MarkSession'],
    mutationFn: useFetch<MarkSessionMutation, MarkSessionMutationVariables>(
      MarkSessionDocument
    ),
    ...options,
  });
};

export const MarkSpaceDocument = `
    mutation MarkSpace($body: MarkSpaceBody!) {
  markSpace(body: $body)
}
    `;

export const useMarkSpaceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    MarkSpaceMutation,
    TError,
    MarkSpaceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    MarkSpaceMutation,
    TError,
    MarkSpaceMutationVariables,
    TContext
  >({
    mutationKey: ['MarkSpace'],
    mutationFn: useFetch<MarkSpaceMutation, MarkSpaceMutationVariables>(
      MarkSpaceDocument
    ),
    ...options,
  });
};

export const PutClassroomDocument = `
    mutation PutClassroom($classroom: ClassroomBody!) {
  putClassroom(classroom: $classroom) {
    id
    name
    courseId
    courseName
    courseOrder
    icon
    avatar
    level
    scheduleStart
    scheduleEnd
  }
}
    `;

export const usePutClassroomMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PutClassroomMutation,
    TError,
    PutClassroomMutationVariables,
    TContext
  >
) => {
  return useMutation<
    PutClassroomMutation,
    TError,
    PutClassroomMutationVariables,
    TContext
  >({
    mutationKey: ['PutClassroom'],
    mutationFn: useFetch<PutClassroomMutation, PutClassroomMutationVariables>(
      PutClassroomDocument
    ),
    ...options,
  });
};

export const RecoverStudentDocument = `
    mutation RecoverStudent($body: RecoverStudentBody) {
  recoverStudent(body: $body)
}
    `;

export const useRecoverStudentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RecoverStudentMutation,
    TError,
    RecoverStudentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RecoverStudentMutation,
    TError,
    RecoverStudentMutationVariables,
    TContext
  >({
    mutationKey: ['RecoverStudent'],
    mutationFn: useFetch<
      RecoverStudentMutation,
      RecoverStudentMutationVariables
    >(RecoverStudentDocument),
    ...options,
  });
};

export const RefreshTokenDocument = `
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    access_token
    expires_in
    id_token
    refresh_token
    token_type
  }
}
    `;

export const useRefreshTokenMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RefreshTokenMutation,
    TError,
    RefreshTokenMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RefreshTokenMutation,
    TError,
    RefreshTokenMutationVariables,
    TContext
  >({
    mutationKey: ['RefreshToken'],
    mutationFn: useFetch<RefreshTokenMutation, RefreshTokenMutationVariables>(
      RefreshTokenDocument
    ),
    ...options,
  });
};

export const RemoveClassroomConnectionDocument = `
    mutation removeClassroomConnection($classroomId: String!) {
  removeClassroomConnection(classroomId: $classroomId)
}
    `;

export const useRemoveClassroomConnectionMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RemoveClassroomConnectionMutation,
    TError,
    RemoveClassroomConnectionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveClassroomConnectionMutation,
    TError,
    RemoveClassroomConnectionMutationVariables,
    TContext
  >({
    mutationKey: ['removeClassroomConnection'],
    mutationFn: useFetch<
      RemoveClassroomConnectionMutation,
      RemoveClassroomConnectionMutationVariables
    >(RemoveClassroomConnectionDocument),
    ...options,
  });
};

export const ResendInvitationDocument = `
    mutation ResendInvitation($body: ResendInvitationBody) {
  resendInvitation(body: $body)
}
    `;

export const useResendInvitationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ResendInvitationMutation,
    TError,
    ResendInvitationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResendInvitationMutation,
    TError,
    ResendInvitationMutationVariables,
    TContext
  >({
    mutationKey: ['ResendInvitation'],
    mutationFn: useFetch<
      ResendInvitationMutation,
      ResendInvitationMutationVariables
    >(ResendInvitationDocument),
    ...options,
  });
};

export const ResendParentsInvitationDocument = `
    mutation ResendParentsInvitation($body: [ResendInvitationBody]) {
  resendParentsInvitation(body: $body)
}
    `;

export const useResendParentsInvitationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ResendParentsInvitationMutation,
    TError,
    ResendParentsInvitationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResendParentsInvitationMutation,
    TError,
    ResendParentsInvitationMutationVariables,
    TContext
  >({
    mutationKey: ['ResendParentsInvitation'],
    mutationFn: useFetch<
      ResendParentsInvitationMutation,
      ResendParentsInvitationMutationVariables
    >(ResendParentsInvitationDocument),
    ...options,
  });
};

export const ResendSchoolInvitationDocument = `
    mutation ResendSchoolInvitation($body: ResendInvitationBody) {
  resendSchoolInvitation(body: $body)
}
    `;

export const useResendSchoolInvitationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ResendSchoolInvitationMutation,
    TError,
    ResendSchoolInvitationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResendSchoolInvitationMutation,
    TError,
    ResendSchoolInvitationMutationVariables,
    TContext
  >({
    mutationKey: ['ResendSchoolInvitation'],
    mutationFn: useFetch<
      ResendSchoolInvitationMutation,
      ResendSchoolInvitationMutationVariables
    >(ResendSchoolInvitationDocument),
    ...options,
  });
};

export const ResetTestsDocument = `
    mutation ResetTests($body: ResetTestsBody!) {
  resetTests(body: $body)
}
    `;

export const useResetTestsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResetTestsMutation,
    TError,
    ResetTestsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResetTestsMutation,
    TError,
    ResetTestsMutationVariables,
    TContext
  >({
    mutationKey: ['ResetTests'],
    mutationFn: useFetch<ResetTestsMutation, ResetTestsMutationVariables>(
      ResetTestsDocument
    ),
    ...options,
  });
};

export const SaveSessionReviewDocument = `
    mutation SaveSessionReview($body: SessionReviewBody!) {
  saveSessionReview(body: $body)
}
    `;

export const useSaveSessionReviewMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SaveSessionReviewMutation,
    TError,
    SaveSessionReviewMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SaveSessionReviewMutation,
    TError,
    SaveSessionReviewMutationVariables,
    TContext
  >({
    mutationKey: ['SaveSessionReview'],
    mutationFn: useFetch<
      SaveSessionReviewMutation,
      SaveSessionReviewMutationVariables
    >(SaveSessionReviewDocument),
    ...options,
  });
};

export const SaveSubscriptionsByEmailDocument = `
    mutation saveSubscriptionsByEmail($email: String!, $subscribedList: [Int!]!, $unsubscribedList: [Int!]!) {
  saveSubscriptionsByEmail(
    email: $email
    subscribedList: $subscribedList
    unsubscribedList: $unsubscribedList
  )
}
    `;

export const useSaveSubscriptionsByEmailMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SaveSubscriptionsByEmailMutation,
    TError,
    SaveSubscriptionsByEmailMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SaveSubscriptionsByEmailMutation,
    TError,
    SaveSubscriptionsByEmailMutationVariables,
    TContext
  >({
    mutationKey: ['saveSubscriptionsByEmail'],
    mutationFn: useFetch<
      SaveSubscriptionsByEmailMutation,
      SaveSubscriptionsByEmailMutationVariables
    >(SaveSubscriptionsByEmailDocument),
    ...options,
  });
};

export const SkipSessionActivityDocument = `
    mutation SkipSessionActivity($body: SkipSessionActivityBody!) {
  skipSessionActivity(body: $body)
}
    `;

export const useSkipSessionActivityMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SkipSessionActivityMutation,
    TError,
    SkipSessionActivityMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SkipSessionActivityMutation,
    TError,
    SkipSessionActivityMutationVariables,
    TContext
  >({
    mutationKey: ['SkipSessionActivity'],
    mutationFn: useFetch<
      SkipSessionActivityMutation,
      SkipSessionActivityMutationVariables
    >(SkipSessionActivityDocument),
    ...options,
  });
};

export const SyncroGoogleClassroomDocument = `
    mutation syncroGoogleClassroom($classroomToImport: ClassroomToImport!) {
  syncroGoogleClassroom(classroomToImport: $classroomToImport)
}
    `;

export const useSyncroGoogleClassroomMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SyncroGoogleClassroomMutation,
    TError,
    SyncroGoogleClassroomMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SyncroGoogleClassroomMutation,
    TError,
    SyncroGoogleClassroomMutationVariables,
    TContext
  >({
    mutationKey: ['syncroGoogleClassroom'],
    mutationFn: useFetch<
      SyncroGoogleClassroomMutation,
      SyncroGoogleClassroomMutationVariables
    >(SyncroGoogleClassroomDocument),
    ...options,
  });
};

export const UnlinkParentDocument = `
    mutation UnlinkParent($body: UnlinkParentBody!) {
  unlinkParent(body: $body)
}
    `;

export const useUnlinkParentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnlinkParentMutation,
    TError,
    UnlinkParentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UnlinkParentMutation,
    TError,
    UnlinkParentMutationVariables,
    TContext
  >({
    mutationKey: ['UnlinkParent'],
    mutationFn: useFetch<UnlinkParentMutation, UnlinkParentMutationVariables>(
      UnlinkParentDocument
    ),
    ...options,
  });
};

export const UnlinkTeacherDocument = `
    mutation UnlinkTeacher($body: UnlinkTeacherBody) {
  unlinkTeacher(body: $body)
}
    `;

export const useUnlinkTeacherMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnlinkTeacherMutation,
    TError,
    UnlinkTeacherMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UnlinkTeacherMutation,
    TError,
    UnlinkTeacherMutationVariables,
    TContext
  >({
    mutationKey: ['UnlinkTeacher'],
    mutationFn: useFetch<UnlinkTeacherMutation, UnlinkTeacherMutationVariables>(
      UnlinkTeacherDocument
    ),
    ...options,
  });
};

export const UpdateDigitalPracticeAccessDocument = `
    mutation UpdateDigitalPracticeAccess($body: UpdateDigitalPracticeAccessBody!) {
  updateDigitalPracticeAccess(body: $body)
}
    `;

export const useUpdateDigitalPracticeAccessMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateDigitalPracticeAccessMutation,
    TError,
    UpdateDigitalPracticeAccessMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateDigitalPracticeAccessMutation,
    TError,
    UpdateDigitalPracticeAccessMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateDigitalPracticeAccess'],
    mutationFn: useFetch<
      UpdateDigitalPracticeAccessMutation,
      UpdateDigitalPracticeAccessMutationVariables
    >(UpdateDigitalPracticeAccessDocument),
    ...options,
  });
};

export const UpdateReportsPreferenceDocument = `
    mutation UpdateReportsPreference($body: UpdateReportsBody) {
  updateReportsPreference(body: $body)
}
    `;

export const useUpdateReportsPreferenceMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateReportsPreferenceMutation,
    TError,
    UpdateReportsPreferenceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateReportsPreferenceMutation,
    TError,
    UpdateReportsPreferenceMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateReportsPreference'],
    mutationFn: useFetch<
      UpdateReportsPreferenceMutation,
      UpdateReportsPreferenceMutationVariables
    >(UpdateReportsPreferenceDocument),
    ...options,
  });
};

export const UpdateRtiDocument = `
    mutation UpdateRti($body: UpdateRtiBody!) {
  updateRti(body: $body) {
    enabled
    startDate
    endDate
  }
}
    `;

export const useUpdateRtiMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateRtiMutation,
    TError,
    UpdateRtiMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateRtiMutation,
    TError,
    UpdateRtiMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateRti'],
    mutationFn: useFetch<UpdateRtiMutation, UpdateRtiMutationVariables>(
      UpdateRtiDocument
    ),
    ...options,
  });
};

export const UpdateStudentAvatarDocument = `
    mutation UpdateStudentAvatar($body: UpdateStudentAvatarBody!) {
  updateStudentAvatar(body: $body)
}
    `;

export const useUpdateStudentAvatarMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateStudentAvatarMutation,
    TError,
    UpdateStudentAvatarMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateStudentAvatarMutation,
    TError,
    UpdateStudentAvatarMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateStudentAvatar'],
    mutationFn: useFetch<
      UpdateStudentAvatarMutation,
      UpdateStudentAvatarMutationVariables
    >(UpdateStudentAvatarDocument),
    ...options,
  });
};

export const UpdateStudentClassroomDocument = `
    mutation UpdateStudentClassroom($body: UpdateStudentClassroomBody!) {
  updateStudentClassroom(body: $body)
}
    `;

export const useUpdateStudentClassroomMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateStudentClassroomMutation,
    TError,
    UpdateStudentClassroomMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateStudentClassroomMutation,
    TError,
    UpdateStudentClassroomMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateStudentClassroom'],
    mutationFn: useFetch<
      UpdateStudentClassroomMutation,
      UpdateStudentClassroomMutationVariables
    >(UpdateStudentClassroomDocument),
    ...options,
  });
};

export const UpdateTimezoneDocument = `
    mutation UpdateTimezone($body: UpdateTimeZoneBody!) {
  updateTimezone(body: $body)
}
    `;

export const useUpdateTimezoneMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateTimezoneMutation,
    TError,
    UpdateTimezoneMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateTimezoneMutation,
    TError,
    UpdateTimezoneMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateTimezone'],
    mutationFn: useFetch<
      UpdateTimezoneMutation,
      UpdateTimezoneMutationVariables
    >(UpdateTimezoneDocument),
    ...options,
  });
};

export const UpdateUserDocument = `
    mutation UpdateUser($body: UpdateUserBody!) {
  updateUser(body: $body)
}
    `;

export const useUpdateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateUser'],
    mutationFn: useFetch<UpdateUserMutation, UpdateUserMutationVariables>(
      UpdateUserDocument
    ),
    ...options,
  });
};

export const UpdateUserRoleDocument = `
    mutation UpdateUserRole($body: UpdateUserRoleBody) {
  updateUserRole(body: $body)
}
    `;

export const useUpdateUserRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserRoleMutation,
    TError,
    UpdateUserRoleMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateUserRoleMutation,
    TError,
    UpdateUserRoleMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateUserRole'],
    mutationFn: useFetch<
      UpdateUserRoleMutation,
      UpdateUserRoleMutationVariables
    >(UpdateUserRoleDocument),
    ...options,
  });
};

export const AdminDashboardDocument = `
    query AdminDashboard($organizationId: ID!, $stage: Stages!) {
  adminDashboard(organizationId: $organizationId, stage: $stage) {
    course {
      courseName
      courseOrder
    }
    classroom
    classroomId
    sessions {
      completed
      total
      completed_laboratory
      completed_adventure
    }
    digitalPractice {
      avgScore
      studentsPerStrip
      acquiredContent
    }
    students {
      connectedPercentage
      timeSpent
    }
    teachers {
      lastConnection
      firstName
      lastName
    }
  }
}
    `;

export const useAdminDashboardQuery = <
  TData = AdminDashboardQuery,
  TError = unknown
>(
  variables: AdminDashboardQueryVariables,
  options?: Omit<
    UseQueryOptions<AdminDashboardQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<AdminDashboardQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<AdminDashboardQuery, TError, TData>({
    queryKey: ['AdminDashboard', variables],
    queryFn: useFetch<AdminDashboardQuery, AdminDashboardQueryVariables>(
      AdminDashboardDocument
    ).bind(null, variables),
    ...options,
  });
};

useAdminDashboardQuery.getKey = (variables: AdminDashboardQueryVariables) => [
  'AdminDashboard',
  variables,
];

export const AppletsBySessionCodeDocument = `
    query appletsBySessionCode($classroomId: ID!, $courseId: ID!, $courseOrder: Int!, $sessionCode: String!, $locale: String!) {
  appletsBySessionCode(
    classroomId: $classroomId
    courseId: $courseId
    courseOrder: $courseOrder
    sessionCode: $sessionCode
    locale: $locale
  ) {
    sceneName
    pack
    data {
      name
      thumbnail
      description
      id
    }
  }
}
    `;

export const useAppletsBySessionCodeQuery = <
  TData = AppletsBySessionCodeQuery,
  TError = unknown
>(
  variables: AppletsBySessionCodeQueryVariables,
  options?: Omit<
    UseQueryOptions<AppletsBySessionCodeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      AppletsBySessionCodeQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<AppletsBySessionCodeQuery, TError, TData>({
    queryKey: ['appletsBySessionCode', variables],
    queryFn: useFetch<
      AppletsBySessionCodeQuery,
      AppletsBySessionCodeQueryVariables
    >(AppletsBySessionCodeDocument).bind(null, variables),
    ...options,
  });
};

useAppletsBySessionCodeQuery.getKey = (
  variables: AppletsBySessionCodeQueryVariables
) => ['appletsBySessionCode', variables];

export const AvailableTestsDocument = `
    query AvailableTests($userId: ID!) {
  availableTests(userId: $userId) {
    userTestUuid
    testUuid
    mandatory
    passwordRequired
    password
  }
}
    `;

export const useAvailableTestsQuery = <
  TData = AvailableTestsQuery,
  TError = unknown
>(
  variables: AvailableTestsQueryVariables,
  options?: Omit<
    UseQueryOptions<AvailableTestsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<AvailableTestsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<AvailableTestsQuery, TError, TData>({
    queryKey: ['AvailableTests', variables],
    queryFn: useFetch<AvailableTestsQuery, AvailableTestsQueryVariables>(
      AvailableTestsDocument
    ).bind(null, variables),
    ...options,
  });
};

useAvailableTestsQuery.getKey = (variables: AvailableTestsQueryVariables) => [
  'AvailableTests',
  variables,
];

export const GetFinalCustomersOfBillingDocument = `
    query GetFinalCustomersOfBilling($organizationId: ID!) {
  getFinalCustomersOfBilling(organizationId: $organizationId) {
    billingAddress {
      administrativeRegion
      city
      country
      formattedAddress
      id
      province
      street
      zip
    }
    cif
    email
    externalId
    id
    name
    phone
  }
}
    `;

export const useGetFinalCustomersOfBillingQuery = <
  TData = GetFinalCustomersOfBillingQuery,
  TError = unknown
>(
  variables: GetFinalCustomersOfBillingQueryVariables,
  options?: Omit<
    UseQueryOptions<GetFinalCustomersOfBillingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetFinalCustomersOfBillingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetFinalCustomersOfBillingQuery, TError, TData>({
    queryKey: ['GetFinalCustomersOfBilling', variables],
    queryFn: useFetch<
      GetFinalCustomersOfBillingQuery,
      GetFinalCustomersOfBillingQueryVariables
    >(GetFinalCustomersOfBillingDocument).bind(null, variables),
    ...options,
  });
};

useGetFinalCustomersOfBillingQuery.getKey = (
  variables: GetFinalCustomersOfBillingQueryVariables
) => ['GetFinalCustomersOfBilling', variables];

export const CatchUpZoneDocument = `
    query CatchUpZone($locale: String, $offset: Int, $limit: Int) {
  catchUpZone(locale: $locale, offset: $offset, limit: $limit) {
    active
    activities {
      sceneName
      codename
      layout
      type
      pack
      locale
      completed
      index
      unityActivity
      stars
      uuid
      data {
        name
        thumbnail
        description
        id
      }
    }
    unityPayload
    noMoreData
  }
}
    `;

export const useCatchUpZoneQuery = <TData = CatchUpZoneQuery, TError = unknown>(
  variables?: CatchUpZoneQueryVariables,
  options?: Omit<
    UseQueryOptions<CatchUpZoneQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CatchUpZoneQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CatchUpZoneQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['CatchUpZone'] : ['CatchUpZone', variables],
    queryFn: useFetch<CatchUpZoneQuery, CatchUpZoneQueryVariables>(
      CatchUpZoneDocument
    ).bind(null, variables),
    ...options,
  });
};

useCatchUpZoneQuery.getKey = (variables?: CatchUpZoneQueryVariables) =>
  variables === undefined ? ['CatchUpZone'] : ['CatchUpZone', variables];

export const ChallengesDocument = `
    query Challenges($body: GetChallengesBody!) {
  challenges(body: $body)
}
    `;

export const useChallengesQuery = <TData = ChallengesQuery, TError = unknown>(
  variables: ChallengesQueryVariables,
  options?: Omit<
    UseQueryOptions<ChallengesQuery, TError, TData>,
    'queryKey'
  > & { queryKey?: UseQueryOptions<ChallengesQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<ChallengesQuery, TError, TData>({
    queryKey: ['Challenges', variables],
    queryFn: useFetch<ChallengesQuery, ChallengesQueryVariables>(
      ChallengesDocument
    ).bind(null, variables),
    ...options,
  });
};

useChallengesQuery.getKey = (variables: ChallengesQueryVariables) => [
  'Challenges',
  variables,
];

export const CheckStudentDocument = `
    query CheckStudent($sisId: String, $email: String) {
  checkStudent(sisId: $sisId, email: $email) {
    type
    message
    data {
      id
      sis_id
      first_name
      last_name
      classroom
      course_order
      email
    }
  }
}
    `;

export const useCheckStudentQuery = <
  TData = CheckStudentQuery,
  TError = unknown
>(
  variables?: CheckStudentQueryVariables,
  options?: Omit<
    UseQueryOptions<CheckStudentQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CheckStudentQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CheckStudentQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['CheckStudent'] : ['CheckStudent', variables],
    queryFn: useFetch<CheckStudentQuery, CheckStudentQueryVariables>(
      CheckStudentDocument
    ).bind(null, variables),
    ...options,
  });
};

useCheckStudentQuery.getKey = (variables?: CheckStudentQueryVariables) =>
  variables === undefined ? ['CheckStudent'] : ['CheckStudent', variables];

export const ClassroomTestRoundAvailableLanguagesDocument = `
    query ClassroomTestRoundAvailableLanguages($testRoundId: ID!) {
  classroomTestRoundAvailableLanguages(testRoundId: $testRoundId)
}
    `;

export const useClassroomTestRoundAvailableLanguagesQuery = <
  TData = ClassroomTestRoundAvailableLanguagesQuery,
  TError = unknown
>(
  variables: ClassroomTestRoundAvailableLanguagesQueryVariables,
  options?: Omit<
    UseQueryOptions<ClassroomTestRoundAvailableLanguagesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      ClassroomTestRoundAvailableLanguagesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<ClassroomTestRoundAvailableLanguagesQuery, TError, TData>({
    queryKey: ['ClassroomTestRoundAvailableLanguages', variables],
    queryFn: useFetch<
      ClassroomTestRoundAvailableLanguagesQuery,
      ClassroomTestRoundAvailableLanguagesQueryVariables
    >(ClassroomTestRoundAvailableLanguagesDocument).bind(null, variables),
    ...options,
  });
};

useClassroomTestRoundAvailableLanguagesQuery.getKey = (
  variables: ClassroomTestRoundAvailableLanguagesQueryVariables
) => ['ClassroomTestRoundAvailableLanguages', variables];

export const ClassroomTestRoundDetailDocument = `
    query ClassroomTestRoundDetail($testRoundId: ID!, $classroomId: ID!) {
  classroomTestRoundDetail(testRoundId: $testRoundId, classroomId: $classroomId) {
    id
    title
    description
    duration
    status
    startDate
    endDate
    participation {
      completed
      total
    }
    numberOfStatements
    statements {
      position
      answersMetrics {
        largestAnswerWidth
      }
    }
    students {
      id
      firstName
      lastName
      score
      status
      statements {
        id
        answer
        isCorrect
        position
      }
    }
  }
}
    `;

export const useClassroomTestRoundDetailQuery = <
  TData = ClassroomTestRoundDetailQuery,
  TError = unknown
>(
  variables: ClassroomTestRoundDetailQueryVariables,
  options?: Omit<
    UseQueryOptions<ClassroomTestRoundDetailQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      ClassroomTestRoundDetailQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<ClassroomTestRoundDetailQuery, TError, TData>({
    queryKey: ['ClassroomTestRoundDetail', variables],
    queryFn: useFetch<
      ClassroomTestRoundDetailQuery,
      ClassroomTestRoundDetailQueryVariables
    >(ClassroomTestRoundDetailDocument).bind(null, variables),
    ...options,
  });
};

useClassroomTestRoundDetailQuery.getKey = (
  variables: ClassroomTestRoundDetailQueryVariables
) => ['ClassroomTestRoundDetail', variables];

export const ClassroomTestRoundReportDocument = `
    query ClassroomTestRoundReport($testRoundId: ID!, $language: String!) {
  classroomTestRoundReport(testRoundId: $testRoundId, language: $language) {
    pdfBase64
  }
}
    `;

export const useClassroomTestRoundReportQuery = <
  TData = ClassroomTestRoundReportQuery,
  TError = unknown
>(
  variables: ClassroomTestRoundReportQueryVariables,
  options?: Omit<
    UseQueryOptions<ClassroomTestRoundReportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      ClassroomTestRoundReportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<ClassroomTestRoundReportQuery, TError, TData>({
    queryKey: ['ClassroomTestRoundReport', variables],
    queryFn: useFetch<
      ClassroomTestRoundReportQuery,
      ClassroomTestRoundReportQueryVariables
    >(ClassroomTestRoundReportDocument).bind(null, variables),
    ...options,
  });
};

useClassroomTestRoundReportQuery.getKey = (
  variables: ClassroomTestRoundReportQueryVariables
) => ['ClassroomTestRoundReport', variables];

export const ClassroomDocument = `
    query Classroom($id: ID!, $orgId: ID!) {
  classroom(id: $id, orgId: $orgId) {
    id
    name
    courseId
    courseName
    courseOrder
    checked
    icon
    avatar
    level
    providerId
    provider
    providerChecked
    numberOfStudents
    numberOfTeachers
    teachersNames
    scheduleStart
    scheduleEnd
    digitalPracticeAccess
    pilotStatus {
      RTI
    }
    level
    scheduleStart
    scheduleEnd
  }
}
    `;

export const useClassroomQuery = <TData = ClassroomQuery, TError = unknown>(
  variables: ClassroomQueryVariables,
  options?: Omit<UseQueryOptions<ClassroomQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<ClassroomQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ClassroomQuery, TError, TData>({
    queryKey: ['Classroom', variables],
    queryFn: useFetch<ClassroomQuery, ClassroomQueryVariables>(
      ClassroomDocument
    ).bind(null, variables),
    ...options,
  });
};

useClassroomQuery.getKey = (variables: ClassroomQueryVariables) => [
  'Classroom',
  variables,
];

export const ClassroomsCsvDocument = `
    query ClassroomsCSV {
  classroomsCSV {
    base64
  }
}
    `;

export const useClassroomsCsvQuery = <
  TData = ClassroomsCsvQuery,
  TError = unknown
>(
  variables?: ClassroomsCsvQueryVariables,
  options?: Omit<
    UseQueryOptions<ClassroomsCsvQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ClassroomsCsvQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ClassroomsCsvQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['ClassroomsCSV']
        : ['ClassroomsCSV', variables],
    queryFn: useFetch<ClassroomsCsvQuery, ClassroomsCsvQueryVariables>(
      ClassroomsCsvDocument
    ).bind(null, variables),
    ...options,
  });
};

useClassroomsCsvQuery.getKey = (variables?: ClassroomsCsvQueryVariables) =>
  variables === undefined ? ['ClassroomsCSV'] : ['ClassroomsCSV', variables];

export const ClassroomsDocument = `
    query Classrooms($orgId: ID!, $mine: Boolean!) {
  classrooms(orgId: $orgId, mine: $mine) {
    id
    name
    courseId
    courseName
    courseOrder
    checked
    icon
    avatar
    level
    providerId
    provider
    numberOfStudents
    numberOfTeachers
    teachersNames
    scheduleStart
    scheduleEnd
  }
}
    `;

export const useClassroomsQuery = <TData = ClassroomsQuery, TError = unknown>(
  variables: ClassroomsQueryVariables,
  options?: Omit<
    UseQueryOptions<ClassroomsQuery, TError, TData>,
    'queryKey'
  > & { queryKey?: UseQueryOptions<ClassroomsQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<ClassroomsQuery, TError, TData>({
    queryKey: ['Classrooms', variables],
    queryFn: useFetch<ClassroomsQuery, ClassroomsQueryVariables>(
      ClassroomsDocument
    ).bind(null, variables),
    ...options,
  });
};

useClassroomsQuery.getKey = (variables: ClassroomsQueryVariables) => [
  'Classrooms',
  variables,
];

export const CompletedResourcesDocument = `
    query CompletedResources($classroomId: ID!, $academicYearId: String!) {
  getCompletedResourcesByClassroomId(
    classroomId: $classroomId
    academicYearId: $academicYearId
  ) {
    resourceId
    solutionsEnabled
  }
}
    `;

export const useCompletedResourcesQuery = <
  TData = CompletedResourcesQuery,
  TError = unknown
>(
  variables: CompletedResourcesQueryVariables,
  options?: Omit<
    UseQueryOptions<CompletedResourcesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CompletedResourcesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<CompletedResourcesQuery, TError, TData>({
    queryKey: ['CompletedResources', variables],
    queryFn: useFetch<
      CompletedResourcesQuery,
      CompletedResourcesQueryVariables
    >(CompletedResourcesDocument).bind(null, variables),
    ...options,
  });
};

useCompletedResourcesQuery.getKey = (
  variables: CompletedResourcesQueryVariables
) => ['CompletedResources', variables];

export const ContentListsDocument = `
    query ContentLists($ids: [ID!]!) {
  contentLists(ids: $ids) {
    translated_resource_list {
      uuid
      page_id
      list_type
      name
      description
      translated_ordered_resources {
        order
        resource_view {
          uuid
          tags
          resource_id
          _tags {
            id
            name
          }
          name
          description
          thumbnail {
            uuid
            href
            original_file_name
            type
          }
          type
          available_locales
          visibility
          done_status
          status
          enumeration
          icon
          has_activities
          has_solutions
          is_completable
          has_digital_guide
          has_player
          code
        }
      }
    }
  }
}
    `;

export const useContentListsQuery = <
  TData = ContentListsQuery,
  TError = unknown
>(
  variables: ContentListsQueryVariables,
  options?: Omit<
    UseQueryOptions<ContentListsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ContentListsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ContentListsQuery, TError, TData>({
    queryKey: ['ContentLists', variables],
    queryFn: useFetch<ContentListsQuery, ContentListsQueryVariables>(
      ContentListsDocument
    ).bind(null, variables),
    ...options,
  });
};

useContentListsQuery.getKey = (variables: ContentListsQueryVariables) => [
  'ContentLists',
  variables,
];

export const CoreActivitiesDocument = `
    query CoreActivities($bubblePlanId: ID!, $bubblesIds: [String]!) {
  coreActivities(bubblePlanId: $bubblePlanId, bubblesIds: $bubblesIds) {
    readableName
    coreActivities {
      uuid
      sceneName
      pack
      data {
        name
      }
    }
  }
}
    `;

export const useCoreActivitiesQuery = <
  TData = CoreActivitiesQuery,
  TError = unknown
>(
  variables: CoreActivitiesQueryVariables,
  options?: Omit<
    UseQueryOptions<CoreActivitiesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CoreActivitiesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CoreActivitiesQuery, TError, TData>({
    queryKey: ['CoreActivities', variables],
    queryFn: useFetch<CoreActivitiesQuery, CoreActivitiesQueryVariables>(
      CoreActivitiesDocument
    ).bind(null, variables),
    ...options,
  });
};

useCoreActivitiesQuery.getKey = (variables: CoreActivitiesQueryVariables) => [
  'CoreActivities',
  variables,
];

export const CoursesDocument = `
    query Courses($regionCode: String!, $organizationId: ID, $activeLevel: String) {
  courses(
    regionCode: $regionCode
    organizationId: $organizationId
    activeLevel: $activeLevel
  ) {
    id
    region
    order
    name
  }
}
    `;

export const useCoursesQuery = <TData = CoursesQuery, TError = unknown>(
  variables: CoursesQueryVariables,
  options?: Omit<UseQueryOptions<CoursesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<CoursesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CoursesQuery, TError, TData>({
    queryKey: ['Courses', variables],
    queryFn: useFetch<CoursesQuery, CoursesQueryVariables>(
      CoursesDocument
    ).bind(null, variables),
    ...options,
  });
};

useCoursesQuery.getKey = (variables: CoursesQueryVariables) => [
  'Courses',
  variables,
];

export const EducationalPlansDocument = `
    query EducationalPlans($regionCode: String!, $courseOrder: Int) {
  educationalPlans(regionCode: $regionCode, courseOrder: $courseOrder) {
    id
    courseId
    region
    code
    courseOrder
    courseName
    educationalPlanName
  }
}
    `;

export const useEducationalPlansQuery = <
  TData = EducationalPlansQuery,
  TError = unknown
>(
  variables: EducationalPlansQueryVariables,
  options?: Omit<
    UseQueryOptions<EducationalPlansQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      EducationalPlansQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<EducationalPlansQuery, TError, TData>({
    queryKey: ['EducationalPlans', variables],
    queryFn: useFetch<EducationalPlansQuery, EducationalPlansQueryVariables>(
      EducationalPlansDocument
    ).bind(null, variables),
    ...options,
  });
};

useEducationalPlansQuery.getKey = (
  variables: EducationalPlansQueryVariables
) => ['EducationalPlans', variables];

export const FamilyPaymentsDocument = `
    query FamilyPayments($academicYearId: ID!, $courseOrder: Int, $organizationId: ID!, $page: Int!, $pageSize: Int!, $regionId: String!, $studentName: String) {
  familyPayments(
    academicYearId: $academicYearId
    courseOrder: $courseOrder
    organizationId: $organizationId
    page: $page
    pageSize: $pageSize
    regionId: $regionId
    studentName: $studentName
  ) {
    page
    pageSize
    countByPage
    countAll
    items {
      student {
        name
      }
      order {
        financialStatus
        payoutDate
        readableExternalId
      }
      product {
        name
        courseName
        language
        quantity
      }
    }
  }
}
    `;

export const useFamilyPaymentsQuery = <
  TData = FamilyPaymentsQuery,
  TError = unknown
>(
  variables: FamilyPaymentsQueryVariables,
  options?: Omit<
    UseQueryOptions<FamilyPaymentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<FamilyPaymentsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<FamilyPaymentsQuery, TError, TData>({
    queryKey: ['FamilyPayments', variables],
    queryFn: useFetch<FamilyPaymentsQuery, FamilyPaymentsQueryVariables>(
      FamilyPaymentsDocument
    ).bind(null, variables),
    ...options,
  });
};

useFamilyPaymentsQuery.getKey = (variables: FamilyPaymentsQueryVariables) => [
  'FamilyPayments',
  variables,
];

export const GetFinalCustomerCrmDocument = `
    query GetFinalCustomerCrm($id: ID!) {
  getFinalCustomerCrm(id: $id) {
    id
    name
    type
    regionId
    addressFormattedAddress
    addressCity
    addressProvince
    addressCountry
    addressPostalCode
    schoolPhone
    a80Email
    languageEmail
    language
  }
}
    `;

export const useGetFinalCustomerCrmQuery = <
  TData = GetFinalCustomerCrmQuery,
  TError = unknown
>(
  variables: GetFinalCustomerCrmQueryVariables,
  options?: Omit<
    UseQueryOptions<GetFinalCustomerCrmQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetFinalCustomerCrmQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetFinalCustomerCrmQuery, TError, TData>({
    queryKey: ['GetFinalCustomerCrm', variables],
    queryFn: useFetch<
      GetFinalCustomerCrmQuery,
      GetFinalCustomerCrmQueryVariables
    >(GetFinalCustomerCrmDocument).bind(null, variables),
    ...options,
  });
};

useGetFinalCustomerCrmQuery.getKey = (
  variables: GetFinalCustomerCrmQueryVariables
) => ['GetFinalCustomerCrm', variables];

export const GetFinalCustomerErpDocument = `
    query GetFinalCustomerErp($id: ID!) {
  getFinalCustomerErp(id: $id) {
    id
    name
    currency
    shippingAddress {
      administrativeRegion
      city
      country
      formattedAddress
      id
      province
      reference
      street
      zip
    }
  }
}
    `;

export const useGetFinalCustomerErpQuery = <
  TData = GetFinalCustomerErpQuery,
  TError = unknown
>(
  variables: GetFinalCustomerErpQueryVariables,
  options?: Omit<
    UseQueryOptions<GetFinalCustomerErpQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetFinalCustomerErpQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetFinalCustomerErpQuery, TError, TData>({
    queryKey: ['GetFinalCustomerErp', variables],
    queryFn: useFetch<
      GetFinalCustomerErpQuery,
      GetFinalCustomerErpQueryVariables
    >(GetFinalCustomerErpDocument).bind(null, variables),
    ...options,
  });
};

useGetFinalCustomerErpQuery.getKey = (
  variables: GetFinalCustomerErpQueryVariables
) => ['GetFinalCustomerErp', variables];

export const GetShippingAddressByOrganizationIdDocument = `
    query GetShippingAddressByOrganizationId($organizationId: ID!, $visibleWeb: Boolean) {
  getFinalCustomerErpByOrganizationId(
    organizationId: $organizationId
    visibleWeb: $visibleWeb
  ) {
    id
    shippingAddress {
      administrativeRegion
      city
      country
      formattedAddress
      id
      province
      reference
      street
      zip
    }
  }
}
    `;

export const useGetShippingAddressByOrganizationIdQuery = <
  TData = GetShippingAddressByOrganizationIdQuery,
  TError = unknown
>(
  variables: GetShippingAddressByOrganizationIdQueryVariables,
  options?: Omit<
    UseQueryOptions<GetShippingAddressByOrganizationIdQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetShippingAddressByOrganizationIdQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetShippingAddressByOrganizationIdQuery, TError, TData>({
    queryKey: ['GetShippingAddressByOrganizationId', variables],
    queryFn: useFetch<
      GetShippingAddressByOrganizationIdQuery,
      GetShippingAddressByOrganizationIdQueryVariables
    >(GetShippingAddressByOrganizationIdDocument).bind(null, variables),
    ...options,
  });
};

useGetShippingAddressByOrganizationIdQuery.getKey = (
  variables: GetShippingAddressByOrganizationIdQueryVariables
) => ['GetShippingAddressByOrganizationId', variables];

export const FluencyDecksDocument = `
    query FluencyDecks($classroomId: ID!) {
  fluencyDecks(classroomId: $classroomId)
}
    `;

export const useFluencyDecksQuery = <
  TData = FluencyDecksQuery,
  TError = unknown
>(
  variables: FluencyDecksQueryVariables,
  options?: Omit<
    UseQueryOptions<FluencyDecksQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<FluencyDecksQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<FluencyDecksQuery, TError, TData>({
    queryKey: ['FluencyDecks', variables],
    queryFn: useFetch<FluencyDecksQuery, FluencyDecksQueryVariables>(
      FluencyDecksDocument
    ).bind(null, variables),
    ...options,
  });
};

useFluencyDecksQuery.getKey = (variables: FluencyDecksQueryVariables) => [
  'FluencyDecks',
  variables,
];

export const FluencyMultiplicationReportDocument = `
    query FluencyMultiplicationReport($id: ID!) {
  fluencyMultiplicationReport(id: $id) {
    uuid
    titleTranslationKey
    operator
    students {
      uuid
      firstName
      lastName
      cards {
        uuid
        status
      }
    }
    multiplicationTables {
      uuid
      operand2
      cards {
        uuid
        operand1
        status
        studentsFailed {
          uuid
          firstName
          lastName
        }
      }
    }
  }
}
    `;

export const useFluencyMultiplicationReportQuery = <
  TData = FluencyMultiplicationReportQuery,
  TError = unknown
>(
  variables: FluencyMultiplicationReportQueryVariables,
  options?: Omit<
    UseQueryOptions<FluencyMultiplicationReportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      FluencyMultiplicationReportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<FluencyMultiplicationReportQuery, TError, TData>({
    queryKey: ['FluencyMultiplicationReport', variables],
    queryFn: useFetch<
      FluencyMultiplicationReportQuery,
      FluencyMultiplicationReportQueryVariables
    >(FluencyMultiplicationReportDocument).bind(null, variables),
    ...options,
  });
};

useFluencyMultiplicationReportQuery.getKey = (
  variables: FluencyMultiplicationReportQueryVariables
) => ['FluencyMultiplicationReport', variables];

export const GetCurrentMaterialsDocument = `
    query GetCurrentMaterials($organizationId: ID!, $academicYearId: ID!) {
  getCurrentMaterials(
    organizationId: $organizationId
    academicYearId: $academicYearId
  ) {
    id
    name
    materials {
      courseId
      courseName
      courseOrder
      imageUrl
      languageId
      maxReturnableItems
      productId
      productName
      productShortname
      productType
      productTypeOfMaterial
      quantity
      stageId
      stageName
      stageOrder
    }
  }
}
    `;

export const useGetCurrentMaterialsQuery = <
  TData = GetCurrentMaterialsQuery,
  TError = unknown
>(
  variables: GetCurrentMaterialsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetCurrentMaterialsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetCurrentMaterialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetCurrentMaterialsQuery, TError, TData>({
    queryKey: ['GetCurrentMaterials', variables],
    queryFn: useFetch<
      GetCurrentMaterialsQuery,
      GetCurrentMaterialsQueryVariables
    >(GetCurrentMaterialsDocument).bind(null, variables),
    ...options,
  });
};

useGetCurrentMaterialsQuery.getKey = (
  variables: GetCurrentMaterialsQueryVariables
) => ['GetCurrentMaterials', variables];

export const GetGoogleClassroomsDocument = `
    query GetGoogleClassrooms {
  getGoogleClassrooms {
    classrooms {
      google_id
      name
      imported
      course_order
    }
  }
}
    `;

export const useGetGoogleClassroomsQuery = <
  TData = GetGoogleClassroomsQuery,
  TError = unknown
>(
  variables?: GetGoogleClassroomsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetGoogleClassroomsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetGoogleClassroomsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetGoogleClassroomsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['GetGoogleClassrooms']
        : ['GetGoogleClassrooms', variables],
    queryFn: useFetch<
      GetGoogleClassroomsQuery,
      GetGoogleClassroomsQueryVariables
    >(GetGoogleClassroomsDocument).bind(null, variables),
    ...options,
  });
};

useGetGoogleClassroomsQuery.getKey = (
  variables?: GetGoogleClassroomsQueryVariables
) =>
  variables === undefined
    ? ['GetGoogleClassrooms']
    : ['GetGoogleClassrooms', variables];

export const GetModulesOfCourseDocument = `
    query GetModulesOfCourse($courseUuid: ID!) {
  modulesOfCourse(courseUuid: $courseUuid) {
    stretch
    title
    standards {
      code
      description
      activities {
        codenamepack
        title
        screenshot
      }
    }
  }
}
    `;

export const useGetModulesOfCourseQuery = <
  TData = GetModulesOfCourseQuery,
  TError = unknown
>(
  variables: GetModulesOfCourseQueryVariables,
  options?: Omit<
    UseQueryOptions<GetModulesOfCourseQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetModulesOfCourseQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetModulesOfCourseQuery, TError, TData>({
    queryKey: ['GetModulesOfCourse', variables],
    queryFn: useFetch<
      GetModulesOfCourseQuery,
      GetModulesOfCourseQueryVariables
    >(GetModulesOfCourseDocument).bind(null, variables),
    ...options,
  });
};

useGetModulesOfCourseQuery.getKey = (
  variables: GetModulesOfCourseQueryVariables
) => ['GetModulesOfCourse', variables];

export const GetThirdPartyConnectionsDocument = `
    query GetThirdPartyConnections {
  getThirdPartyConnections {
    google {
      connected_at
      username
      _links {
        disconnect
      }
    }
    microsoft {
      connected_at
      username
      _links {
        disconnect
      }
    }
  }
}
    `;

export const useGetThirdPartyConnectionsQuery = <
  TData = GetThirdPartyConnectionsQuery,
  TError = unknown
>(
  variables?: GetThirdPartyConnectionsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetThirdPartyConnectionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetThirdPartyConnectionsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<GetThirdPartyConnectionsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['GetThirdPartyConnections']
        : ['GetThirdPartyConnections', variables],
    queryFn: useFetch<
      GetThirdPartyConnectionsQuery,
      GetThirdPartyConnectionsQueryVariables
    >(GetThirdPartyConnectionsDocument).bind(null, variables),
    ...options,
  });
};

useGetThirdPartyConnectionsQuery.getKey = (
  variables?: GetThirdPartyConnectionsQueryVariables
) =>
  variables === undefined
    ? ['GetThirdPartyConnections']
    : ['GetThirdPartyConnections', variables];

export const InterventionReportDocument = `
    query InterventionReport($id: ID!) {
  interventionReport(id: $id) {
    weeks {
      currentWeekNumber
      interventionWeeks {
        startDate
        endDate
      }
    }
    studentsCurrentlyInIntervention {
      uuid
      firstName
      lastName
      initialArithmeticFluency
      currentArithmeticFluency
      weeks {
        completedInterventionSessions
        availableInterventionSessions
      }
      lastWeekInIntervention
    }
    studentsNoLongerInIntervention {
      uuid
      firstName
      lastName
      initialArithmeticFluency
      currentArithmeticFluency
      weeks {
        completedInterventionSessions
        availableInterventionSessions
      }
      lastWeekInIntervention
    }
  }
}
    `;

export const useInterventionReportQuery = <
  TData = InterventionReportQuery,
  TError = unknown
>(
  variables: InterventionReportQueryVariables,
  options?: Omit<
    UseQueryOptions<InterventionReportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      InterventionReportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<InterventionReportQuery, TError, TData>({
    queryKey: ['InterventionReport', variables],
    queryFn: useFetch<
      InterventionReportQuery,
      InterventionReportQueryVariables
    >(InterventionReportDocument).bind(null, variables),
    ...options,
  });
};

useInterventionReportQuery.getKey = (
  variables: InterventionReportQueryVariables
) => ['InterventionReport', variables];

export const LastYearReportDocument = `
    query LastYearReport($id: ID!, $standardStructure: String) {
  lastYearReport(id: $id, standardStructure: $standardStructure) {
    reports {
      id
      operativeRegion
      courseOrder
      students {
        uuid
        firstName
        lastName
        blocks {
          blockId
          blockTitle {
            text
            requiresTranslation
          }
          blockTooltip {
            text
            requiresTranslation
          }
          score
          contents {
            contentId
            contentTitle {
              text
              requiresTranslation
            }
            contentTooltip {
              text
              requiresTranslation
            }
            score
          }
        }
        averageScore
        timeSpentAll
        weekScores {
          weekStartDate
          weekEndDate
          score
        }
        appletsCompleted
      }
      average {
        blocks {
          blockId
          blockTitle {
            text
            requiresTranslation
          }
          blockTooltip {
            text
            requiresTranslation
          }
          score
          contents {
            contentId
            contentTitle {
              text
              requiresTranslation
            }
            contentTooltip {
              text
              requiresTranslation
            }
            score
          }
        }
        averageScore
        timeSpentAll
        otherContents {
          score
          contents {
            contentId
            contentTitle {
              text
              requiresTranslation
            }
            contentTooltip {
              text
              requiresTranslation
            }
            score
          }
        }
        weekScores {
          weekStartDate
          weekEndDate
          score
        }
        appletsCompleted
      }
    }
    fromDate
    toDate
  }
}
    `;

export const useLastYearReportQuery = <
  TData = LastYearReportQuery,
  TError = unknown
>(
  variables: LastYearReportQueryVariables,
  options?: Omit<
    UseQueryOptions<LastYearReportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<LastYearReportQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<LastYearReportQuery, TError, TData>({
    queryKey: ['LastYearReport', variables],
    queryFn: useFetch<LastYearReportQuery, LastYearReportQueryVariables>(
      LastYearReportDocument
    ).bind(null, variables),
    ...options,
  });
};

useLastYearReportQuery.getKey = (variables: LastYearReportQueryVariables) => [
  'LastYearReport',
  variables,
];

export const LastYearReportsDocument = `
    query LastYearReports($id: ID!) {
  lastYearReports(id: $id) {
    academicYear
    isLastYear
  }
}
    `;

export const useLastYearReportsQuery = <
  TData = LastYearReportsQuery,
  TError = unknown
>(
  variables: LastYearReportsQueryVariables,
  options?: Omit<
    UseQueryOptions<LastYearReportsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<LastYearReportsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<LastYearReportsQuery, TError, TData>({
    queryKey: ['LastYearReports', variables],
    queryFn: useFetch<LastYearReportsQuery, LastYearReportsQueryVariables>(
      LastYearReportsDocument
    ).bind(null, variables),
    ...options,
  });
};

useLastYearReportsQuery.getKey = (variables: LastYearReportsQueryVariables) => [
  'LastYearReports',
  variables,
];

export const RevenueOrganizationDocument = `
    query RevenueOrganization($id: ID!) {
  revenueOrganization(id: $id) {
    erp_id
    crm_id
  }
}
    `;

export const useRevenueOrganizationQuery = <
  TData = RevenueOrganizationQuery,
  TError = unknown
>(
  variables: RevenueOrganizationQueryVariables,
  options?: Omit<
    UseQueryOptions<RevenueOrganizationQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      RevenueOrganizationQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<RevenueOrganizationQuery, TError, TData>({
    queryKey: ['RevenueOrganization', variables],
    queryFn: useFetch<
      RevenueOrganizationQuery,
      RevenueOrganizationQueryVariables
    >(RevenueOrganizationDocument).bind(null, variables),
    ...options,
  });
};

useRevenueOrganizationQuery.getKey = (
  variables: RevenueOrganizationQueryVariables
) => ['RevenueOrganization', variables];

export const OrderDetailsDocument = `
    query OrderDetails($orderId: ID!) {
  orderDetails(orderId: $orderId) {
    shippingAddress
    billingAddress
    materials {
      course
      id
      language
      name
      totalOperationQuantity
      stageOrder
      courseOrder
      units
    }
  }
}
    `;

export const useOrderDetailsQuery = <
  TData = OrderDetailsQuery,
  TError = unknown
>(
  variables: OrderDetailsQueryVariables,
  options?: Omit<
    UseQueryOptions<OrderDetailsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<OrderDetailsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrderDetailsQuery, TError, TData>({
    queryKey: ['OrderDetails', variables],
    queryFn: useFetch<OrderDetailsQuery, OrderDetailsQueryVariables>(
      OrderDetailsDocument
    ).bind(null, variables),
    ...options,
  });
};

useOrderDetailsQuery.getKey = (variables: OrderDetailsQueryVariables) => [
  'OrderDetails',
  variables,
];

export const CheckHasOngoingOrdersDocument = `
    query CheckHasOngoingOrders($organizationId: ID!) {
  checkHasOngoingOrders(organizationId: $organizationId) {
    hasOngoingOrders
  }
}
    `;

export const useCheckHasOngoingOrdersQuery = <
  TData = CheckHasOngoingOrdersQuery,
  TError = unknown
>(
  variables: CheckHasOngoingOrdersQueryVariables,
  options?: Omit<
    UseQueryOptions<CheckHasOngoingOrdersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CheckHasOngoingOrdersQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<CheckHasOngoingOrdersQuery, TError, TData>({
    queryKey: ['CheckHasOngoingOrders', variables],
    queryFn: useFetch<
      CheckHasOngoingOrdersQuery,
      CheckHasOngoingOrdersQueryVariables
    >(CheckHasOngoingOrdersDocument).bind(null, variables),
    ...options,
  });
};

useCheckHasOngoingOrdersQuery.getKey = (
  variables: CheckHasOngoingOrdersQueryVariables
) => ['CheckHasOngoingOrders', variables];

export const MaterialOrdersDocument = `
    query MaterialOrders($organizationId: String!, $academicYearId: String!, $page: Int!, $pageSize: Int) {
  materialOrders(
    organizationId: $organizationId
    academicYearId: $academicYearId
    page: $page
    pageSize: $pageSize
  ) {
    createdAt
    id
    orderStatus
    orderType
    organizationId
    readableId
    returnDeadlineDate
    transactionType
    estimateNumber
  }
}
    `;

export const useMaterialOrdersQuery = <
  TData = MaterialOrdersQuery,
  TError = unknown
>(
  variables: MaterialOrdersQueryVariables,
  options?: Omit<
    UseQueryOptions<MaterialOrdersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<MaterialOrdersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MaterialOrdersQuery, TError, TData>({
    queryKey: ['MaterialOrders', variables],
    queryFn: useFetch<MaterialOrdersQuery, MaterialOrdersQueryVariables>(
      MaterialOrdersDocument
    ).bind(null, variables),
    ...options,
  });
};

useMaterialOrdersQuery.getKey = (variables: MaterialOrdersQueryVariables) => [
  'MaterialOrders',
  variables,
];

export const OrganizationDocument = `
    query Organization($id: ID!) {
  organization(id: $id) {
    id
    name
    location
    numberOfTeachers
    numberOfClassrooms
    numberOfStudents
    numberOfDeletedStudents
    numberOfImports
    region
    operativeRegion
    availableLanguages
    platformEnabled
    language
    isLATAM
    academicYearId
    academicYear {
      isUpdatePeriod
      isTeacherPeriod
      studentEndDate
      studentStartDate
      teacherEndDate
      teacherStartDate
    }
    nextAcademicYear {
      isUpdatePeriod
      isTeacherPeriod
      studentEndDate
      studentStartDate
      teacherEndDate
      teacherStartDate
    }
    anonymized
    currency
    standard
    importedStudentsWithSisId
    timezone
  }
}
    `;

export const useOrganizationQuery = <
  TData = OrganizationQuery,
  TError = unknown
>(
  variables: OrganizationQueryVariables,
  options?: Omit<
    UseQueryOptions<OrganizationQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<OrganizationQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrganizationQuery, TError, TData>({
    queryKey: ['Organization', variables],
    queryFn: useFetch<OrganizationQuery, OrganizationQueryVariables>(
      OrganizationDocument
    ).bind(null, variables),
    ...options,
  });
};

useOrganizationQuery.getKey = (variables: OrganizationQueryVariables) => [
  'Organization',
  variables,
];

export const OrganizationsDocument = `
    query Organizations($name: String!) {
  organizations(name: $name) {
    id
    name
    location
    numberOfTeachers
    numberOfClassrooms
    numberOfStudents
    numberOfDeletedStudents
    region
    operativeRegion
    availableLanguages
    platformEnabled
    language
    anonymized
  }
}
    `;

export const useOrganizationsQuery = <
  TData = OrganizationsQuery,
  TError = unknown
>(
  variables: OrganizationsQueryVariables,
  options?: Omit<
    UseQueryOptions<OrganizationsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<OrganizationsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrganizationsQuery, TError, TData>({
    queryKey: ['Organizations', variables],
    queryFn: useFetch<OrganizationsQuery, OrganizationsQueryVariables>(
      OrganizationsDocument
    ).bind(null, variables),
    ...options,
  });
};

useOrganizationsQuery.getKey = (variables: OrganizationsQueryVariables) => [
  'Organizations',
  variables,
];

export const AllOrganizationsDocument = `
    query AllOrganizations {
  allOrganizations {
    id
    name
    location
    numberOfTeachers
    numberOfClassrooms
    numberOfStudents
    numberOfDeletedStudents
    region
    operativeRegion
    availableLanguages
    platformEnabled
    language
    anonymized
  }
}
    `;

export const useAllOrganizationsQuery = <
  TData = AllOrganizationsQuery,
  TError = unknown
>(
  variables?: AllOrganizationsQueryVariables,
  options?: Omit<
    UseQueryOptions<AllOrganizationsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      AllOrganizationsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<AllOrganizationsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['AllOrganizations']
        : ['AllOrganizations', variables],
    queryFn: useFetch<AllOrganizationsQuery, AllOrganizationsQueryVariables>(
      AllOrganizationsDocument
    ).bind(null, variables),
    ...options,
  });
};

useAllOrganizationsQuery.getKey = (
  variables?: AllOrganizationsQueryVariables
) =>
  variables === undefined
    ? ['AllOrganizations']
    : ['AllOrganizations', variables];

export const OrganizationsCoursesDocument = `
    query OrganizationsCourses($organizationId: ID!, $academicYearId: ID!) {
  organizationsCourses(
    organizationId: $organizationId
    academicYearId: $academicYearId
  ) {
    id
    region
    order
    name
    tutoringChat
    earlyIntervention
    adminDashboard
    assessment
    platformEnabled
  }
}
    `;

export const useOrganizationsCoursesQuery = <
  TData = OrganizationsCoursesQuery,
  TError = unknown
>(
  variables: OrganizationsCoursesQueryVariables,
  options?: Omit<
    UseQueryOptions<OrganizationsCoursesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      OrganizationsCoursesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<OrganizationsCoursesQuery, TError, TData>({
    queryKey: ['OrganizationsCourses', variables],
    queryFn: useFetch<
      OrganizationsCoursesQuery,
      OrganizationsCoursesQueryVariables
    >(OrganizationsCoursesDocument).bind(null, variables),
    ...options,
  });
};

useOrganizationsCoursesQuery.getKey = (
  variables: OrganizationsCoursesQueryVariables
) => ['OrganizationsCourses', variables];

export const PageDocument = `
    query Page($id: ID!, $classroomId: ID, $paginated: Boolean) {
  page(id: $id, classroomId: $classroomId, paginated: $paginated) {
    description
    name
    uuid
    ordered_translated_resource_lists {
      order
      translated_resource_list {
        uuid
        page_id
        list_type
        name
        description
        total_resources
        translated_ordered_resources {
          order
          resource_view {
            uuid
            tags
            resource_id
            _tags {
              id
              name
            }
            name
            description
            thumbnail {
              uuid
              href
              original_file_name
              type
            }
            type
            available_locales
            visibility
            done_status
            status
            enumeration
            icon
            has_activities
            has_solutions
            is_completable
            has_digital_guide
            has_player
            code
            progress
            duration
          }
        }
      }
    }
  }
}
    `;

export const usePageQuery = <TData = PageQuery, TError = unknown>(
  variables: PageQueryVariables,
  options?: Omit<UseQueryOptions<PageQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<PageQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PageQuery, TError, TData>({
    queryKey: ['Page', variables],
    queryFn: useFetch<PageQuery, PageQueryVariables>(PageDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

usePageQuery.getKey = (variables: PageQueryVariables) => ['Page', variables];

export const PaginatedListDocument = `
    query PaginatedList($body: PaginatedListBody!) {
  paginatedList(body: $body)
}
    `;

export const usePaginatedListQuery = <
  TData = PaginatedListQuery,
  TError = unknown
>(
  variables: PaginatedListQueryVariables,
  options?: Omit<
    UseQueryOptions<PaginatedListQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<PaginatedListQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PaginatedListQuery, TError, TData>({
    queryKey: ['PaginatedList', variables],
    queryFn: useFetch<PaginatedListQuery, PaginatedListQueryVariables>(
      PaginatedListDocument
    ).bind(null, variables),
    ...options,
  });
};

usePaginatedListQuery.getKey = (variables: PaginatedListQueryVariables) => [
  'PaginatedList',
  variables,
];

export const PermissionCoursesDocument = `
    query PermissionCourses($organizationId: ID!, $academicYearId: String!) {
  permissionCourses(
    organizationId: $organizationId
    academicYearId: $academicYearId
  ) {
    courseId
    permission {
      tutoringChat
      earlyIntervention
      adminDashboard
      assessment
    }
  }
}
    `;

export const usePermissionCoursesQuery = <
  TData = PermissionCoursesQuery,
  TError = unknown
>(
  variables: PermissionCoursesQueryVariables,
  options?: Omit<
    UseQueryOptions<PermissionCoursesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      PermissionCoursesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<PermissionCoursesQuery, TError, TData>({
    queryKey: ['PermissionCourses', variables],
    queryFn: useFetch<PermissionCoursesQuery, PermissionCoursesQueryVariables>(
      PermissionCoursesDocument
    ).bind(null, variables),
    ...options,
  });
};

usePermissionCoursesQuery.getKey = (
  variables: PermissionCoursesQueryVariables
) => ['PermissionCourses', variables];

export const PlatformApiUrlDocument = `
    query PlatformApiUrl {
  platformApiUrl
}
    `;

export const usePlatformApiUrlQuery = <
  TData = PlatformApiUrlQuery,
  TError = unknown
>(
  variables?: PlatformApiUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<PlatformApiUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<PlatformApiUrlQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PlatformApiUrlQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['PlatformApiUrl']
        : ['PlatformApiUrl', variables],
    queryFn: useFetch<PlatformApiUrlQuery, PlatformApiUrlQueryVariables>(
      PlatformApiUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

usePlatformApiUrlQuery.getKey = (variables?: PlatformApiUrlQueryVariables) =>
  variables === undefined ? ['PlatformApiUrl'] : ['PlatformApiUrl', variables];

export const ProgressReportsDocument = `
    query ProgressReports($id: ID!, $from: String!, $to: String!, $stage: String!, $standardStructure: String) {
  progressReports(
    id: $id
    from: $from
    to: $to
    stage: $stage
    standardStructure: $standardStructure
  ) {
    reports {
      id
      operativeRegion
      courseOrder
      students {
        uuid
        firstName
        lastName
        blocks {
          blockId
          blockTitle {
            text
            requiresTranslation
          }
          blockTooltip {
            text
            requiresTranslation
          }
          score
          contents {
            contentId
            contentTitle {
              text
              requiresTranslation
            }
            contentTooltip {
              text
              requiresTranslation
            }
            score
          }
        }
        averageScore
        timeSpentAll
        weekScores {
          weekStartDate
          weekEndDate
          score
        }
        appletsCompleted
      }
      average {
        blocks {
          score
          blockId
          blockTitle {
            text
            requiresTranslation
          }
          blockTooltip {
            text
            requiresTranslation
          }
          contents {
            contentId
            contentTitle {
              text
              requiresTranslation
            }
            contentTooltip {
              text
              requiresTranslation
            }
            score
          }
        }
        averageScore
        timeSpentAll
        otherContents {
          score
          contents {
            contentId
            contentTitle {
              text
              requiresTranslation
            }
            contentTooltip {
              text
              requiresTranslation
            }
            score
          }
        }
        weekScores {
          weekStartDate
          weekEndDate
          score
        }
        appletsCompleted
      }
    }
    dateTo
    dateFrom
  }
}
    `;

export const useProgressReportsQuery = <
  TData = ProgressReportsQuery,
  TError = unknown
>(
  variables: ProgressReportsQueryVariables,
  options?: Omit<
    UseQueryOptions<ProgressReportsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ProgressReportsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ProgressReportsQuery, TError, TData>({
    queryKey: ['ProgressReports', variables],
    queryFn: useFetch<ProgressReportsQuery, ProgressReportsQueryVariables>(
      ProgressReportsDocument
    ).bind(null, variables),
    ...options,
  });
};

useProgressReportsQuery.getKey = (variables: ProgressReportsQueryVariables) => [
  'ProgressReports',
  variables,
];

export const RegionParametersDocument = `
    query RegionParameters($regionCode: String!, $stage: String) {
  regionParameters(regionCode: $regionCode, stage: $stage)
}
    `;

export const useRegionParametersQuery = <
  TData = RegionParametersQuery,
  TError = unknown
>(
  variables: RegionParametersQueryVariables,
  options?: Omit<
    UseQueryOptions<RegionParametersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      RegionParametersQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<RegionParametersQuery, TError, TData>({
    queryKey: ['RegionParameters', variables],
    queryFn: useFetch<RegionParametersQuery, RegionParametersQueryVariables>(
      RegionParametersDocument
    ).bind(null, variables),
    ...options,
  });
};

useRegionParametersQuery.getKey = (
  variables: RegionParametersQueryVariables
) => ['RegionParameters', variables];

export const ResourceDocument = `
    query Resource($id: ID!, $classroomId: ID, $type: String, $standard: String, $courseOrder: Int) {
  resource(
    classroomId: $classroomId
    id: $id
    type: $type
    standard: $standard
    courseOrder: $courseOrder
  )
}
    `;

export const useResourceQuery = <TData = ResourceQuery, TError = unknown>(
  variables: ResourceQueryVariables,
  options?: Omit<UseQueryOptions<ResourceQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<ResourceQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ResourceQuery, TError, TData>({
    queryKey: ['Resource', variables],
    queryFn: useFetch<ResourceQuery, ResourceQueryVariables>(
      ResourceDocument
    ).bind(null, variables),
    ...options,
  });
};

useResourceQuery.getKey = (variables: ResourceQueryVariables) => [
  'Resource',
  variables,
];

export const RosteringsDocument = `
    query Rosterings($organizationId: ID!) {
  rosterings(organizationId: $organizationId) {
    completedAt
    createdAt
    originalFilename
    id
    items {
      id
      message
      number
      status
    }
    organization
    owner
    status
  }
}
    `;

export const useRosteringsQuery = <TData = RosteringsQuery, TError = unknown>(
  variables: RosteringsQueryVariables,
  options?: Omit<
    UseQueryOptions<RosteringsQuery, TError, TData>,
    'queryKey'
  > & { queryKey?: UseQueryOptions<RosteringsQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<RosteringsQuery, TError, TData>({
    queryKey: ['Rosterings', variables],
    queryFn: useFetch<RosteringsQuery, RosteringsQueryVariables>(
      RosteringsDocument
    ).bind(null, variables),
    ...options,
  });
};

useRosteringsQuery.getKey = (variables: RosteringsQueryVariables) => [
  'Rosterings',
  variables,
];

export const SchoolPositionsDocument = `
    query SchoolPositions {
  schoolPositions
}
    `;

export const useSchoolPositionsQuery = <
  TData = SchoolPositionsQuery,
  TError = unknown
>(
  variables?: SchoolPositionsQueryVariables,
  options?: Omit<
    UseQueryOptions<SchoolPositionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SchoolPositionsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SchoolPositionsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['SchoolPositions']
        : ['SchoolPositions', variables],
    queryFn: useFetch<SchoolPositionsQuery, SchoolPositionsQueryVariables>(
      SchoolPositionsDocument
    ).bind(null, variables),
    ...options,
  });
};

useSchoolPositionsQuery.getKey = (variables?: SchoolPositionsQueryVariables) =>
  variables === undefined
    ? ['SchoolPositions']
    : ['SchoolPositions', variables];

export const SchoolStudentsDocument = `
    query SchoolStudents($body: PaginationBody) {
  schoolStudents(body: $body) {
    id
    firstName
    lastName
    classroomName
    classroomId
    classroomDeletedAt
    courseName
    courseOrder
    avatar
    deletedAt
    alias
  }
}
    `;

export const useSchoolStudentsQuery = <
  TData = SchoolStudentsQuery,
  TError = unknown
>(
  variables?: SchoolStudentsQueryVariables,
  options?: Omit<
    UseQueryOptions<SchoolStudentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SchoolStudentsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SchoolStudentsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['SchoolStudents']
        : ['SchoolStudents', variables],
    queryFn: useFetch<SchoolStudentsQuery, SchoolStudentsQueryVariables>(
      SchoolStudentsDocument
    ).bind(null, variables),
    ...options,
  });
};

useSchoolStudentsQuery.getKey = (variables?: SchoolStudentsQueryVariables) =>
  variables === undefined ? ['SchoolStudents'] : ['SchoolStudents', variables];

export const SessionReviewDocument = `
    query SessionReview($id: ID!, $academicYearId: String!) {
  sessionReview(id: $id, academicYearId: $academicYearId) {
    rating
  }
}
    `;

export const useSessionReviewQuery = <
  TData = SessionReviewQuery,
  TError = unknown
>(
  variables: SessionReviewQueryVariables,
  options?: Omit<
    UseQueryOptions<SessionReviewQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SessionReviewQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SessionReviewQuery, TError, TData>({
    queryKey: ['SessionReview', variables],
    queryFn: useFetch<SessionReviewQuery, SessionReviewQueryVariables>(
      SessionReviewDocument
    ).bind(null, variables),
    ...options,
  });
};

useSessionReviewQuery.getKey = (variables: SessionReviewQueryVariables) => [
  'SessionReview',
  variables,
];

export const SettingsDocument = `
    query Settings {
  settings {
    email
    firstName
    lastLogin
    lastName
    phoneNumber
    positions
    roles
    schoolPositions
    id
  }
}
    `;

export const useSettingsQuery = <TData = SettingsQuery, TError = unknown>(
  variables?: SettingsQueryVariables,
  options?: Omit<UseQueryOptions<SettingsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SettingsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SettingsQuery, TError, TData>({
    queryKey: variables === undefined ? ['Settings'] : ['Settings', variables],
    queryFn: useFetch<SettingsQuery, SettingsQueryVariables>(
      SettingsDocument
    ).bind(null, variables),
    ...options,
  });
};

useSettingsQuery.getKey = (variables?: SettingsQueryVariables) =>
  variables === undefined ? ['Settings'] : ['Settings', variables];

export const SitemapLevelsDocument = `
    query SitemapLevels($region: String!) {
  sitemapLevels(region: $region) {
    key
    value
  }
}
    `;

export const useSitemapLevelsQuery = <
  TData = SitemapLevelsQuery,
  TError = unknown
>(
  variables: SitemapLevelsQueryVariables,
  options?: Omit<
    UseQueryOptions<SitemapLevelsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SitemapLevelsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SitemapLevelsQuery, TError, TData>({
    queryKey: ['SitemapLevels', variables],
    queryFn: useFetch<SitemapLevelsQuery, SitemapLevelsQueryVariables>(
      SitemapLevelsDocument
    ).bind(null, variables),
    ...options,
  });
};

useSitemapLevelsQuery.getKey = (variables: SitemapLevelsQueryVariables) => [
  'SitemapLevels',
  variables,
];

export const SitemapTreeDocument = `
    query SitemapTree($region: String!, $courseOrder: Int!, $level: String!, $viewAs: String) {
  sitemapTree(
    region: $region
    courseOrder: $courseOrder
    level: $level
    viewAs: $viewAs
  ) {
    icon
    value
    route
    items {
      fullPageId
      demoPageId
      icon
      isOnlyForClassroom
      linkItemType
      program
      route
      status
      type
      url
      urlName
      value
    }
  }
}
    `;

export const useSitemapTreeQuery = <TData = SitemapTreeQuery, TError = unknown>(
  variables: SitemapTreeQueryVariables,
  options?: Omit<
    UseQueryOptions<SitemapTreeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SitemapTreeQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SitemapTreeQuery, TError, TData>({
    queryKey: ['SitemapTree', variables],
    queryFn: useFetch<SitemapTreeQuery, SitemapTreeQueryVariables>(
      SitemapTreeDocument
    ).bind(null, variables),
    ...options,
  });
};

useSitemapTreeQuery.getKey = (variables: SitemapTreeQueryVariables) => [
  'SitemapTree',
  variables,
];

export const SitemapsDocument = `
    query Sitemaps($region: String!) {
  sitemaps(region: $region) {
    active
    courseOrder
    numberOfNullPageId
    region
    level
  }
}
    `;

export const useSitemapsQuery = <TData = SitemapsQuery, TError = unknown>(
  variables: SitemapsQueryVariables,
  options?: Omit<UseQueryOptions<SitemapsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SitemapsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SitemapsQuery, TError, TData>({
    queryKey: ['Sitemaps', variables],
    queryFn: useFetch<SitemapsQuery, SitemapsQueryVariables>(
      SitemapsDocument
    ).bind(null, variables),
    ...options,
  });
};

useSitemapsQuery.getKey = (variables: SitemapsQueryVariables) => [
  'Sitemaps',
  variables,
];

export const SolverApiUrlDocument = `
    query SolverApiUrl {
  solverApiUrl
}
    `;

export const useSolverApiUrlQuery = <
  TData = SolverApiUrlQuery,
  TError = unknown
>(
  variables?: SolverApiUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<SolverApiUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SolverApiUrlQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SolverApiUrlQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['SolverApiUrl'] : ['SolverApiUrl', variables],
    queryFn: useFetch<SolverApiUrlQuery, SolverApiUrlQueryVariables>(
      SolverApiUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

useSolverApiUrlQuery.getKey = (variables?: SolverApiUrlQueryVariables) =>
  variables === undefined ? ['SolverApiUrl'] : ['SolverApiUrl', variables];

export const SpacesDocument = `
    query Spaces($body: GetSpacesBody!) {
  spaces(body: $body)
}
    `;

export const useSpacesQuery = <TData = SpacesQuery, TError = unknown>(
  variables: SpacesQueryVariables,
  options?: Omit<UseQueryOptions<SpacesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SpacesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SpacesQuery, TError, TData>({
    queryKey: ['Spaces', variables],
    queryFn: useFetch<SpacesQuery, SpacesQueryVariables>(SpacesDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

useSpacesQuery.getKey = (variables: SpacesQueryVariables) => [
  'Spaces',
  variables,
];

export const StarsFeedbackTrainingZoneDocument = `
    query StarsFeedbackTrainingZone($userId: ID!, $activityId: ID!) {
  starsFeedbackTrainingZone(userId: $userId, activityId: $activityId)
}
    `;

export const useStarsFeedbackTrainingZoneQuery = <
  TData = StarsFeedbackTrainingZoneQuery,
  TError = unknown
>(
  variables: StarsFeedbackTrainingZoneQueryVariables,
  options?: Omit<
    UseQueryOptions<StarsFeedbackTrainingZoneQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      StarsFeedbackTrainingZoneQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<StarsFeedbackTrainingZoneQuery, TError, TData>({
    queryKey: ['StarsFeedbackTrainingZone', variables],
    queryFn: useFetch<
      StarsFeedbackTrainingZoneQuery,
      StarsFeedbackTrainingZoneQueryVariables
    >(StarsFeedbackTrainingZoneDocument).bind(null, variables),
    ...options,
  });
};

useStarsFeedbackTrainingZoneQuery.getKey = (
  variables: StarsFeedbackTrainingZoneQueryVariables
) => ['StarsFeedbackTrainingZone', variables];

export const StartWeeklyPracticeDocument = `
    mutation StartWeeklyPractice($locale: String) {
  startWeeklyPractice(locale: $locale) {
    active
    activities {
      sceneName
      codename
      layout
      type
      pack
      locale
      completed
      index
      stars
      uuid
      data {
        name
        thumbnail
        description
        id
      }
      unityActivity
    }
    unityPayload
  }
}
    `;

export const useStartWeeklyPracticeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    StartWeeklyPracticeMutation,
    TError,
    StartWeeklyPracticeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    StartWeeklyPracticeMutation,
    TError,
    StartWeeklyPracticeMutationVariables,
    TContext
  >({
    mutationKey: ['StartWeeklyPractice'],
    mutationFn: useFetch<
      StartWeeklyPracticeMutation,
      StartWeeklyPracticeMutationVariables
    >(StartWeeklyPracticeDocument),
    ...options,
  });
};

export const StretchesOfCourseDocument = `
    query stretchesOfCourse($courseUuid: ID!) {
  stretchesOfCourse(courseUuid: $courseUuid) {
    title
    number
    modulePacings {
      title
      lessons {
        title
        type
        duration
        image
      }
    }
    assessmentPacing {
      title
      lessons {
        title
        type
        duration
        image
      }
    }
  }
}
    `;

export const useStretchesOfCourseQuery = <
  TData = StretchesOfCourseQuery,
  TError = unknown
>(
  variables: StretchesOfCourseQueryVariables,
  options?: Omit<
    UseQueryOptions<StretchesOfCourseQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      StretchesOfCourseQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<StretchesOfCourseQuery, TError, TData>({
    queryKey: ['stretchesOfCourse', variables],
    queryFn: useFetch<StretchesOfCourseQuery, StretchesOfCourseQueryVariables>(
      StretchesOfCourseDocument
    ).bind(null, variables),
    ...options,
  });
};

useStretchesOfCourseQuery.getKey = (
  variables: StretchesOfCourseQueryVariables
) => ['stretchesOfCourse', variables];

export const StudentDocument = `
    query Student($id: ID!) {
  student(id: $id) {
    classroomName
    courseName
    region
    icon
    courseOrder
    classroomId
    classroomIcon
    classroomAvatar
    courseId
    classroomLevel
  }
}
    `;

export const useStudentQuery = <TData = StudentQuery, TError = unknown>(
  variables: StudentQueryVariables,
  options?: Omit<UseQueryOptions<StudentQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<StudentQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StudentQuery, TError, TData>({
    queryKey: ['Student', variables],
    queryFn: useFetch<StudentQuery, StudentQueryVariables>(
      StudentDocument
    ).bind(null, variables),
    ...options,
  });
};

useStudentQuery.getKey = (variables: StudentQueryVariables) => [
  'Student',
  variables,
];

export const StudentsByParentDocument = `
    query StudentsByParent {
  studentsByParent {
    classroom_id
    classroom_name
    course_id
    digital_practice_access
    first_name
    last_name
    student_id
    teachers
  }
}
    `;

export const useStudentsByParentQuery = <
  TData = StudentsByParentQuery,
  TError = unknown
>(
  variables?: StudentsByParentQueryVariables,
  options?: Omit<
    UseQueryOptions<StudentsByParentQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      StudentsByParentQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<StudentsByParentQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['StudentsByParent']
        : ['StudentsByParent', variables],
    queryFn: useFetch<StudentsByParentQuery, StudentsByParentQueryVariables>(
      StudentsByParentDocument
    ).bind(null, variables),
    ...options,
  });
};

useStudentsByParentQuery.getKey = (
  variables?: StudentsByParentQueryVariables
) =>
  variables === undefined
    ? ['StudentsByParent']
    : ['StudentsByParent', variables];

export const StudentsCsvDocument = `
    query StudentsCSV {
  studentsCSV {
    base64
  }
}
    `;

export const useStudentsCsvQuery = <TData = StudentsCsvQuery, TError = unknown>(
  variables?: StudentsCsvQueryVariables,
  options?: Omit<
    UseQueryOptions<StudentsCsvQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<StudentsCsvQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StudentsCsvQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['StudentsCSV'] : ['StudentsCSV', variables],
    queryFn: useFetch<StudentsCsvQuery, StudentsCsvQueryVariables>(
      StudentsCsvDocument
    ).bind(null, variables),
    ...options,
  });
};

useStudentsCsvQuery.getKey = (variables?: StudentsCsvQueryVariables) =>
  variables === undefined ? ['StudentsCSV'] : ['StudentsCSV', variables];

export const StudentsDocument = `
    query Students($organizationId: ID, $classroomId: ID) {
  students(organizationId: $organizationId, classroomId: $classroomId) {
    id
    firstName
    lastName
    email
    educationalPlanId
    avatar
    icon
    language
    homeAccess
    individualAccess
    weeklyTimeLimit
    classroomId
    courseOrder
    isSecondaryStudent
    pinCode
    alias
    parents {
      id
      userInfo {
        firstName
        lastName
        email
        lastLogin
        token
      }
    }
    isInvitation
    lastLogin
    token
    trainingAccess
    trainingTimeLimit
    rti {
      enabled
      startDate
      endDate
    }
  }
}
    `;

export const useStudentsQuery = <TData = StudentsQuery, TError = unknown>(
  variables?: StudentsQueryVariables,
  options?: Omit<UseQueryOptions<StudentsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<StudentsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StudentsQuery, TError, TData>({
    queryKey: variables === undefined ? ['Students'] : ['Students', variables],
    queryFn: useFetch<StudentsQuery, StudentsQueryVariables>(
      StudentsDocument
    ).bind(null, variables),
    ...options,
  });
};

useStudentsQuery.getKey = (variables?: StudentsQueryVariables) =>
  variables === undefined ? ['Students'] : ['Students', variables];

export const SubscriptionsByEmailDocument = `
    query SubscriptionsByEmail($email: String!) {
  subscriptionsByEmail(email: $email) {
    subscribedList
    unsubscribedList
  }
}
    `;

export const useSubscriptionsByEmailQuery = <
  TData = SubscriptionsByEmailQuery,
  TError = unknown
>(
  variables: SubscriptionsByEmailQueryVariables,
  options?: Omit<
    UseQueryOptions<SubscriptionsByEmailQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      SubscriptionsByEmailQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<SubscriptionsByEmailQuery, TError, TData>({
    queryKey: ['SubscriptionsByEmail', variables],
    queryFn: useFetch<
      SubscriptionsByEmailQuery,
      SubscriptionsByEmailQueryVariables
    >(SubscriptionsByEmailDocument).bind(null, variables),
    ...options,
  });
};

useSubscriptionsByEmailQuery.getKey = (
  variables: SubscriptionsByEmailQueryVariables
) => ['SubscriptionsByEmail', variables];

export const SuggestTeachersDocument = `
    query SuggestTeachers($name: String!) {
  suggestTeachers(name: $name) {
    id
    firstName
    lastName
    email
    type
    roles
    lastLogin
    reportsEnabled
    isInvitation
    isSchoolInvitation
    token
    expiresAt
  }
}
    `;

export const useSuggestTeachersQuery = <
  TData = SuggestTeachersQuery,
  TError = unknown
>(
  variables: SuggestTeachersQueryVariables,
  options?: Omit<
    UseQueryOptions<SuggestTeachersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SuggestTeachersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SuggestTeachersQuery, TError, TData>({
    queryKey: ['SuggestTeachers', variables],
    queryFn: useFetch<SuggestTeachersQuery, SuggestTeachersQueryVariables>(
      SuggestTeachersDocument
    ).bind(null, variables),
    ...options,
  });
};

useSuggestTeachersQuery.getKey = (variables: SuggestTeachersQueryVariables) => [
  'SuggestTeachers',
  variables,
];

export const TeachersDocument = `
    query Teachers($organizationId: ID, $classroomId: ID, $isPlatformEnabled: Boolean) {
  teachers(
    organizationId: $organizationId
    classroomId: $classroomId
    isPlatformEnabled: $isPlatformEnabled
  ) {
    id
    firstName
    lastName
    email
    type
    roles
    lastLogin
    reportsEnabled
    isInvitation
    isSchoolInvitation
    token
    expiresAt
    classrooms {
      courseId
      courseName
      courseOrder
      icon
      id
      name
    }
  }
}
    `;

export const useTeachersQuery = <TData = TeachersQuery, TError = unknown>(
  variables?: TeachersQueryVariables,
  options?: Omit<UseQueryOptions<TeachersQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<TeachersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<TeachersQuery, TError, TData>({
    queryKey: variables === undefined ? ['Teachers'] : ['Teachers', variables],
    queryFn: useFetch<TeachersQuery, TeachersQueryVariables>(
      TeachersDocument
    ).bind(null, variables),
    ...options,
  });
};

useTeachersQuery.getKey = (variables?: TeachersQueryVariables) =>
  variables === undefined ? ['Teachers'] : ['Teachers', variables];

export const TestDocument = `
    query Test($testUuid: ID!, $userTestUuid: ID!) {
  test(testUuid: $testUuid, userTestUuid: $userTestUuid) {
    description
    navigation
    shuffle
    time
    title
    time
    id
    userTestUuid
    activities {
      description
      pack
      scene
      id
      statements
      completed
      type
    }
  }
}
    `;

export const useTestQuery = <TData = TestQuery, TError = unknown>(
  variables: TestQueryVariables,
  options?: Omit<UseQueryOptions<TestQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<TestQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<TestQuery, TError, TData>({
    queryKey: ['Test', variables],
    queryFn: useFetch<TestQuery, TestQueryVariables>(TestDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

useTestQuery.getKey = (variables: TestQueryVariables) => ['Test', variables];

export const TestsAndResultsDocument = `
    query TestsAndResults($classroomId: ID!, $academicYearId: ID!) {
  testsAndResults(classroomId: $classroomId, academicYearId: $academicYearId) {
    testId
    classroomTestRoundId
    roundName
    status
    password
    startDate
    endDate
    test {
      type
    }
    report {
      deliveryDate
    }
  }
}
    `;

export const useTestsAndResultsQuery = <
  TData = TestsAndResultsQuery,
  TError = unknown
>(
  variables: TestsAndResultsQueryVariables,
  options?: Omit<
    UseQueryOptions<TestsAndResultsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<TestsAndResultsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<TestsAndResultsQuery, TError, TData>({
    queryKey: ['TestsAndResults', variables],
    queryFn: useFetch<TestsAndResultsQuery, TestsAndResultsQueryVariables>(
      TestsAndResultsDocument
    ).bind(null, variables),
    ...options,
  });
};

useTestsAndResultsQuery.getKey = (variables: TestsAndResultsQueryVariables) => [
  'TestsAndResults',
  variables,
];

export const TrainingZoneDocument = `
    query TrainingZone($locale: String) {
  trainingZone(locale: $locale) {
    active
    activities {
      sceneName
      codename
      layout
      type
      pack
      locale
      completed
      index
      unityActivity
      stars
      uuid
      data {
        name
        thumbnail
        description
        id
      }
    }
    unityPayload
    noMoreData
  }
}
    `;

export const useTrainingZoneQuery = <
  TData = TrainingZoneQuery,
  TError = unknown
>(
  variables?: TrainingZoneQueryVariables,
  options?: Omit<
    UseQueryOptions<TrainingZoneQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<TrainingZoneQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<TrainingZoneQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['TrainingZone'] : ['TrainingZone', variables],
    queryFn: useFetch<TrainingZoneQuery, TrainingZoneQueryVariables>(
      TrainingZoneDocument
    ).bind(null, variables),
    ...options,
  });
};

useTrainingZoneQuery.getKey = (variables?: TrainingZoneQueryVariables) =>
  variables === undefined ? ['TrainingZone'] : ['TrainingZone', variables];

export const WeeklyPracticeDocument = `
    query WeeklyPractice($locale: String) {
  weeklyPractice(locale: $locale) {
    active
    activities {
      sceneName
      codename
      layout
      type
      pack
      locale
      completed
      index
      stars
      uuid
      data {
        name
        thumbnail
        description
        id
      }
      unityActivity
    }
    unityPayload
    isVacation
  }
}
    `;

export const useWeeklyPracticeQuery = <
  TData = WeeklyPracticeQuery,
  TError = unknown
>(
  variables?: WeeklyPracticeQueryVariables,
  options?: Omit<
    UseQueryOptions<WeeklyPracticeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<WeeklyPracticeQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<WeeklyPracticeQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['WeeklyPractice']
        : ['WeeklyPractice', variables],
    queryFn: useFetch<WeeklyPracticeQuery, WeeklyPracticeQueryVariables>(
      WeeklyPracticeDocument
    ).bind(null, variables),
    ...options,
  });
};

useWeeklyPracticeQuery.getKey = (variables?: WeeklyPracticeQueryVariables) =>
  variables === undefined ? ['WeeklyPractice'] : ['WeeklyPractice', variables];

export const WeeklyReportsDatesDocument = `
    query WeeklyReportsDates($id: ID!, $stage: String!) {
  weeklyReportsDates(id: $id, stage: $stage) {
    date_from
    date_to
    title
  }
}
    `;

export const useWeeklyReportsDatesQuery = <
  TData = WeeklyReportsDatesQuery,
  TError = unknown
>(
  variables: WeeklyReportsDatesQueryVariables,
  options?: Omit<
    UseQueryOptions<WeeklyReportsDatesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      WeeklyReportsDatesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<WeeklyReportsDatesQuery, TError, TData>({
    queryKey: ['WeeklyReportsDates', variables],
    queryFn: useFetch<
      WeeklyReportsDatesQuery,
      WeeklyReportsDatesQueryVariables
    >(WeeklyReportsDatesDocument).bind(null, variables),
    ...options,
  });
};

useWeeklyReportsDatesQuery.getKey = (
  variables: WeeklyReportsDatesQueryVariables
) => ['WeeklyReportsDates', variables];

export const WeeklyReportsDocument = `
    query WeeklyReports($id: ID!, $from: String!, $to: String!, $practicePeriod: String!, $stage: String!) {
  weeklyReports(
    id: $id
    from: $from
    to: $to
    practicePeriod: $practicePeriod
    stage: $stage
  ) {
    lessonsDone
    id
    operativeRegion
    courseOrder
    students {
      uuid
      firstName
      lastName
      statementsCompleted
      statementsServed
      timeSpentAll
      timeSpentSchool
      timeSpentHome
      appletsCompleted
      appletsServed
      averageScore
      contents {
        contentId
        contentTextCode
        skillTextCode
        score
        applets {
          uuid
          sceneName
          pack
          score
          data {
            name
            thumbnail
            description
            id
          }
        }
      }
      appletSelectorActivities {
        uuid
        sceneName
        pack
        maxScoreCurrentWeek
        passed
        data {
          name
          thumbnail
          description
          id
        }
      }
      bubbles {
        bubbleTextCode
        hasDifficulty
        readableId
      }
      trainingZone {
        timeSpent
        averageScore
        appletsImproved
        appletsAttempted {
          count
          applets {
            sceneName
            pack
            score
            data {
              name
              thumbnail
              description
              id
            }
          }
        }
      }
    }
    average {
      statementsCompleted
      statementsServed
      timeSpentAll
      timeSpentSchool
      timeSpentHome
      appletsCompleted
      appletsServed
      averageScore
      contents {
        contentId
        contentTextCode
        skillTextCode
        score
        applets {
          uuid
          sceneName
          pack
          score
          data {
            name
            thumbnail
            description
            id
          }
        }
      }
      trainingZone {
        timeSpent
        averageScore
      }
      otherContents {
        score
        contents {
          contentId
          contentTextCode
          skillTextCode
          score
          applets {
            uuid
            sceneName
            pack
            score
            data {
              name
              thumbnail
              description
              id
            }
          }
        }
      }
    }
  }
}
    `;

export const useWeeklyReportsQuery = <
  TData = WeeklyReportsQuery,
  TError = unknown
>(
  variables: WeeklyReportsQueryVariables,
  options?: Omit<
    UseQueryOptions<WeeklyReportsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<WeeklyReportsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<WeeklyReportsQuery, TError, TData>({
    queryKey: ['WeeklyReports', variables],
    queryFn: useFetch<WeeklyReportsQuery, WeeklyReportsQueryVariables>(
      WeeklyReportsDocument
    ).bind(null, variables),
    ...options,
  });
};

useWeeklyReportsQuery.getKey = (variables: WeeklyReportsQueryVariables) => [
  'WeeklyReports',
  variables,
];
